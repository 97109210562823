import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Button,
  Box,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import './DeletePopUp.scss';

const DeletePopUp = ({
  buttonText,
  entity,
  deleteFunction,
  id,
  redirectPath,
  icon,
}) => {
  const [open, setOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirmDelete = async () => {
    await deleteFunction(id);
    setOpen(false);
    if (redirectPath) setRedirect(true);
  };

  return (
    <div>
      {redirect && <Redirect to={redirectPath} />}
      <Box align="center" className="DeletePopUp">
        {icon ? (
          <IconButton
            className="buttonDelete"
            variant="contained"
            onClick={handleClickOpen}
          >
            {icon}
          </IconButton>
        ) : (
          <Button className="button-delete" onClick={handleClickOpen}>
            {buttonText}
          </Button>
        )}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            ¿Está seguro que quiere eliminar {entity}?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Luego de confirmar, esta acción no se puede deshacer.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={confirmDelete} color="primary" autoFocus>
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </div>
  );
};

DeletePopUp.propTypes = {
  buttonText: PropTypes.string,
  deleteFunction: PropTypes.func.isRequired,
  entity: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  redirectPath: PropTypes.string,
  icon: PropTypes.node,
};

DeletePopUp.defaultProps = {
  buttonText: 'Eliminar',
  redirectPath: null,
  icon: null,
};

export default DeletePopUp;
