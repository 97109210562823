import { React } from 'react';
import DataListForm from 'components/DataListForm';
import useService from 'utils/services';

export default function Vehicles() {
  const { vehicle } = useService();

  const submitFunction = async (values, args) => {
    const newValues = values.options.map((optionObj) => optionObj.optionValue);
    await vehicle.postVehicles(newValues);
    args.setValuesFunction(newValues);
  };

  const fetchDataFunction = async (args) => {
    const data = await vehicle.fetchVehicles();
    args.setValuesFunction(data);
  };

  return (
    <div className="VehiclesIndex">
      <h2 className="title"> Camionetas </h2>
      <DataListForm
        entity="camionetas"
        submitFunction={submitFunction}
        fetchDataFunction={fetchDataFunction}
      />
    </div>
  );
}
