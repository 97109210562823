import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import LoadView from 'components/LoadView';

import './DeleteDialog.scss';

const DeleteDialog = ({
  open,
  setOpen,
  onDelete,
  afterDelete,
  row,
  entityName: deleteEntityPrompt,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const confirmDelete = async () => {
    setIsLoading(true);
    await onDelete(row);
    handleClose();
    setIsLoading(false);
    if (afterDelete) await afterDelete(row);
  };

  return (
    <Box align="center" className="DeleteDialog">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          ¿Está seguro que quiere eliminar {deleteEntityPrompt(row)}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Luego de confirmar, esta acción no se puede deshacer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={confirmDelete} color="primary" autoFocus>
            <LoadView isLoading={isLoading} size="1.2rem">
              Confirmar
            </LoadView>
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

DeleteDialog.propTypes = {
  entityName: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  row: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  afterDelete: PropTypes.func,
};

DeleteDialog.defaultProps = {
  afterDelete: null,
};

export default DeleteDialog;
