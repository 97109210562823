import React, { useState, useEffect } from 'react';
import useNotification from 'utils/notifications/notificationHook';
import PropTypes from 'prop-types';
import { LoadView } from 'components';
import { Box, Grid, Paper, TextField, MenuItem } from '@material-ui/core/';
import LoadingButton from 'components/LoadingButton';
import OptionList from '../OptionList';
import './DataListForm.scss';

const DataListForm = (props) => {
  const [initialValues, setInitialValues] = useState([]);
  const [serviceType, setServiceType] = useState(0);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState({});
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { entity, submitFunction, fetchDataFunction } = props;
  const { setSuccessMessage } = useNotification();

  const onSubmit = async (values) => {
    setLoadingSubmit(true);
    await submitFunction(values, {
      setValuesFunction: setInitialValues,
      initialValues,
      serviceType,
    });
    setLoadingSubmit(false);
    setSuccessMessage(
      `Lista de ${entity.replace(/_/g, ' ')} modificada correctamente`
    );
  };

  const fetchData = async () => {
    fetchDataFunction({
      setValuesFunction: setInitialValues,
      setCategoriesFunction: setCategories,
      serviceType,
    });
    setLoading(false);
  };

  useEffect(fetchData, []);

  const submitButton = (btnProps) => (
    <Box align="center">
      <LoadingButton loading={loadingSubmit} text="Modificar" {...btnProps} />
    </Box>
  );

  const handleChange = (e) => {
    setServiceType(parseInt(e.target.value, 10));
  };

  return (
    <div className="DataLisForm">
      <LoadView isLoading={loading}>
        {entity !== 'tareas' ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className="DataListForm"
          >
            <Grid
              className="DataListFormGrid"
              item
              xs={12}
              lg={4}
              component={Paper}
              elevation={2}
            >
              {initialValues.length > 0 && (
                <OptionList
                  initialOptions={initialValues}
                  optionMinLength={1}
                  SubmitButton={submitButton}
                  onSubmit={onSubmit}
                  canReorder
                />
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className="DataListForm"
          >
            <Grid
              className="DataListFormGrid"
              item
              xs={12}
              lg={4}
              component={Paper}
              elevation={2}
            >
              <TextField
                id="cateogry_type"
                select
                fullWidth
                margin="normal"
                label="Elige una categoría"
                onChange={(e) => handleChange(e)}
                value={serviceType}
                defaultValue=""
              >
                {categories !== {} &&
                  Object.entries(categories).map(([key, value]) => (
                    <MenuItem key={`${key}`} value={`${key}`}>
                      {value}
                    </MenuItem>
                  ))}
              </TextField>
              {initialValues && initialValues.length > 0 && (
                <OptionList
                  initialOptions={initialValues[serviceType][1]}
                  optionMinLength={1}
                  SubmitButton={submitButton}
                  onSubmit={onSubmit}
                  canReorder
                />
              )}
            </Grid>
          </Grid>
        )}
      </LoadView>
    </div>
  );
};

DataListForm.propTypes = {
  entity: PropTypes.string,
  submitFunction: PropTypes.func,
  fetchDataFunction: PropTypes.func,
};

DataListForm.defaultProps = {
  entity: null,
  submitFunction: null,
  fetchDataFunction: null,
};

export default DataListForm;
