import requests from 'utils/requests';

const fetchVehicles = async () => {
  const result = await requests.get('/vehicles/');
  return result.json();
};

const postVehicles = async (vehicles) => {
  await requests.post('/vehicles/', vehicles);
};

export default {
  fetchVehicles,
  postVehicles,
};
