import React, { useState, useEffect } from 'react';
import { Typography, Box, Button } from '@material-ui/core';
import useService from 'utils/services';
import PropTypes from 'prop-types';
import LoadView from 'components/LoadView';
import PopUp from 'components/PopUp';
import useTableState from 'utils/table-state';

import './QuestionDeleteDialog.scss';

// eslint-disable-next-line react/prop-types
const PopUpBody = ({ row }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [currQuestion, setCurrQuestion] = useState({});
  const [blocks, setBlocks] = useState([]);

  // eslint-disable-next-line react/prop-types
  const { id } = row;
  const { question } = useService();

  const initialSetup = async () => {
    setIsLoading(true);

    const setBlockPromise = question
      .fetchAssociatedBlocks(id)
      .then((data) => setBlocks(data));
    const setQuestionPromise = question
      .fetchQuestion(id)
      .then((data) => setCurrQuestion(data));

    // Run both requests in parallel
    await Promise.all([setBlockPromise, setQuestionPromise]);
    setIsLoading(false);
  };

  useEffect(initialSetup, []);

  return (
    <LoadView isLoading={isLoading}>
      <Typography align="center">
        ¿Estás seguro de que quieres eliminar la pregunta
        <Box fontWeight="fontWeightBold" display="inline" ml="0.2rem">
          {currQuestion.value}
        </Box>
        ?
      </Typography>

      {blocks.length ? (
        <>
          <Box align="center" my={1}>
            La pregunta se encuentra en los siguientes bloques:{' '}
          </Box>
          <ul>
            {blocks.map((block) => {
              return <li>{block.name}</li>;
            })}
          </ul>
        </>
      ) : (
        <Box align="center" mt={3}>
          Esta pregunta no está asociada a ningún bloque
        </Box>
      )}
    </LoadView>
  );
};

function QuestionDeleteDialog({ open, setOpen, row }) {
  const [isLoading, setIsLoading] = useState(false);
  const { question } = useService();
  const { setShouldReload } = useTableState('questions');

  const { id } = row;

  const onClose = () => {
    setOpen(false);
  };

  const onDelete = async () => {
    setIsLoading(true);
    await question.deleteQuestion(id);
    setIsLoading(false);
    setShouldReload(true);
    setOpen(false);
  };

  const buttons = (
    <div className="buttons">
      <Button onClick={onClose} color="primary">
        Cancelar
      </Button>
      <Button
        className="question-delete-btn"
        onClick={onDelete}
        color="primary"
      >
        <LoadView isLoading={isLoading} size="1.2rem">
          Confirmar
        </LoadView>
      </Button>
    </div>
  );

  return (
    <div className="QuestionDeleteDialog">
      <PopUp
        open={open}
        onClose={onClose}
        title="Eliminar Pregunta"
        buttons={buttons}
      >
        <PopUpBody row={row} />
      </PopUp>
    </div>
  );
}

QuestionDeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  row: PropTypes.object.isRequired,
};

export default QuestionDeleteDialog;
