import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid } from '@material-ui/core';

/* Show CircularProgress unless data has loaded */
const LoadView = ({ children, isLoading, ...circularProps }) => {
  return (
    <>
      {isLoading ? (
        <Grid container justifyContent="center" alignItems="center" m={0}>
          <CircularProgress {...circularProps} />
        </Grid>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

LoadView.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default LoadView;
