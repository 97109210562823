/* eslint-disable no-plusplus */
import requests from 'utils/requests';

const capitalize = (strings) => {
  if (strings) {
    const str = strings.toLowerCase().split(' ');
    if (str.length > 1) {
      return `${str[0][0].toUpperCase()}${str[0].slice(1)} \
              ${str[1][0].toUpperCase()}${str[1].slice(1)}`;
    }
    return `${str[0][0].toUpperCase()}${str[0].slice(1)}`;
  }
  return '';
};

const fixTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};

const buildCategoryDict = async () => {
  const categories = await (await requests.get('/categories/')).json();
  const categoryDict = {};
  categories.forEach(({ id, name }) => {
    categoryDict[id] = name;
  });
  return categoryDict;
};

const userType = (type) => {
  const types = ['Administrador', 'Auditor', 'Cliente'];
  return types[type - 1];
};

/* Returns a new identical object minus the specified keys */
/* Useful when a DOM component doesn't use helperText for instance */
const rmKeys = (object, keysToRemove) => {
  const newObj = {};
  Object.entries(object)
    .filter((entry) => !keysToRemove.includes(entry[0]))
    .forEach(([key, value]) => {
      newObj[key] = value;
    });
  return newObj;
};

const changeDate = (string) => {
  if (string.includes('-')) {
    const [day, month, year] = string.split('-');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }
  const [day, month, year] = string.split('/');
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};

const changeTime = (string) => {
  const list = string.split(':');
  return `${list[0].padStart(2, 0)}:${list[1].padStart(2, 0)}`;
};

const exportInitialDate = (date) => {
  const [year, month, day] = date.split('-').map((t) => Number(t));
  return `${day}/${month}/${year}`;
};

const exportFinalTimestamp = (date, time) => {
  const [year, month, day] = date.split('-').map((t) => Number(t));
  return `${day}-${month}-${year} ${time}`;
};

const invertObject = (obj) => {
  const ret = {};
  Object.keys(obj).forEach((key) => {
    ret[obj[key]] = key;
  });
  return ret;
};

const makeJoin = (objsList, idsList) => {
  if (objsList && idsList)
    return objsList.filter((obj) => idsList.includes(obj.id));
  return [];
};

const getToday = () => {
  const today = new Date();
  const day = today.getDate().toString().padStart(2, '0');
  const month = (today.getMonth() + 1).toString().padStart(2, '0');
  const year = today.getFullYear();
  return `${year}-${month}-${day}`;
};

const getMonthAgo = () => {
  const today = new Date();
  const day = Math.min(today.getDate(), 28).toString().padStart(2, '0');
  const rawM = today.getMonth();
  const rawY = today.getFullYear();
  const [month, year] =
    rawM === 0 ? ['12', rawY - 1] : [`${rawM}`.padStart(2, '0'), rawY];
  return `${year}-${month}-${day}`;
};

const rutValid = (rutComplete) => {
  if (rutComplete) {
    if (
      rutComplete.toString().trim() !== '' &&
      rutComplete.toString().indexOf('-') > 0
    ) {
      const caract = [];
      const serie = [2, 3, 4, 5, 6, 7];
      const dig = rutComplete
        .toString()
        .substr(rutComplete.toString().length - 1, 1);
      const rut = rutComplete
        .toString()
        .substr(0, rutComplete.toString().length - 2);

      for (let i = 0; i < rut.length; i++) {
        caract[i] = parseInt(rut.charAt(rut.length - (i + 1)), 10);
      }
      let sum = 0;
      let k = 0;
      let rest = 0;
      for (let j = 0; j < caract.length; j++) {
        if (k === 6) {
          k = 0;
        }
        sum += parseInt(caract[j], 10) * parseInt(serie[k], 10);
        k++;
      }
      rest = sum % 11;
      let dv = 11 - rest;
      if (dv === 10) {
        dv = 'K';
      } else if (dv === 11) {
        dv = 0;
      }
      if (
        dv.toString().trim().toUpperCase() ===
        dig.toString().trim().toUpperCase()
      )
        return true;
      return false;
    }
  }
  return false;
};

export {
  buildCategoryDict,
  capitalize,
  fixTimestamp,
  getToday,
  getMonthAgo,
  rmKeys,
  changeDate,
  changeTime,
  exportInitialDate,
  exportFinalTimestamp,
  invertObject,
  makeJoin,
  userType,
  rutValid,
};
