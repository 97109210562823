import requests from 'utils/requests';
import { fixTimestamp } from 'utils';

const fetchNotifications = async () => {
  const data = await requests.get('/infopanel/');
  const info = await data.json();
  const list = Object.values(info).map((notification) => {
    let link;
    let text;
    const buttonText = 'Revisar';
    const date = fixTimestamp(notification.timestamp);
    if (notification.data_type === 0) {
      link = `/tecnicos/${notification.technician_id}`;
      text = `El auditor ${notification.auditor_name} ha creado un nuevo técnico en ${date}`;
    } else if (notification.data_type === 1) {
      link = `/tecnicos/${notification.technician_id}/`;
      text = `El auditor ${notification.auditor_name} ha editado un técnico en ${date}`;
    } else if (notification.data_type === 2) {
      link = `/auditorias/${notification.answer_id}/`;
      text =
        `El auditor ${notification.auditor_name} ha agregado ` +
        `${notification.photos} foto(s) adicional(es) a la auditoría ` +
        `${notification.answer_id} en ${date}`;
    }
    const node = {
      id: notification.id,
      timestamp: notification.timestamp,
      link,
      text,
      buttonText,
    };
    return node;
  });
  list.sort((a, b) => b.id - a.id);
  return list;
};

const deleteNotification = async (id) => {
  return requests.del(`/infodata/${id}/`);
};

const deleteNotificationAll = async () => {
  const tzoffset = new Date().getTimezoneOffset() * 60000;
  let localISOTime = new Date(Date.now() - tzoffset).toISOString().slice(0, -1);
  localISOTime += '000-04:00';
  const body = {
    timestamp: localISOTime,
  };
  const response = await requests.del(`/infopanel/`, body);
  return response.ok;
};

export default {
  fetchNotifications,
  deleteNotification,
  deleteNotificationAll,
};
