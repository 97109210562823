import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import './SearchBar.scss';
import { Box } from '@material-ui/core';

const SearchBar = ({ keyword, setKeyword, cancelSearch, placeholder }) => {
  return (
    <Box className="SearchBar">
      <SearchIcon />
      <input
        className="input"
        key="1"
        value={keyword}
        placeholder={placeholder}
        onChange={(e) => setKeyword(e.target.value)}
      />
      <IconButton aria-label="cross" size="small" onClick={cancelSearch}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

SearchBar.propTypes = {
  keyword: PropTypes.string.isRequired,
  setKeyword: PropTypes.func.isRequired,
  cancelSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default SearchBar;
