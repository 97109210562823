import React from 'react';
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';
import AnsweredQuestion from 'components/AnsweredQuestion';
import './Block.scss';

const Block = ({ data, able, save, edit }) => {
  let num = 0;
  const responseComponents = able
    ? data.map((response) => {
        if ([0, 1, 2, 3, 4].includes(response.category)) {
          num += 1;
          return (
            <AnsweredQuestion
              key={response.id}
              id={response.id}
              number={num}
              category={response.category}
              question={response.question}
              answer={response.answer}
              possibleValues={response.possible_values}
              show={able}
              saveAnswer={save}
              edit={edit}
            />
          );
        }
        return null;
      })
    : data.map((response, index) => (
        <AnsweredQuestion
          key={response.id}
          id={response.id}
          number={index + 1}
          category={response.category}
          question={response.question}
          answer={response.answer}
          possibleValues={response.possible_values}
          show={able}
          saveAnswer={save}
          edit={edit}
        />
      ));
  return (
    <div>
      <div className="Section">
        <div className="smallboxContent">
          <Container maxWidth="xl">{responseComponents}</Container>
        </div>
      </div>
    </div>
  );
};

Block.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array])
    )
  ).isRequired,
  able: PropTypes.bool.isRequired,
  save: PropTypes.func,
  edit: PropTypes.func,
};

Block.defaultProps = {
  save: null,
  edit: null,
};

export default Block;
