import { useHistory } from 'react-router-dom';

const useRouterUtils = () => {
  const history = useHistory();

  const redirect = (path) => {
    history.push(path);
  };

  return { redirect };
};

export default useRouterUtils;
