/* eslint-disable no-alert */
import { React } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import useService from 'utils/services';
import './CategoryIndex.scss';
import { ResponsiveTableGrid } from 'components';
import useRouterUtils from 'utils/router/routerUtilsHook';

const CategoryIndex = () => {
  const { formtree, category } = useService();
  const { redirect } = useRouterUtils();

  const headers = [
    /* {
      field: 'id',
      headerName: 'ID',
      width: 100,
      tooltip: false,
    }, */
    {
      field: 'name',
      headerName: 'Nombre',
    },
    {
      field: 'number_tech',
      headerName: 'Número de Técnicos',
      flex: 1,
    },
    {
      field: 'levels',
      headerName: 'Cantidad de Filtros',
      flex: 1,
    },
    {
      field: 'goal_rm',
      headerName: 'Meta Auditorías RM',
      flex: 1,
    },
    {
      field: 'goal_other',
      headerName: 'Meta Auditorías Regiones',
      flex: 1,
    },
  ];

  return (
    <div>
      <h2 className="title"> Categorías</h2>
      <div className="CategoryIndex">
        <Button
          className="new-entity-btn"
          component={Link}
          to="/categorias/nueva"
          variant="contained"
        >
          Nueva Categoría
        </Button>
        <ResponsiveTableGrid
          fetchAllFunc={formtree.fetchCategoriesInfo}
          headers={headers}
          tableName="categories"
          canView
          canEdit
          canDelete
          deleteNotification={() => 'Categoría Eliminada'}
          deleteEntityName={(row) => ` la categoría ${row.name}`}
          onView={(row) => redirect(`/categorias/${row.id}`)}
          onEdit={(row) => redirect(`/categorias/editar/${row.id}`)}
          onDelete={(row) => category.deleteCategory(row.id)}
        />
      </div>
    </div>
  );
};
export default CategoryIndex;
