import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import useService from 'utils/services';

export default function CloseSessionButton({ logout }) {
  const [loading, setLoading] = useState(false);
  const { session } = useService();

  async function closeSession() {
    setLoading(true);
    await session.destroySession();
    setLoading(false);
    logout();
  }

  return (
    <button type="button" className="CloseSessionButton" onClick={closeSession}>
      {loading ? <CircularProgress size="0.95rem" /> : 'Cerrar Sesión'}
    </button>
  );
}

CloseSessionButton.propTypes = {
  logout: PropTypes.func.isRequired,
};
