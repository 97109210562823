import React from 'react';

import useNotificationState from 'utils/notifications/notificationStateHook';
import BaseNotification from '../BaseNotification';

const Notification = () => {
  const { open, setOpen, timeout, message, severity } = useNotificationState();

  return (
    <BaseNotification
      open={open || false}
      severity={severity || ''}
      setOpen={setOpen}
      timeout={timeout}
      message={message || 'Este texto no se debería ver'}
    />
  );
};

export default Notification;
