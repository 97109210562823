import requests from 'utils/requests';

const fetchEnterprises = async () => {
  const result = await requests.get('/enterprises/');
  return result.json();
};

const fetchEnterprise = async (id) => {
  const result = await requests.get(`/enterprise/${id}/`);
  return result.json();
};

const postEnterprise = async (values) => {
  return requests.post('/enterprises/', values);
};

const patchEnterprise = async (values, id) => {
  return requests.patch(`/enterprise/${id}/`, values);
};

const deleteEnterprise = async (id) => {
  return requests.del(`/enterprise/${id}/`);
};

export default {
  fetchEnterprises,
  fetchEnterprise,
  postEnterprise,
  patchEnterprise,
  deleteEnterprise,
};
