import React from 'react';
import { Grid } from '@material-ui/core';
import TableGrid from './TableGrid';

const ResponsiveTableGrid = (props) => {
  return (
    <Grid container item xs justifyContent="center">
      <Grid item xs={12} md={11} lg={10} m={3}>
        <TableGrid {...props} />
      </Grid>
    </Grid>
  );
};

export default ResponsiveTableGrid;
