import React from 'react';
import { Box, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ResponsiveTableGrid } from 'components';
import useService from 'utils/services';
import useRouterUtils from 'utils/router/routerUtilsHook';

export default function EnterprisesIndex() {
  const { enterprise } = useService();
  const { redirect } = useRouterUtils();

  const headers = [
    {
      field: 'name',
      headerName: 'Nombre',
    },
  ];

  return (
    <div className="EnterprisesIndex">
      <h2 className="title">Empresas</h2>
      <Box align="center">
        <Button
          component={Link}
          to="/empresas/nueva"
          className="new-entity-btn"
          variant="contained"
        >
          Nueva empresa
        </Button>
      </Box>

      <ResponsiveTableGrid
        fetchAllFunc={enterprise.fetchEnterprises}
        headers={headers}
        tableName="enterprises"
        canEdit
        canDelete
        deleteNotification={() => 'Empresa Eliminada'}
        deleteEntityName={(row) => ` la empresa ${row.name}`}
        onEdit={(row) => redirect(`/empresas/editar/${row.id}`)}
        onDelete={(row) => enterprise.deleteEnterprise(row.id)}
      />
    </div>
  );
}
