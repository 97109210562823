import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

const LoginRedirect = () => {
  const location = useLocation();
  useEffect(() => {
    localStorage.setItem('login-redirect', location.pathname);
  }, [location]);

  return <Redirect to="/iniciar-sesion" />;
};

export default LoginRedirect;
