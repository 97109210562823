import React, { useState, useEffect } from 'react';
import { Typography, Box, Button } from '@material-ui/core';
import useService from 'utils/services';
import PropTypes from 'prop-types';
import useNotification from 'utils/notifications/notificationHook';
import LoadView from 'components/LoadView';
import PopUp from 'components/PopUp';

import './BlockDelete.scss';
import useTableState from 'utils/table-state';

// eslint-disable-next-line react/prop-types
const PopUpBody = ({ row, form }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [currBlock, setCurrBlock] = useState({});
  const [forms, setForms] = useState([]);

  // eslint-disable-next-line react/prop-types
  const { id } = row;
  const { block } = useService();

  const initialSetup = async () => {
    setIsLoading(true);

    const setFormPromise = block
      .fetchAssociatedForms(id)
      .then((data) => setForms(data));
    const setBlockPromise = block
      .fetchSingleBlock(id)
      .then((data) => setCurrBlock(data));

    // Run both requests in parallell
    if (form) {
      await Promise.all([setFormPromise, setBlockPromise]);
    } else {
      const data = await block.fetchSingleBlock(id);
      setCurrBlock(data);
    }
    setIsLoading(false);
  };

  useEffect(initialSetup, []);

  return (
    <LoadView isLoading={isLoading}>
      <Typography align="center">
        ¿Estás seguro de que quieres eliminar el bloque
        <Box fontWeight="fontWeightBold" display="inline" ml="0.2rem">
          {currBlock.name}
        </Box>
        ?
      </Typography>

      {forms.length ? (
        <>
          <Box align="center" my={1}>
            El bloque se encuentra en los siguientes formularios:{' '}
          </Box>
          <ul>
            {forms.map((f) => {
              return <li>{f.name}</li>;
            })}
          </ul>
        </>
      ) : (
        <Box align="center" mt={3}>
          Este bloque no está asociado a ningún formulario.
        </Box>
      )}
    </LoadView>
  );
};

function BlockDelete({ open, setOpen, row }) {
  const [isLoading, setIsLoading] = useState(false);
  const { setSuccessMessage, setErrorMessage } = useNotification();
  const { block } = useService();

  const tableName = row.isForm ? 'blocks-form' : 'blocks-report';

  const { setShouldReload } = useTableState(tableName);

  const onClose = () => {
    setOpen(false);
  };

  const { id, isForm: form } = row;

  const onDelete = async () => {
    setIsLoading(true);
    const response = await block.deleteBlock(id);
    if (response) {
      setSuccessMessage('Bloque Eliminado');
    } else {
      setErrorMessage('Hubo un error al eliminar el bloque');
    }
    setIsLoading(false);
    setShouldReload(true);
    setOpen(false);
  };

  const buttons = (
    <div className="buttons">
      <Button onClick={onClose} color="primary">
        Cancelar
      </Button>
      <Button className="block-delete-btn" onClick={onDelete} color="primary">
        <LoadView isLoading={isLoading} size="1.2rem">
          Confirmar
        </LoadView>
      </Button>
    </div>
  );

  return (
    <div className="BlockDelete">
      <PopUp
        open={open}
        onClose={onClose}
        title="Eliminar Bloque"
        buttons={buttons}
      >
        <PopUpBody row={row} form={form} />
      </PopUp>
    </div>
  );
}

BlockDelete.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  row: PropTypes.object.isRequired,
};

export default BlockDelete;
