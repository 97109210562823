/* eslint-disable no-alert */
import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import AuditInfo from 'components/AuditInfo';
import AuditPhotos from 'components/AuditPhotos';
import AuditAnswers from 'components/AuditAnswers';
import { BackButton, GoogleMap, LoadView } from 'components';
import useService from 'utils/services';

import './AuditShow.scss';

const Audit = () => {
  const { id } = useParams();
  const location = useLocation();
  const filt = Boolean(location.search.slice(1).split('=')[1]);
  const [infoAudit, setInfoAudit] = useState({});
  const [infoBlocksAudit, setInfoBlocksAudit] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [values, setValues] = useState({});
  const [origin, setOrigin] = useState({});
  const [destiny, setDestiny] = useState({});
  const [technicians, setTechnicians] = useState([]);

  const { audit } = useService();

  const getAudit = async () => {
    const [infoAuditData, infoBlocks, infoImages] = await audit.fetchAudit(id);
    try {
      setInfoAudit(infoAuditData);
      setValues(infoAuditData.values);
      setInfoBlocksAudit(infoBlocks);
      setImageData(infoImages);
      setTechnicians(infoAuditData.technicians);

      const { locationData } = infoAuditData;
      const originData = {
        latitude: locationData.initial_latitude,
        longitude: locationData.initial_longitude,
      };
      const destinyData = {
        latitude: locationData.final_latitude,
        longitude: locationData.final_longitude,
      };

      setOrigin(originData);
      setDestiny(destinyData);

      setLoading(false);
    } catch (e) {
      window.alert('Hubo un problema al cargar la auditoría');
    }
    return null;
  };

  useEffect(getAudit, []);

  const editAnswer = (idQuestion, newValue) => {
    const newValues = { ...values };
    newValues[parseInt(idQuestion, 10)] = newValue;
    setValues(newValues);
  };

  const patchAnswers = async () => {
    await audit.patchAuditAnswers(id, values);
    // TODO: just reload data and show notification of changes
    window.location.reload();
  };

  return (
    <div>
      <LoadView isLoading={isLoading}>
        <div className="AuditShow">
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={10}>
              <BackButton
                to={`/auditorias/${filt ? 'filtrar' : 'recientes'}`}
              />
              <AuditInfo dataAudit={infoAudit} technicians={technicians} />
              <GoogleMap origin={origin} destiny={destiny} />
              {imageData.length > 0 && <AuditPhotos imageData={imageData} />}
              <AuditAnswers
                able
                dataSections={infoBlocksAudit}
                save={editAnswer}
                patchMethod={patchAnswers}
              />
            </Grid>
          </Grid>
        </div>
      </LoadView>
    </div>
  );
};

export default Audit;
