import { useSessionContext } from 'utils/session/sessionContext';
import audit from 'services/audits';
import block from 'services/blocks';
import category from 'services/categories';
import commune from 'services/communes';
import enterprise from 'services/enterprises';
import form from 'services/forms';
import formtree from 'services/formtree';
import province from 'services/provinces';
import question from 'services/questions';
import region from 'services/regions';
import report from 'services/reports';
import session from 'services/sessions';
import team from 'services/teams';
import tech from 'services/techs';
import user from 'services/users';
import dashboard from 'services/dashboard';
import panel from 'services/panel';
import requests from 'utils/requests';
import vehicle from 'services/vehicles';
import taskType from 'services/taskTypes';
import nonOkOption from 'services/nonOkOptions';
import useNotification from 'utils/notifications/notificationHook';

// Add services in singular here so they can be used in useService hook
const includedServices = {
  audit,
  block,
  category,
  commune,
  enterprise,
  form,
  formtree,
  province,
  question,
  region,
  report,
  session,
  team,
  tech,
  user,
  vehicle,
  taskType,
  nonOkOption,
  dashboard,
  panel,
};

const useService = () => {
  const { logout } = useSessionContext();

  const { setWarningMessage } = useNotification();

  const authDecorator = (func) => {
    const newFunc = async (...args) => {
      try {
        const ret = await func(...args);
        return ret;
      } catch (error) {
        if (error instanceof requests.AuthError) {
          setWarningMessage(
            'Se ha cerrado tu sesión ya que fue iniciada en otro dispositivo.'
          );
          logout();
          return null;
        }
        throw error;
      }
    };
    return newFunc;
  };

  const services = {};
  Object.entries(includedServices).forEach((entry) => {
    const [key, funcs] = entry;
    const newFuncs = {};
    Object.entries(funcs).forEach((funcsEntry) => {
      const [funcName, func] = funcsEntry;
      newFuncs[funcName] = authDecorator(func);
    });

    services[key] = newFuncs;
  });
  return services;
};

export default useService;
