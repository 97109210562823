import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Form, LoadView, fieldProps } from 'components';
import LoadingButton from 'components/LoadingButton';
import { rmKeys } from 'utils';
import useNotification from 'utils/notifications/notificationHook';
import useService from 'utils/services';
import './CategoryShow.scss';

const validationSchema = yup.object({
  token: yup.string().required('Campo obligatorio'),
});

const CategoryShow = () => {
  const [isLoading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [title, setTitle] = useState('Cargando');
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { id } = useParams();
  const { category, report } = useService();
  const { setSuccessMessage, setErrorMessage } = useNotification();

  const onSubmit = async (values) => {
    setLoadingSubmit(true);
    const { token, active_report: actReport } = values;
    const status1 = await report.updateForAPI(actReport);
    const status2 = await category.updateToken(id, token);
    // TODO: check separately (if only one fails, the other change is applied anyway)
    /*
    if (status1.ok) setSuccessMessage('¡Reporte activo cambiado con éxito!');
    else setErrorMessage('Hubo un error al intentar cambiar el reporte activo');
    if (status2.ok) setSuccessMessage('Token cambiado con éxito!');
    else setErrorMessage('Hubo un error al intentar cambiar el token');
    */
    if (status1.ok && status2.ok)
      setSuccessMessage('¡Cambios guardados con éxito!');
    else setErrorMessage('Hubo un error al guardar los cambios');
    setLoadingSubmit(false);
  };

  const formik = useFormik({
    initialValues: {
      token: '',
      active_report: '',
      tid: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  const fetchData = async () => {
    const {
      id: tid,
      name,
      token,
    } = (await category.fetchTokens()).filter(
      (tok) => `${tok.id}` === `${id}`
    )[0];
    const reportData = (await report.fetchAll(true)).filter(
      (repord) => `${repord.category}` === `${id}`
    );
    const currentReport = Object.values(await report.fetchForAPI()).filter(
      (repord) => `${repord.category}` === `${id}`
    );
    formik.setFieldValue(
      'active_report',
      currentReport.length > 0 ? currentReport[0].id : -1
    );
    formik.setFieldValue('tid', `${tid}`);
    formik.setFieldValue('token', token);
    setReports(reportData);
    setTitle(name);
    setLoading(false);
  };
  useEffect(fetchData, []);

  return (
    <Grid container className="CategoryShow" justifyContent="center">
      <Grid item xs={12} lg={4}>
        <Form onSubmit={formik.handleSubmit} title={title}>
          <LoadView isLoading={isLoading}>
            <TextField
              label="ID"
              id="tid"
              name="tid"
              margin="normal"
              disabled
              {...fieldProps(formik, 'tid')}
            />
            <TextField
              label="Token"
              id="token"
              name="token"
              margin="normal"
              fullWidth
              {...fieldProps(formik, 'token')}
            />
            <FormControl margin="normal" fullWidth>
              <InputLabel className="labelField" id="active_report">
                Reporte Activo
              </InputLabel>
              <Select
                id="active_report"
                name="active_report"
                fullWidth
                {...rmKeys(fieldProps(formik, 'active_report'), ['helperText'])}
              >
                <MenuItem value="-1">Ninguno</MenuItem>
                {Object.values(reports).map(({ id: rid, name }) => (
                  <MenuItem key={rid} value={rid}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box align="center">
              <LoadingButton loading={loadingSubmit} text="Guardar cambios" />
            </Box>
          </LoadView>
        </Form>
      </Grid>
    </Grid>
  );
};

export default CategoryShow;
