import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import useService from 'utils/services';
import './DisplayQuestions.scss';

const DisplayQuestions = (props) => {
  const { id, isQuestion, forTable } = props;
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});
  const [isLoading, setLoading] = useState(true);
  const { block, question } = useService();

  const fetchData = async () => {
    try {
      let data;
      if (isQuestion) {
        data = await question.fetchQuestion(id);
      } else {
        data = await block.fetchSingleBlock(id);
      }
      setValues(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
    fetchData();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="displayQuestions">
      <Box align="center" className="DisplayQuestions">
        {forTable ? (
          <Button className="buttonView" onClick={handleClickOpen}>
            Ver
          </Button>
        ) : (
          <IconButton
            aria-label="open pop-up"
            size="small"
            color="primary"
            onClick={handleClickOpen}
          >
            <VisibilityIcon />
          </IconButton>
        )}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {isLoading && (
            <Box m={2}>
              <CircularProgress m={2} />
            </Box>
          )}
          {!isLoading && (
            <div>
              <IconButton
                className="close"
                aria-label="close pop-up"
                size="small"
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
              <DialogTitle id="alert-dialog-title" align="center">
                {isQuestion
                  ? `Valores Posibles Pregunta ${values.value}`
                  : `Preguntas Bloque ${values.name}`}
              </DialogTitle>
              <DialogContent>
                <Box margin={1}>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>{isQuestion ? '' : 'Número'}</TableCell>
                        {!isQuestion && <TableCell>Pregunta</TableCell>}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isQuestion
                        ? values.options.possible_values.map((value) => (
                            <TableRow key={value}>
                              <TableCell
                                component="th"
                                scope="row"
                                align="center"
                              >
                                {value}
                              </TableCell>
                            </TableRow>
                          ))
                        : values.questions_Selected.map((questionRow, i) => (
                            <TableRow key={questionRow.value}>
                              <TableCell
                                component="th"
                                scope="row"
                                align="center"
                              >
                                {i + 1}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {questionRow.value}
                              </TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </Box>
              </DialogContent>
            </div>
          )}
        </Dialog>
      </Box>
    </div>
  );
};

DisplayQuestions.propTypes = {
  id: PropTypes.number.isRequired,
  isQuestion: PropTypes.bool.isRequired,
  forTable: PropTypes.bool.isRequired,
};

export default DisplayQuestions;
