import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';
import useRouterUtils from 'utils/router/routerUtilsHook';
import useService from 'utils/services';
import { ResponsiveTableGrid } from 'components';
import QuestionDeleteDialog from './QuestionDeleteDialog';

const QuestionIndex = () => {
  const { question } = useService();
  const { redirect } = useRouterUtils();

  const headers = [
    {
      field: 'value',
      headerName: 'Título',
      flex: 1,
    },
    {
      field: 'category',
      headerName: 'Categoría',
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Tipo',
      flex: 1,
    },
    {
      field: 'option_qty',
      headerName: 'Cantidad de Opciones',
      flex: 1,
    },
  ];

  return (
    <div className="QuestionIndex">
      <h2 className="title">Preguntas</h2>
      <Box align="center">
        <Button
          component={Link}
          to="/preguntas/nuevo"
          className="new-entity-btn"
          variant="contained"
        >
          Nueva Pregunta
        </Button>
      </Box>
      <br />
      <ResponsiveTableGrid
        fetchAllFunc={question.fetchQuestions}
        headers={headers}
        tableName="questions"
        canView
        canEdit
        canDelete
        onView={(row) => redirect(`/preguntas/${row.id}`)}
        onEdit={(row) => redirect(`/preguntas/editar/${row.id}`)}
        DeleteDialog={QuestionDeleteDialog}
      />
    </div>
  );
};

export default QuestionIndex;
