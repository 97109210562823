import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TextField, Box, Grid } from '@material-ui/core/';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useTableState from 'utils/table-state';
import { BackButton } from 'components';
import LoadingButton from 'components/LoadingButton';
import useRouterUtils from 'utils/router/routerUtilsHook';
import useService from 'utils/services';
import { Form, fieldProps } from 'components/Form';

const validationSchema = yup.object({
  name: yup.string().required('Este campo es obligatorio'),
  province_name: yup.string().required('Este campo es obligatorio'),
});

const CommuneForm = ({ isEdit, title }) => {
  const [provinces, setProvinces] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState({ name: '-' });
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { id } = useParams();
  const { commune, province, region } = useService();
  const { setShouldReload } = useTableState('communes');
  const { redirect } = useRouterUtils();

  const onSubmit = async (values) => {
    setLoadingSubmit(true);
    // eslint-disable-next-line camelcase
    const { province_name, ...realValues } = values;
    if (isEdit) await commune.patchCommune(realValues, id);
    else await commune.postCommune(realValues);
    setLoadingSubmit(false);
    setShouldReload(true);
    redirect('/comunas');
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      province_name: '',
      province_id: '',
      region_zona_att: 1,
      region_crm: 1,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  const romanToNum = (roman) => {
    if (roman === '') return 0;
    if (roman.startsWith('X')) return 10 + romanToNum(roman.substr(1));
    if (roman.startsWith('IX')) return 9 + romanToNum(roman.substr(2));
    if (roman.startsWith('V')) return 5 + romanToNum(roman.substr(1));
    if (roman.startsWith('IV')) return 4 + romanToNum(roman.substr(2));
    if (roman.startsWith('I')) return 1 + romanToNum(roman.substr(1));
    if (roman.startsWith('RM')) return 5.5;
    return 0;
  };

  const getOrdinal = (name) => {
    const firstPart = name.split()[0];
    return firstPart.substring(1, firstPart.length - 1);
  };

  const fetchData = async () => {
    const regionsData = await region.fetchRegions();
    const regionsObj = {};
    regionsData.forEach((regionObj) => {
      if (regionObj.region_ordinal !== '0') {
        regionsObj[regionObj.id] = {};
        regionsObj[
          regionObj.id
        ].name = `(${regionObj.region_ordinal}) ${regionObj.name}`;
        regionsObj[regionObj.id].region_zona_att = regionObj.region_zona_att;
        regionsObj[regionObj.id].region_crm = regionObj.region_crm;
      }
    });

    const provincesData = await province.fetchProvinces();
    const formattedProvinces = provincesData.map((provinceObj) => {
      return {
        ...provinceObj,
        regionName: regionsObj[provinceObj.region_id].name,
        // eslint-disable-next-line camelcase
        region_zona_att: regionsObj[provinceObj.region_id].region_zona_att,
        region_crm: regionsObj[provinceObj.region_id].region_crm,
      };
    });
    setProvinces(
      formattedProvinces.sort(
        (a, b) =>
          romanToNum(getOrdinal(a.regionName)) -
            romanToNum(getOrdinal(b.regionName)) || a.name.localeCompare(b.name)
      )
    );

    if (isEdit && id) {
      const data = await commune.fetchCommune(id);
      const provinceData = formattedProvinces.filter(
        (provObj) => provObj.id === data.province_id
      )[0];
      await formik.setValues({
        name: data.name,
        province_name: provinceData.name,
        province_id: provinceData.id,
      });
    }
  };

  useEffect(fetchData, []);

  useEffect(() => {
    setSelectedProvince(
      provinces.find((provObj) => provObj.name === formik.values.province_name)
    );
  }, [formik.values.province_name]);

  const handleClick = (provinceObj) => {
    // TODO:
    // Solve region_zona_att and region_crm issues
    if (provinceObj) {
      formik.setFieldValue('province_name', provinceObj.name);
      formik.setFieldValue('province_id', provinceObj.id);
      formik.setFieldValue(
        'region_zona_att',
        provinceObj.region_zona_att !== undefined
          ? provinceObj.region_zona_att
          : 1
      );
      formik.setFieldValue(
        'region_crm',
        provinceObj.region_crm !== undefined ? provinceObj.region_crm : 1
      );
      setSelectedProvince(provinceObj);
    }
  };

  const GoBack = () => <BackButton to="/comunas" />;

  return (
    <div>
      <div className="communeForm">
        <Grid container justifyContent="center">
          <Grid item xs={4}>
            <Form
              onSubmit={formik.handleSubmit}
              title={title}
              HeaderComponent={GoBack}
            >
              <TextField
                fullWidth
                label="Nombre"
                margin="normal"
                id="name"
                name="name"
                className="name"
                {...fieldProps(formik, 'name')}
              />
              {provinces && (
                <Autocomplete
                  id="grouped"
                  value={selectedProvince}
                  options={provinces}
                  groupBy={(provinceObj) => provinceObj.regionName}
                  getOptionLabel={(provinceObj) => provinceObj.name}
                  onChange={(e, provinceObj) => handleClick(provinceObj)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Provincia"
                      variant="outlined"
                      {...fieldProps(formik, 'province_name')}
                    />
                  )}
                />
              )}

              <Box align="center">
                <LoadingButton loading={loadingSubmit} isEdit={isEdit} />
              </Box>
            </Form>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

CommuneForm.propTypes = {
  isEdit: PropTypes.bool,
  title: PropTypes.string,
};

CommuneForm.defaultProps = {
  isEdit: false,
  title: 'Nueva comuna',
};

export default CommuneForm;
