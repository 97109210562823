import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Box } from '@material-ui/core';
import useService from 'utils/services';
import { ResponsiveTableGrid } from 'components';
import useRouterUtils from 'utils/router/routerUtilsHook';

const UserIndex = () => {
  const { user } = useService();
  const { redirect } = useRouterUtils();

  const headers = [
    {
      field: 'rut',
      headerName: 'RUT',
      width: 150,
    },
    {
      field: 'lastName',
      headerName: 'Apellidos',
      flex: 1,
    },
    {
      field: 'firstName',
      headerName: 'Nombre',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'stringedType',
      headerName: 'Tipo',
      width: 150,
    },
    {
      field: 'niceTime',
      headerName: 'Último Ingreso',
    },
  ];

  return (
    <>
      <h2 className="title">Usuarios</h2>
      <Box align="center">
        <Button
          className="new-entity-btn"
          component={Link}
          to="/usuarios/nuevo"
          variant="contained"
        >
          Nuevo usuario
        </Button>
      </Box>

      <ResponsiveTableGrid
        fetchAllFunc={user.fetchAll}
        headers={headers}
        tableName="users"
        canEdit
        canDelete
        deleteEntityName={(row) => ` el usuario ${row.firstName}`}
        onEdit={(row) => redirect(`/usuarios/editar/${row.id}`)}
        onDelete={(row) => user.destroy(row.id)}
      />
    </>
  );
};

export default UserIndex;
