import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Block from 'components/Block';
import './AnswersAccordion.scss';

const AnswersAccordeon = ({ dataSections, able, save, edit }) => {
  return (
    <div className="AnswersAccordeon">
      {dataSections.map((sec, index) => (
        <Accordion key={sec.name} className="accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="headingAccordion">
              {`${index + 1}. ${sec.name}`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="details">
            <Block data={sec.questions} able={able} save={save} edit={edit} />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

AnswersAccordeon.propTypes = {
  dataSections: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array]))
  ).isRequired,
  able: PropTypes.bool.isRequired,
  save: PropTypes.func,
  edit: PropTypes.func,
};
AnswersAccordeon.defaultProps = {
  save: null,
  edit: null,
};
export default AnswersAccordeon;
