const fieldProps = (formik, name, options) => {
  const defaultOptions = {
    initialValue: '',
  };
  const fieldOptions = { ...defaultOptions, ...options };

  return {
    name,
    value: formik.values[name] || fieldOptions.initialValue,
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    error:
      (formik.touched[name] && Boolean(formik.errors[name])) ||
      formik.status === 'fields-error',
    helperText: formik.touched[name] && formik.errors[name],
  };
};

export default fieldProps;
