import requests from 'utils/requests';

const createSession = async (username, password) => {
  const response = await requests.post(
    '/users/login/',
    { username, password },
    { withoutToken: true }
  );

  if (response.status !== 201) return null;
  return response.json();
};

const destroySession = async () => {
  return requests.post('/users/logout/', {});
};

export default { createSession, destroySession };
