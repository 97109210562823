import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Container, Grid } from '@material-ui/core';
import useNotification from 'utils/notifications/notificationHook';
import { useSessionContext } from 'utils/session/sessionContext';
import DeletePopUp from 'components/DeletePopUp';
import useService from 'utils/services';
import useTableState from 'utils/table-state';
import FormRow from './FormRow';
import './AuditInfo.scss';

const AuditInfo = ({ dataAudit, technicians }) => {
  const { currentUser } = useSessionContext();
  const { setSuccessMessage, setErrorMessage } = useNotification();
  const { audit } = useService();

  const { setShouldReload } = useTableState('audits');

  const messages = ['Se editó el técnico', 'Se agregó el técnico'];
  const tecMessage = (index) => {
    const { status, technician_name: name } = technicians[index];
    if ([2, 3].includes(status)) return `${messages[status - 2]} ${name}`;
    return '';
  };

  const onDelete = async (entityId) => {
    const response = await audit.deleteAudit(entityId);
    if (response) {
      setSuccessMessage('Auditoría Eliminada');
      setShouldReload(true);
      return;
    }

    setErrorMessage('Hubo un error al eliminar la auditoría');
  };

  return (
    <div>
      <Container className="AuditInfo" maxWidth="xl">
        <h2 className="headline"> Detalles Auditoría</h2>
        <strong>{tecMessage(0)}</strong>
        <br />
        <strong>{tecMessage(1)}</strong>
        {currentUser.isAdmin && (
          <>
            <div className="deleteAudit">
              <DeletePopUp
                className="buttonDelete"
                buttonText="Eliminar Auditoría"
                entity="esta auditoría"
                variant="contained"
                deleteFunction={onDelete}
                id={dataAudit.id}
                redirectPath="/auditorias"
              />
            </div>
            <Button
              className="editAudit"
              component={Link}
              variant="contained"
              to={`/auditoria/editar/${dataAudit.id}/`}
            >
              Editar Información
            </Button>
          </>
        )}
      </Container>
      <Container maxWidth="xl">
        <Grid key={dataAudit.id} container spacing={1} justifyContent="center">
          <Grid container item xs={12} spacing={1} justifyContent="center">
            <FormRow
              auditId={dataAudit.id}
              row={{
                Categoría: dataAudit.category,
                'Tipo de Auditoría': dataAudit.audit_type,
                'Tipo de Servicio': dataAudit.service_type,
              }}
            />
          </Grid>
          <Grid container item xs={12} spacing={1} justifyContent="center">
            <FormRow
              auditId={dataAudit.id}
              row={{
                'Técnico(s)': dataAudit.technician,
                'Rut(s) Técnico(s)': dataAudit.technician_rut,
                Empresa: dataAudit.technician_enterprise,
              }}
            />
          </Grid>
          <Grid container item xs={12} spacing={1} justifyContent="center">
            <FormRow
              auditId={dataAudit.id}
              row={{
                Auditor: dataAudit.auditor,
                'Fecha Inicio': dataAudit.startTime,
                'Fecha Término': dataAudit.finishTime,
              }}
            />
          </Grid>
          <Grid container item xs={12} spacing={1} justifyContent="center">
            <FormRow
              auditId={dataAudit.id}
              row={{
                Comuna: dataAudit.commune,
                Dirección: dataAudit.address,
                'Tipo de tarea': dataAudit.task_type,
              }}
            />
          </Grid>
          <Grid container item xs={12} spacing={1} justifyContent="center">
            <FormRow
              auditId={dataAudit.id}
              row={{
                'Modelo Camioneta': dataAudit.truck_model,
                'Patente Camioneta': dataAudit.license_plate,
                'Número de tarea': dataAudit.task_number,
              }}
            />
          </Grid>
          <Grid container item xs={12} spacing={1} justifyContent="center">
            <FormRow
              auditId={dataAudit.id}
              row={{
                '¿Auditoría OK?': dataAudit.audit_ok,
                'Razones Auditoría No OK': dataAudit.comments_non_ok,
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

AuditInfo.propTypes = {
  dataAudit: PropTypes.instanceOf(Object).isRequired,
  technicians: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

AuditInfo.defaultProps = {
  technicians: [{ status: 1 }, { status: 0 }],
};

export default AuditInfo;
