import React from 'react';

const NavBarBtn = () => (
  <>
    <input type="checkbox" id="navbar-check" />
    <div className="navbar-btn">
      <label htmlFor="navbar-check">
        <span />
        <span />
        <span />
      </label>
    </div>
  </>
);

export default NavBarBtn;
