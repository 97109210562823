import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

const LoadingButton = ({ loading, isEdit, text, ...btnProps }) => {
  let buttonText = '';
  if (text) {
    buttonText = text;
  } else {
    buttonText = isEdit ? 'Modificar' : 'Crear';
  }
  return (
    <Button
      type="submit"
      className="button-submit"
      style={{ width: `${buttonText.length + 2}ch`, height: '2.1rem' }}
      {...btnProps}
    >
      {loading ? (
        <CircularProgress color="inherit" size="1.2rem" />
      ) : (
        <>{buttonText}</>
      )}
    </Button>
  );
};

LoadingButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool,
  text: PropTypes.string,
};

LoadingButton.defaultProps = {
  isEdit: false,
  text: '',
};
export default LoadingButton;
