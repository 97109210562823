import requests from 'utils/requests';

const fetchRegions = async () => {
  const result = await requests.get('/regions/');
  const data = await result.json();
  return data;
};

const fetchRegion = async (id) => {
  const result = await requests.get(`/region/${id}/`);
  const data = await result.json();
  return data;
};

const postRegion = async (values) => {
  await requests.post('/regions/', values);
};

const patchRegion = async (values, id) => {
  await requests.patch(`/region/${id}/`, values);
};

const deleteRegion = async (id) => {
  const result = await requests.del(`/region/${id}/`);
  return result.json();
};

export default {
  fetchRegions,
  fetchRegion,
  postRegion,
  patchRegion,
  deleteRegion,
};
