import requests from 'utils/requests';
import { fixTimestamp, userType, buildCategoryDict } from 'utils';

const fetchAll = async () => {
  const data = await (await requests.get('/users/all/')).json();
  const categoryDict = await buildCategoryDict();
  return data.map((obj) => {
    const {
      rut,
      email,
      _type: type,
      user_id: id,
      last_name: lastName,
      first_name: firstName,
      last_login: lastLogin,
      category_id: categoryId,
    } = obj;
    const stringedType =
      userType(type) +
      ([1, 2].includes(type) ? '' : ` ${categoryDict[categoryId]}`);
    const niceTime = fixTimestamp(lastLogin);
    return { id, rut, lastName, firstName, email, stringedType, niceTime };
  });
};

const fetchOne = async ({ id }) => {
  const newValues = await requests.get(`/users/${id}/getuser/`);
  return newValues.json();
};

const create = async (values) => {
  await requests.post('/users/signup/', values);
};

const update = async (values) => {
  await requests.patch(`/users/${values.user_id}/edit/`, values);
};

const destroy = async (entityId) => {
  await requests.del(`/users/${entityId}/destroyer/`);
};

export default { fetchAll, fetchOne, create, update, destroy };
