import requests from 'utils/requests';

const fetchCommunes = async () => {
  const result = await requests.get('/communes/');
  const jsonData = await result.json();
  let provinces = await requests.get('/provinces_indexed/');
  provinces = await provinces.json();
  let regions = await requests.get('/regions_indexed/');
  regions = await regions.json();
  const data = jsonData.map((communeObj) => {
    const provinceObj = provinces[communeObj.province_id];
    const regionObj = regions[provinceObj.region_id];
    const regionName = `${regionObj.name} (${regionObj.region_ordinal})`;
    return { ...communeObj, province: provinceObj.name, region: regionName };
  });
  return data;
};

const fetchCommune = async (id) => {
  const result = await requests.get(`/commune/${id}/`);
  return result.json();
};

const postCommune = async (values) => {
  return requests.post('/communes/', values);
};

const patchCommune = async (values, id) => {
  return requests.patch(`/commune/${id}/`, values);
};

const deleteCommune = async (id) => {
  return requests.del(`/commune/${id}/`);
};

export default {
  fetchCommunes,
  fetchCommune,
  postCommune,
  patchCommune,
  deleteCommune,
};
