import React from 'react';
import { Box, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ResponsiveTableGrid } from 'components';
import useRouterUtils from 'utils/router/routerUtilsHook';

import useService from 'utils/services';

export default function TecIndex() {
  const { tech } = useService();
  const { redirect } = useRouterUtils();

  const headers = [
    {
      field: 'rut',
      headerName: 'RUT',
    },
    {
      field: 'firstName',
      headerName: 'Nombres',
    },
    {
      field: 'lastName',
      headerName: 'Apellido',
    },
    {
      field: 'enterpriseName',
      headerName: 'Empresa',
    },
  ];

  return (
    <>
      <div className="TecIndex">
        <h2 className="title"> Técnicos </h2>
        <Box align="center">
          <Button
            component={Link}
            to="/tecnicos/nuevo"
            className="new-entity-btn"
            variant="contained"
          >
            Nuevo técnico
          </Button>
        </Box>

        <ResponsiveTableGrid
          fetchAllFunc={tech.fetchTechnicians}
          headers={headers}
          tableName="techs"
          canEdit
          canDelete
          deleteEntityName={(row) =>
            ` el técnico ${row.firstName} ${row.lastName}`
          }
          onEdit={(row) => redirect(`/tecnicos/editar/${row.id}`)}
          onDelete={(row) => tech.deleteTechnician(row.id)}
        />
      </div>
    </>
  );
}
