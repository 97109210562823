import requests from 'utils/requests';

const fetchNonOkOptions = async () => {
  const result = await requests.get('/non_ok_options/');
  return result.json();
};

const postNonOkOptions = async (options) => {
  await requests.post('/non_ok_options/', options);
};

export default {
  fetchNonOkOptions,
  postNonOkOptions,
};
