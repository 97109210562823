import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { TextField, Grid, Button, Box, Paper } from '@material-ui/core';
import useService from 'utils/services';
import { DeletePopUp, LoadView } from 'components';

import './TecShow.scss';

const TecShow = () => {
  const [info, setInfo] = useState({});
  const [isLoading, setLoading] = useState(true);
  const { id } = useParams();
  const { tech } = useService();

  const fetchData = async () => {
    const data = await tech.fetchTechnician(id);
    delete data.team_id;
    delete data.id;
    setInfo(data);
    setLoading(false);
  };
  useEffect(fetchData, []);

  return (
    <Box className="TecShow" align="center">
      <LoadView isLoading={isLoading}>
        <h2 className="headline">Perfil Técnico</h2>
        <div className="actionButtons">
          <div className="deleteTech">
            <DeletePopUp
              className="buttonDelete"
              buttonText="Eliminar Técnico"
              entity="este técnico"
              variant="contained"
              deleteFunction={tech.deleteTechnician}
              id={parseInt(id, 10)}
              redirectPath="/tecnicos"
            />
          </div>
          <Button
            className="editTech"
            component={Link}
            variant="contained"
            to={`/tecnicos/editar/${id}`}
          >
            Editar Técnico
          </Button>
        </div>
        <Grid container justifyContent="center">
          <Paper className="has-shadow">
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nombres"
                margin="normal"
                name="first_name"
                id="first_name"
                className="name-field"
                variant="outlined"
                value={info.first_name}
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />

              <TextField
                fullWidth
                label="Apellidos"
                margin="normal"
                name="last_name"
                id="last_name"
                className="last-names-field"
                value={info.last_name}
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />
              <TextField
                fullWidth
                label="Rut"
                margin="normal"
                id="rut"
                name="rut"
                className="rut-field"
                value={info.rut}
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />
              <TextField
                fullWidth
                label="Empresa"
                margin="normal"
                id="enterprise"
                className="enterprise-field"
                variant="outlined"
                value={info.enterprise_name}
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />

              <TextField
                fullWidth
                label="Categoría"
                margin="normal"
                id="category"
                className="category-field"
                variant="outlined"
                value={info.category_name}
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />

              <TextField
                fullWidth
                label="Teléfono"
                margin="normal"
                name="phone"
                id="phone"
                className="telephone-field"
                variant="outlined"
                value={info.phone}
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />

              <TextField
                fullWidth
                label="Email"
                margin="normal"
                name="email"
                id="email"
                className="email-field"
                variant="outlined"
                value={info.email ? info.email : 'Sin Correo'}
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />
            </Grid>
          </Paper>
        </Grid>
      </LoadView>
    </Box>
  );
};

export default TecShow;
