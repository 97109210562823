import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TextField, Box, Grid } from '@material-ui/core/';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useRouterUtils from 'utils/router/routerUtilsHook';
import useTableState from 'utils/table-state';
import { Form, fieldProps } from 'components/Form';
import LoadingButton from 'components/LoadingButton';

import { BackButton } from 'components';

import useService from 'utils/services';

const validationSchema = yup.object({
  name: yup.string().required('Este campo es obligatorio'),
});

const EnterprisesForm = ({ isEdit, title }) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { id } = useParams();
  const { enterprise } = useService();
  const { redirect } = useRouterUtils();
  const { setShouldReload } = useTableState('enterprises');

  const onSubmit = async (values) => {
    setLoadingSubmit(true);
    if (isEdit) await enterprise.patchEnterprise(values, id);
    else await enterprise.postEnterprise(values);
    setLoadingSubmit(false);
    setShouldReload(true);
    redirect('/empresas');
  };

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  const fetchData = async () => {
    if (isEdit && id) {
      const data = await enterprise.fetchEnterprise(id);
      await formik.setFieldValue('name', data.name);
    }
  };

  const GoBack = () => <BackButton to="/empresas" />;

  useEffect(fetchData, []);

  return (
    <div>
      <div className="enterpriseForm">
        <Grid container justifyContent="center">
          <Grid item xs={4}>
            <Form
              onSubmit={formik.handleSubmit}
              title={title}
              HeaderComponent={GoBack}
            >
              <TextField
                fullWidth
                label="Nombre"
                margin="normal"
                id="name"
                name="name"
                className="name"
                {...fieldProps(formik, 'name')}
              />

              <Box align="center">
                <LoadingButton loading={loadingSubmit} isEdit={isEdit} />
              </Box>
            </Form>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

EnterprisesForm.propTypes = {
  isEdit: PropTypes.bool,
  title: PropTypes.string,
};

EnterprisesForm.defaultProps = {
  isEdit: false,
  title: 'Nueva Empresa',
};

export default EnterprisesForm;
