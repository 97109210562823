import useNotificationState from './notificationStateHook';

const useNotification = () => {
  const { setSeverity, setMessage, setTimeout, setOpen } =
    useNotificationState();

  const genMessageSetter = (severity) => {
    const newFunc = (msg) => {
      setSeverity(severity);
      setMessage(msg);
      setOpen(true);
    };
    return newFunc;
  };

  return {
    setInfoMessage: genMessageSetter('info'),
    setWarningMessage: genMessageSetter('warning'),
    setSuccessMessage: genMessageSetter('success'),
    setErrorMessage: genMessageSetter('error'),
    setNotificationTimeout: setTimeout,
  };
};
export default useNotification;
