import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import {
  createTheme,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core/styles';
import { esES } from '@material-ui/data-grid';
import { esES as coreEsES } from '@material-ui/core/locale';

import Notification from 'components/notifications';

import OsamRouter from './router';
import { SessionContext } from './utils/session/sessionContext';

import 'fontsource-roboto';
import './App.scss';
import './styles/commonStyle.scss';

const App = () => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [loadingSession, setLoadingSession] = useState(true);
  const [sessionData, setSessionData] = useState({});
  const [tableState, setTableState] = useState({});
  const [notificationState, setNotificationState] = useState({});

  const theme = createTheme(esES, coreEsES);

  const logout = () => {
    localStorage.removeItem('current-user');
    localStorage.removeItem('session-data');
    setLoggedIn(false);
    setCurrentUser(null);
    setTableState({});
  };

  const RestoreSession = () => {
    const userStr = localStorage.getItem('current-user');
    const sessionStr = localStorage.getItem('session-data');

    if (!userStr || !sessionStr) {
      setLoadingSession(false);
      return;
    }
    const user = JSON.parse(userStr);
    const session = JSON.parse(sessionStr);
    setCurrentUser(user);
    setSessionData(session);
    setLoggedIn(true);
    setLoadingSession(false);
    setTableState({});
  };

  useEffect(RestoreSession, []);

  if (loadingSession) {
    return (
      <Grid container alignItems="center" justifyContent="center">
        <Grid item>
          <CircularProgress size="3rem" />
        </Grid>
      </Grid>
    );
  }

  return (
    <SessionContext.Provider
      value={{
        isLoggedIn,
        setLoggedIn,
        currentUser,
        setCurrentUser,
        sessionData,
        setSessionData,
        logout,
        tableState,
        setTableState,
        notificationState,
        setNotificationState,
      }}
    >
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <OsamRouter />
          <Notification />
        </StylesProvider>
      </ThemeProvider>
    </SessionContext.Provider>
  );
};

export default App;
