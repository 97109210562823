import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import LoginRedirect from './LoginRedirect';

const BaseRoute = ({ children, userCheckCallback, redirectPath, ...rest }) => {
  return (
    <Route {...rest}>
      {(() => {
        if (userCheckCallback()) {
          return children;
        }

        if (redirectPath) {
          return <Redirect to={redirectPath} />;
        }

        return <LoginRedirect />;
      })()}
    </Route>
  );
};

BaseRoute.propTypes = {
  userCheckCallback: PropTypes.func.isRequired,
  redirectPath: PropTypes.string,
  children: PropTypes.node.isRequired,
};

BaseRoute.defaultProps = {
  redirectPath: null,
};

export default BaseRoute;
