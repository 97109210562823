/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
import { React, useState, useEffect } from 'react';
import {
  Box,
  Grid,
  TextField,
  Button,
  FormLabel,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import { Form, LoadView, fieldProps, BackButton } from 'components';
import LoadingButton from 'components/LoadingButton';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import useNotification from 'utils/notifications/notificationHook';
import useService from 'utils/services';
import { rmKeys } from 'utils';
import './CategoryForm.scss';
import useRouterUtils from 'utils/router/routerUtilsHook';
import useTableState from 'utils/table-state';

const validationSchema = yup.object({
  name: yup.string().required('Campo obligatorio'),
  technicians: yup.string().required('Elija un número de técnicos'),
  goal_rm: yup
    .number()
    .required('Ingresa una meta para la RM')
    .min(1, 'Número debe ser mayor a 0'),
  goal_other: yup
    .number()
    .required('Ingresa una meta para las otras regiones')
    .min(1, 'Número debe ser mayor a 0'),
});

const CategoryForm = ({ isEdit, title }) => {
  const { setSuccessMessage, setErrorMessage } = useNotification();
  const [isLoading, setLoading] = useState(isEdit);
  const [rootName, setRootName] = useState('');
  const [error, setError] = useState(false);
  const [noName, setNoName] = useState(false);
  const [repeated, setRepeated] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [childName, setChildName] = useState('');
  const [selectedNode, setSelectedNode] = useState('');
  const [selectedNameRoot, setSelectedNameRoot] = useState(false);
  const [selectedNameChild, setSelectedNameChild] = useState(false);
  const [currentLevel, setCurrentLevel] = useState('0');
  const [counterL1, setCounterL1] = useState(0);
  const [currentChildLevel2, setCurrentChildLevel2] = useState(0);
  const [currentChildLevel3, setCurrentChildLevel3] = useState(0);
  const [helpText, setHelpText] = useState('');
  const [changing, setChanging] = useState(true);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState({
    id: '0,0',
    name: rootName,
    children: [],
    levels: 0,
  });
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { id } = useParams();
  const { category } = useService();
  const { redirect } = useRouterUtils();
  const { setShouldReload } = useTableState('categories');

  const onSubmit = async (values) => {
    const clean = rmKeys(values, ['active_report']);
    if (data.levels > 0) {
      setLoadingSubmit(true);
      const response = await (isEdit
        ? category.editCategory
        : category.newCategory)(clean, data);
      if (response) {
        const message = isEdit ? 'Categoría Modificada' : 'Categoría Creada';
        setSuccessMessage(message);
      } else {
        const message = isEdit
          ? 'Hubo un error al modificar la categoría'
          : 'Hubo un error al crear la categoría';
        setErrorMessage(message);
      }
      setLoadingSubmit(false);
      setShouldReload(true);
      redirect('/categorias');
    } else {
      setErrorMessage('Debe agregar mínimo una sub-categoría');
    }
  };
  const formik = useFormik({
    initialValues: {
      id: 0,
      name: '',
      technicians: '1',
      goal_rm: 1,
      goal_other: 1,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });
  const fetchData = async () => {
    if (id) {
      const [info, tree] = await category.fetchSingleCategory(id);
      formik.setValues(info);
      setData(tree);
    }
    setLoading(false);
  };
  const handleNameChange = () => {
    if (formik.values.name) {
      setRootName(formik.values.name);
      setData({ ...data, name: formik.values.name });
      setSelectedNameRoot(true);
    }
  };
  const handlePosition = (nodeId, name) => {
    setCurrentChildLevel2(0);
    setCurrentChildLevel3(0);
    setSelectedNode(nodeId);
    setChildName(name);
    setCurrentLevel(nodeId.split(',')[0]);
    setError(false);
    setNoName(false);
    if (nodeId.split(',')[0] === '0') {
      setNoName(true);
    }
    if (nodeId.split(',')[0] === '1') {
      setCurrentChildLevel2(parseInt(nodeId.split(',')[1], 10));
    }
    if (nodeId.split(',')[0] === '2') {
      setCurrentChildLevel2(parseInt(nodeId.split(',')[1], 10));
      setCurrentChildLevel3(parseInt(nodeId.split(',')[2], 10));
      setError(true);
    }
  };
  const validateName = (nodes, name) => {
    if (nodes.name === name) {
      return false;
    }
    if (Array.isArray(nodes.children)) {
      const list = nodes.children.map((node) => validateName(node, name));
      if (list.includes(false)) {
        return false;
      }
      return true;
    }
    return true;
  };
  const submitNewChild = () => {
    setHelpText('');
    setRepeated(false);
    setEmpty(false);
    if (childName) {
      if (validateName(data, childName)) {
        setChanging(false);
        const node = {
          id: '',
          name: childName,
          children: [],
        };
        if (currentLevel === '0') {
          node.id = `1,${counterL1}`;
          const newData = data;
          newData.children.push(node);
          setCounterL1(counterL1 + 1);
          if (data.levels === 0) {
            newData.levels = 1;
          }
          setData(newData);
          setChanging(true);
        }
        if (currentLevel === '1') {
          const index = data.children[currentChildLevel2].children
            ? data.children[currentChildLevel2].children.length
            : 0;
          node.id = `2,${currentChildLevel2},${index}`;
          const newData = data;
          newData.children[currentChildLevel2].children.push(node);
          if (data.levels === 1) {
            newData.levels = 2;
          }
          setData(newData);
          setChanging(true);
        }
        setSelectedNameChild(false);
        setChildName('');
        setSelectedNode('');
      } else {
        setRepeated(true);
        setHelpText('Este nombre está repetido');
      }
    } else {
      setEmpty(true);
      setHelpText('Este campo no puede estar vacío');
    }
  };
  const renderTree = (nodes) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={nodes.name}
      align="left"
      onClick={() => {
        handlePosition(nodes.id, nodes.name);
      }}
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );
  const handleEdit = () => {
    setEdit(true);
    setSelectedNameChild(true);
  };
  const handleDelete = () => {
    if (currentLevel === '1') {
      const changed = data.children.filter(
        (child) => parseInt(child.id.split(',')[1], 10) !== currentChildLevel2
      );
      setData({
        ...data,
        children: changed,
      });
    }
    if (currentLevel === '2') {
      const changed = data.children.map((child) => {
        if (parseInt(child.id.split(',')[1], 10) === currentChildLevel2) {
          const newChild = child;
          const newGrandChildren = child.children.filter(
            (gchild) =>
              parseInt(gchild.id.split(',')[2], 10) !== currentChildLevel3
          );
          newChild.children = newGrandChildren;
          return newChild;
        }
        return child;
      });
      setData({
        ...data,
        children: changed,
      });
    }
  };
  const editName = () => {
    setHelpText('');
    setRepeated(false);
    setEmpty(false);
    if (childName) {
      if (validateName(data, childName)) {
        if (currentLevel === '1') {
          const changed = data.children.map((child) =>
            parseInt(child.id.split(',')[1], 10) === currentChildLevel2
              ? { ...child, name: childName }
              : child
          );
          setData({
            ...data,
            children: changed,
          });
        }
        if (currentLevel === '2') {
          const changed = data.children.map((child) => {
            if (parseInt(child.id.split(',')[1], 10) === currentChildLevel2) {
              const newChild = child;
              const newGrandChildren = child.children.map((gchild) => {
                if (
                  parseInt(gchild.id.split(',')[2], 10) === currentChildLevel3
                ) {
                  gchild.name = childName;
                }
                return gchild;
              });
              newChild.children = newGrandChildren;
              return newChild;
            }
            return child;
          });
          setData({
            ...data,
            children: changed,
          });
        }
        setEdit(false);
        setSelectedNameChild(false);
      } else {
        setRepeated(true);
        setHelpText('Este nombre está repetido');
      }
    } else {
      setEmpty(true);
      setHelpText('Este campo no puede estar vacío');
    }
  };
  const closeState = () => {
    if (edit) {
      setEdit(false);
    }
    setSelectedNameChild(false);
  };

  const GoBack = () => <BackButton to="/categorias" />;

  useEffect(fetchData, []);
  useEffect(handleNameChange, [formik.values.name]);

  return (
    <div className="CategoryForm" align="center">
      <Form
        onSubmit={formik.handleSubmit}
        title={title}
        HeaderComponent={GoBack}
      >
        <LoadView isLoading={isLoading}>
          <Grid item m={12}>
            <TextField
              fullWidth
              label="Nombre Categoría"
              margin="normal"
              name="name"
              id="name"
              className="name-field"
              {...fieldProps(formik, 'name')}
            />
            <FormControl
              className="radioButtons"
              component="fieldset"
              margin="normal"
            >
              <FormLabel component="legend">Número de Técnicos</FormLabel>
              <RadioGroup
                row
                component="fieldset"
                id="technicians"
                name="technicians"
                value={`${formik.values.technicians}`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio color="primary" />}
                  label="1"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio color="primary" />}
                  label="2"
                />
              </RadioGroup>
            </FormControl>
            <TextField
              fullWidth
              label="Meta Auditorías RM"
              margin="normal"
              name="goal-rm"
              id="goal-rm"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              className="name-field"
              {...fieldProps(formik, 'goal_rm')}
            />
            <TextField
              fullWidth
              label="Meta Auditorías Otras Regiones"
              margin="normal"
              name="goal-other"
              id="goal-other"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              className="name-field"
              {...fieldProps(formik, 'goal_other')}
            />
            {selectedNameRoot && (
              <>
                <br />
                <br />
                <h4>Estructura Nueva Categoría</h4>
                <TreeView
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  defaultSelected={['0,0']}
                >
                  {changing && renderTree(data)}
                </TreeView>
                {!selectedNameChild && selectedNode && (
                  <div className="actionButtons">
                    {!error && (
                      <Button
                        variant="contained"
                        className="addButton"
                        onClick={() => setSelectedNameChild(true)}
                      >
                        Agregar Sub-categoría
                      </Button>
                    )}
                    {!noName && (
                      <>
                        <Button
                          variant="contained"
                          className="editButton"
                          onClick={handleEdit}
                        >
                          Editar
                        </Button>
                        <Button
                          variant="contained"
                          className="deleteButton"
                          onClick={handleDelete}
                        >
                          Eliminar
                        </Button>
                      </>
                    )}
                  </div>
                )}
              </>
            )}
            {selectedNameChild && (
              <>
                <div className="textField">
                  <TextField
                    fullWidth
                    label="Nombre Sub-categoría"
                    margin="normal"
                    className="name-field"
                    onChange={(e) => {
                      setChildName(e.target.value);
                    }}
                    defaultValue={edit ? childName : ''}
                    error={empty || repeated}
                    helperText={helpText}
                  />
                  <IconButton
                    aria-label="close"
                    onClick={closeState}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                {edit ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className="submitButton"
                    onClick={editName}
                  >
                    Modificar
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    className="submitButton"
                    onClick={submitNewChild}
                  >
                    Agregar
                  </Button>
                )}
              </>
            )}
            {!edit && (
              <Box align="center">
                <LoadingButton loading={loadingSubmit} isEdit={isEdit} />
              </Box>
            )}
          </Grid>
        </LoadView>
      </Form>
    </div>
  );
};

CategoryForm.propTypes = {
  isEdit: PropTypes.bool,
  title: PropTypes.string,
};

CategoryForm.defaultProps = {
  isEdit: false,
  title: 'Nueva Categoría',
};
export default CategoryForm;
