import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  IconButton,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import './PopUp.scss';

const PopUp = ({ title, children, buttons, open, onClose, onOpen }) => {
  useEffect(() => onOpen && onOpen(), [open]);

  return (
    <Dialog className="PopUp" onClose={onClose} open={open}>
      {title && (
        <DialogTitle
          className="dialog-title"
          onClose={onClose}
          disableTypography
        >
          <Typography variant="h6">{title}</Typography>

          {onClose && (
            <IconButton
              aria-label="close"
              className="close-button"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
      )}
      <DialogContent dividers>{children}</DialogContent>
      {buttons && <DialogActions>{buttons}</DialogActions>}
    </Dialog>
  );
};

PopUp.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  buttons: PropTypes.node,
  children: PropTypes.node.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

PopUp.defaultProps = {
  title: '',
  buttons: null,
  onOpen: null,
  onClose: null,
};

export default PopUp;
