import React from 'react';
import { Box, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import useService from 'utils/services';
import { ResponsiveTableGrid } from 'components';
import useRouterUtils from 'utils/router/routerUtilsHook';

export default function CommuneIndex() {
  const { commune } = useService();
  const { redirect } = useRouterUtils();

  const headers = [
    {
      field: 'name',
      headerName: 'Nombre',
      flex: 1,
    },
    {
      field: 'province',
      headerName: 'Provincia',
      flex: 1,
    },
    {
      field: 'region',
      headerName: 'Región',
      flex: 1,
    },
  ];

  return (
    <>
      <div className="CommuneIndex">
        <h2 className="title"> Comunas </h2>
        <Box align="center">
          <Button
            component={Link}
            to="/comunas/nueva"
            className="new-entity-btn"
            variant="contained"
          >
            Nueva comuna
          </Button>
        </Box>

        <ResponsiveTableGrid
          fetchAllFunc={commune.fetchCommunes}
          headers={headers}
          tableName="communes"
          canEdit
          canDelete
          deleteNotification={() => 'Comuna Eliminada'}
          deleteEntityName={(row) => ` la comuna ${row.name}`}
          onEdit={(row) => redirect(`/comunas/editar/${row.id}`)}
          onDelete={(row) => commune.deleteCommune(row.id)}
        />
      </div>
    </>
  );
}
