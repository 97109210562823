import React from 'react';
import PropTypes from 'prop-types';
import { useSessionContext } from 'utils/session/sessionContext';
import BaseRoute from './BaseRoute';

const ClientRoute = ({ children, ...rest }) => {
  const { isLoggedIn, currentUser } = useSessionContext();
  return (
    <BaseRoute
      userCheckCallback={() =>
        isLoggedIn && (currentUser.isClient || currentUser.isAdmin)
      }
      {...rest}
    >
      {children}
    </BaseRoute>
  );
};

ClientRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ClientRoute;
