import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { useSessionContext } from 'utils/session/sessionContext';
import AnswersAccordeon from 'components/AnswersAccordeon';
import './AuditAnswers.scss';

const AuditAnswers = ({ dataSections, able, save, patchMethod }) => {
  const { currentUser } = useSessionContext();
  const [show, setShow] = useState(false);
  const [edited, setEdited] = useState(false);
  const [visible, setVisible] = useState(false);
  const [blocks, setBlocks] = useState([]);

  const isVisible = () => {
    const hasQuestions = dataSections.filter(
      (section) =>
        section.questions.filter((question) => question.category < 5).length > 0
    );
    if (hasQuestions.length > 0) {
      setVisible(true);
      setBlocks(hasQuestions);
    }
  };
  const editAnswers = async () => {
    await patchMethod();
    setEdited(false);
  };

  useEffect(isVisible, []);
  return (
    <div>
      {visible && (
        <div className="AuditAnswers">
          <Button
            className="buttonStyle"
            variant="contained"
            color="primary"
            onClick={() => setShow(!show)}
          >
            {' '}
            Ver Respuestas
          </Button>
          {show && (
            <>
              <AnswersAccordeon
                dataSections={blocks}
                able={able}
                save={save}
                patchMethod={patchMethod}
                edit={setEdited}
              />
              <br />
              <br />
              {currentUser.isAdmin && edited && (
                <Button
                  className="buttonStyle"
                  variant="contained"
                  color="secondary"
                  onClick={editAnswers}
                >
                  {' '}
                  Guardar Respuestas
                </Button>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

AuditAnswers.propTypes = {
  dataSections: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array]))
  ).isRequired,
  able: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
  patchMethod: PropTypes.func.isRequired,
};

export default AuditAnswers;
