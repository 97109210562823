/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  Box,
  IconButton,
  Grid,
  Typography,
} from '@material-ui/core';
import { FieldArray, Form, Formik, getIn } from 'formik';
import DeleteIcon from '@material-ui/icons/Delete';
import * as Yup from 'yup';

import './OptionList.scss';

// Custom validator for checking that options are not repeated
Yup.addMethod(
  Yup.array,
  'unique',
  function uniqueLayer(message, mapper = (a) => a) {
    return this.test('unique', message, (list) => {
      return list.length === new Set(list.map(mapper)).size;
    });
  }
);

// Proptypes have been ignored since this is an internal component
const InnerForm = ({
  initialOptions,
  values,
  setValues,
  touched,
  errors,
  handleChange,
  handleBlur,
  isValid,
  onValueChange,
  onOrderChange,
  onFocusChange,
  onValidChange,
  onSubmit,
  SubmitButton,
  onDelete,
  canDelete,
  canAdd,
  canReorder,
  readOnly,
  editEnabled,
}) => {
  const [dragIndex, setDragIndex] = useState();

  useEffect(() => {
    if (!initialOptions) return;
    const formatted = initialOptions.map((opt) => {
      return { optionValue: opt };
    });
    setValues({ options: formatted });
  }, [initialOptions]);

  useEffect(() => {
    if (!onValueChange) return;
    onValueChange(values);
  }, [values]);

  useEffect(() => {
    if (!onValidChange) return;
    onValidChange(isValid);
  }, [isValid]);

  const onOptionAdd = (push) => {
    push({ optionValue: '' });
  };

  const onOptionRemove = (index, remove) => {
    remove(index);
  };

  const blurHandler = (...args) => {
    if (onFocusChange) onFocusChange(...args);
    // We use formik default handler
    handleBlur(...args);
  };

  const deleteHandler = (id, index, remove) => {
    if (onDelete) onDelete(id);
    onOptionRemove(index, remove);
  };

  const handleDragStart = (e, index) => {
    setDragIndex(index);
  };

  const handleDragLeave = (e) => {
    e.target.style.backgroundColor = 'white';
  };

  const handleDrop = (e, index, move) => {
    e.target.style.backgroundColor = 'white';
    if (onOrderChange) onOrderChange(e, index, move);
    move(dragIndex, index);
  };

  return (
    <Form noValidate autoComplete="off">
      <FieldArray name="options">
        {({ push, remove, move }) => (
          <div>
            {values.options.map((p, index) => {
              const optionValue = `options[${index}].optionValue`;
              const touchedOption = getIn(touched, optionValue);
              const errorOption = getIn(errors, optionValue);

              return (
                <div
                  key={typeof p === 'string' ? p : p.optionValue + index}
                  draggable={editEnabled && canReorder}
                  onDragStart={(e) => handleDragStart(e, index)}
                  onDragLeave={(e) => handleDragLeave(e)}
                  onDrop={(e) => handleDrop(e, index, move)}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <Box display="flex">
                    <Box flexGrow={1}>
                      <TextField
                        margin="normal"
                        variant="outlined"
                        label="Opción"
                        name={optionValue}
                        value={p.optionValue}
                        fullWidth
                        helperText={
                          touchedOption && errorOption ? errorOption : ''
                        }
                        error={Boolean(touchedOption && errorOption)}
                        onChange={handleChange}
                        onBlur={blurHandler}
                        InputProps={{ readOnly: !editEnabled || readOnly }}
                      />
                    </Box>
                    {editEnabled && canDelete && (
                      <Box ml={1} pb={1}>
                        <Grid
                          className="delete-grid"
                          container
                          justifyContent="center"
                          alignItems="center"
                        >
                          <IconButton
                            aria-label="delete"
                            size="medium"
                            onClick={() => deleteHandler(p.id, index, remove)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Box>
                    )}
                  </Box>
                </div>
              );
            })}

            <Typography className="options-error" color="error" align="center">
              {typeof errors?.options === 'string' && errors.options}
            </Typography>

            {editEnabled && canAdd && (
              <Box align="center" mb={2}>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => onOptionAdd(push)}
                >
                  +
                </Button>
              </Box>
            )}
          </div>
        )}
      </FieldArray>

      {SubmitButton && <SubmitButton onClick={() => onSubmit(values)} />}
    </Form>
  );
};

const OptionList = (props) => {
  const {
    initialOptions,
    onValueChange,
    onOrderChange,
    onFocusChange,
    onSubmit,
    onValidChange,
    SubmitButton,
    onDelete,
    canAdd,
    canDelete,
    canReorder,
    optionMinLength,
    optionMaxLength,
    readOnly,
    editEnabled,
  } = props;

  // Validation depends on props so we use them inside here
  const validationSchema = Yup.object({
    options: Yup.array()
      .of(
        Yup.object({
          optionValue: Yup.string()
            .min(optionMinLength, 'La opción es muy corta')
            .max(optionMaxLength, 'La opción es muy larga')
            .required('Debes ingresar una opción'),
        })
      )
      .unique('No pueden haber opciones repetidas', (el) => el.optionValue)
      .min(1, 'Debes elegir al menos una opcion'),
  });

  return (
    <div className="OptionList">
      <Formik
        initialValues={{
          options: [
            {
              optionValue: '',
            },
          ],
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formikProps) => (
          <InnerForm
            {...formikProps}
            initialOptions={initialOptions}
            onValueChange={onValueChange}
            onOrderChange={onOrderChange}
            onFocusChange={onFocusChange}
            onSubmit={onSubmit}
            onValidChange={onValidChange}
            SubmitButton={SubmitButton}
            onDelete={onDelete}
            canAdd={canAdd}
            canDelete={canDelete}
            canReorder={canReorder}
            readOnly={readOnly}
            editEnabled={editEnabled}
          />
        )}
      </Formik>
    </div>
  );
};

OptionList.propTypes = {
  initialOptions: PropTypes.arrayOf(PropTypes.string),
  onValueChange: PropTypes.func,
  onOrderChange: PropTypes.func,
  onFocusChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onValidChange: PropTypes.func,
  SubmitButton: PropTypes.elementType,
  onDelete: PropTypes.func,
  canAdd: PropTypes.bool,
  canDelete: PropTypes.bool,
  canReorder: PropTypes.bool,
  optionMinLength: PropTypes.number,
  optionMaxLength: PropTypes.number,
  readOnly: PropTypes.bool,
  editEnabled: PropTypes.bool,
};

OptionList.defaultProps = {
  initialOptions: null,
  onValueChange: null,
  onOrderChange: null,
  onFocusChange: null,
  onSubmit: null,
  onValidChange: null,
  SubmitButton: null,
  onDelete: null,
  canAdd: true,
  canDelete: true,
  canReorder: false,
  optionMaxLength: Number.POSITIVE_INFINITY,
  optionMinLength: 2,
  readOnly: false,
  editEnabled: true,
};

export default OptionList;
