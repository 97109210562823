import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import OsamLogo from 'components/OsamLogo';
import { Form, fieldProps } from 'components/Form';
import { useSessionContext } from 'utils/session/sessionContext';
import useService from 'utils/services';

import './Login.scss';

// As a team convention, we set the schema validation up here
const validationSchema = yup.object({
  username: yup.string('Ingresa tu usuario').required('Ingresa un usuario'),
  password: yup
    .string('Ingresa tu contraseña')
    .required('Ingresa una contraseña'),
});

const Login = () => {
  const { setLoggedIn, setCurrentUser, setSessionData } = useSessionContext();

  const { session } = useService();

  const [loginError, setLoginError] = useState(false);
  const history = useHistory();

  const saveSession = (user, newSession) => {
    setCurrentUser(user);
    setSessionData(newSession);
    localStorage.setItem('current-user', JSON.stringify(user));
    localStorage.setItem('session-data', JSON.stringify(newSession));
  };

  const redirectLogin = () => {
    const redirect = localStorage.getItem('login-redirect');
    localStorage.removeItem('login-redirect');
    history.push({ pathname: redirect || '/' });
  };

  const onLoginSuccess = (resp) => {
    const loggedUser = {
      username: resp.username,
      email: resp.email,
      name: resp.first_name,
      lastname: resp.last_name,
      rut: resp.rut,
      type: resp._type,
      isAdmin: resp._type === 1,
      isAuditor: resp._type === 2,
      isClient: resp._type === 3,
      clientCategory: resp.category_id,
    };

    const newSession = { token: resp.access_token };
    saveSession(loggedUser, newSession);
    redirectLogin();
    setLoggedIn(true);
  };

  const onSubmit = async (values, { setStatus }) => {
    const resp = await session.createSession(values.username, values.password);
    if (!resp) {
      setLoginError(true);
      // Custom formik status that sets all fields in error mode. See formUtils.js
      setStatus('fields-error');
      return;
    }
    onLoginSuccess(resp);
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema,
    onSubmit,
  });

  // Delete login error when any field is changed
  useEffect(() => {
    setLoginError(false);
    // Remove error status from fields
    formik.setStatus(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  return (
    <Grid container className="Login" justifyContent="center">
      <Grid item xs={12} lg={4} className="central-row">
        <Grid container justifyContent="center">
          <Box mt={5}>
            <OsamLogo />
          </Box>
        </Grid>
        <div className="paper">
          <Typography align="center" component="h1" variant="h5">
            Inicia sesión en el Panel de OSAM
          </Typography>
          <Form method="post" boxShadow={false} onSubmit={formik.handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="username"
              label="Usuario"
              name="username"
              autoComplete="username"
              autoFocus
              {...fieldProps(formik, 'username')}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              {...fieldProps(formik, 'password')}
            />

            <Typography color="error" align="center" className="error-text">
              {loginError && 'Usuario o Contraseña incorrecta'}
            </Typography>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="submit-btn"
              disabled={loginError || !formik.isValid}
            >
              {formik.isSubmitting ? (
                <CircularProgress size="1.5rem" />
              ) : (
                'Iniciar Sesión'
              )}
            </Button>
          </Form>
        </div>
      </Grid>
    </Grid>
  );
};

export default Login;
