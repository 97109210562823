import React from 'react';
import PropTypes from 'prop-types';
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const BaseNotification = ({ open, setOpen, timeout, message, severity }) => {
  const onClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={timeout} onClose={onClose}>
      <Alert onClose={onClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

BaseNotification.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  severity: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  timeout: PropTypes.number,
};

BaseNotification.defaultProps = {
  timeout: 6000,
};

export default BaseNotification;
