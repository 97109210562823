/* eslint-disable no-alert */
import React from 'react';
import useService from 'utils/services';
import './AuditIndex.scss';

import { ResponsiveTableGrid } from 'components';
import useRouterUtils from 'utils/router/routerUtilsHook';

const AuditIndex = () => {
  const { audit } = useService();
  const { redirect } = useRouterUtils();

  const headers = [
    {
      field: 'date',
      headerName: 'Fecha de Inicio',
      width: 200,
    },
    {
      field: 'category',
      headerName: 'Categoría',
      flex: 1,
    },
    {
      field: 'audit_type',
      headerName: 'Tipo de Auditoria',
      flex: 1,
    },
    {
      field: 'service_type',
      headerName: 'Tipo de Servicio',
      flex: 1,
    },
    {
      field: 'technician_name',
      headerName: 'Nombre Técnico(s)',
      flex: 1,
    },
    {
      field: 'technician_rut',
      headerName: 'RUT',
      flex: 1,
    },
    {
      field: 'technician_enterprise',
      headerName: 'Empresa',
      flex: 1,
    },
  ];

  return (
    <div>
      <h2 className="title"> Auditorías</h2>
      <ResponsiveTableGrid
        fetchAllFunc={audit.fetchLatestAudits}
        fetchPaginatedFunc={audit.fetchLatestAudits}
        headers={headers}
        tableName="audits"
        canView
        onView={(row) => redirect(`/auditorias/${row.id}`)}
      />
    </div>
  );
};
export default AuditIndex;
