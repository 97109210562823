import React from 'react';
import { Box } from '@material-ui/core';

import PropTypes from 'prop-types';

const GoogleMap = ({ origin, destiny }) => {
  const url =
    `https://www.google.com/maps/embed/v1/directions` +
    `?key=${process.env.REACT_APP_MAPS_API_KEY}` +
    `&origin=${origin.latitude},${origin.longitude}` +
    `&destination=${destiny.latitude},${destiny.longitude}` +
    `&mode=walking`;

  return (
    <Box align="center" m={4} className="GoogleMap">
      <iframe
        title="audit-map"
        width="100%"
        height="450"
        style={{ border: 0 }}
        loading="lazy"
        allowFullScreen
        src={url}
      />
    </Box>
  );
};

GoogleMap.propTypes = {
  origin: PropTypes.shape({
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
  }).isRequired,

  destiny: PropTypes.shape({
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
  }).isRequired,
};

export default GoogleMap;
