import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

const Entry = ({ name, value }) => (
  <li>
    <Grid container justifyContent="space-between">
      <Grid item>
        <strong>{name}:</strong>
      </Grid>
      <Grid item>{value}</Grid>
    </Grid>
  </li>
);

Entry.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
};

Entry.defaultProps = {
  name: '',
  value: '',
};

export default Entry;
