/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-alert */
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import useNotification from 'utils/notifications/notificationHook';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import useTableState from 'utils/table-state';
import { FormQuestions, DeletePopUp, BackButton } from 'components';
import useService from 'utils/services';
import './FormShow.scss';

const FormShowRow = ({ field, answer }) => {
  return (
    <Grid
      container
      item
      xs={12}
      spacing={3}
      justifyContent="center"
      className="FormShowRow"
    >
      <Grid item xs={3}>
        <Paper className="paper">{field}</Paper>
      </Grid>
      <Grid item xs={3}>
        <Paper className="paperAnswer">{answer}</Paper>
      </Grid>
    </Grid>
  );
};

FormShowRow.propTypes = {
  field: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};

const Form = () => {
  const { id } = useParams();
  const { setSuccessMessage, setErrorMessage } = useNotification();
  const [infoForm, setInfoForm] = useState({});
  const [infoBlocksForm, setInfoBlocksForm] = useState([]);
  const [hasQuestions, setQuestions] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(true);
  const { form } = useService();
  const { setShouldReload } = useTableState('forms');

  const onDeleteForm = async ({ entityId }) => {
    const response = await form.deleteForm(entityId);
    if (response) {
      setSuccessMessage('Formulario Eliminado');
    } else {
      setErrorMessage('Hubo un error al eliminar el formulario');
    }
    setShouldReload(true);
  };

  const getFormInfo = async () => {
    const data = await form.fetchFormInfo(id, false);
    try {
      setInfoForm(data[0]);
      setInfoBlocksForm(data[1]);
      if (data[1].length > 0) {
        setQuestions(true);
      }
      setLoading(false);
    } catch (e) {
      setError(false);
    }
    return null;
  };
  useEffect(getFormInfo, []);

  return (
    <Box className="FormShow" align="center">
      {isLoading && (
        <>
          <br /> <br /> <CircularProgress />{' '}
        </>
      )}
      {!isLoading && error && (
        <>
          <BackButton to="/formularios" />
          <h2 className="headline"> Detalles Formulario</h2>
          <div className="actionButtons">
            <div className="deleteForm">
              <DeletePopUp
                className="buttonDelete"
                buttonText="Eliminar Formulario"
                entity="este formulario"
                variant="contained"
                deleteFunction={onDeleteForm}
                id={parseInt(id, 10)}
                redirectPath="/formularios"
              />
            </div>
            <Button
              className="editForm"
              component={Link}
              variant="contained"
              to={`/formularios/editar/${id}`}
            >
              Editar Formulario
            </Button>
          </div>
          <br />
          <br />
          <Grid container spacing={2} justifyContent="center">
            <FormShowRow field="Nombre" answer={infoForm.name} />
            <FormShowRow field="Categoría" answer={infoForm.category} />
            <FormShowRow
              field="Tipo de Auditoría"
              answer={infoForm.audit_type}
            />
            <FormShowRow
              field="Tipo de Servicio"
              answer={infoForm.service_type}
            />
            <FormShowRow field="¿Activo?" answer={infoForm.active} />
          </Grid>
          {hasQuestions ? (
            <FormQuestions dataSections={infoBlocksForm} able={false} />
          ) : (
            <>
              <br />
              <br />
              <h2>No contiene preguntas</h2>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default Form;
