/* eslint-disable no-restricted-globals */
/* eslint-disable no-console */
/* eslint-disable no-alert */
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { ResponsiveTableGrid } from 'components';
import './BlockIndex.scss';
import useService from 'utils/services';

import useRouterUtils from 'utils/router/routerUtilsHook';
import BlockDelete from './BlockDelete';
import BlockViewDialog from './BlockViewDialog';

const BlockIndex = ({ form }) => {
  const { block, formtree } = useService();
  const { redirect } = useRouterUtils();

  const fetchBlocks = async () => {
    const blockPromise = block.fetchBlocks(form);
    const categoryPromise = formtree.fetchTree();
    // Get data in parallel
    const [blocks, formTree] = await Promise.all([
      blockPromise,
      categoryPromise,
    ]);
    const categoryDict = {};

    Object.entries(formTree).forEach(([id, attrs]) => {
      const { name } = attrs;
      categoryDict[id] = name;
    });

    const categorizedBlocks = blocks.map((currBlock) => {
      const ret = { ...currBlock };
      ret.categoryName = categoryDict[ret.category];
      ret.isForm = form;
      return ret;
    });

    return categorizedBlocks;
  };

  const headers = [
    {
      field: 'name',
      headerName: 'Nombre',
      flex: 1,
    },
    {
      field: 'categoryName',
      headerName: 'Categoría',
      flex: 1,
    },
  ];

  const linkPrefix = form ? '/bloques_formularios' : '/bloques_reportes';
  const tableName = form ? 'blocks-form' : 'blocks-report';

  return (
    <Box className="BlockIndex">
      <h2 className="title">
        {form ? 'Bloques Formularios' : 'Bloques Reportes'}
      </h2>
      <Button
        component={Link}
        to={`${linkPrefix}/nuevo`}
        className="new-entity-btn"
        variant="contained"
      >
        Nuevo Bloque
      </Button>

      <ResponsiveTableGrid
        fetchAllFunc={fetchBlocks}
        headers={headers}
        tableName={tableName}
        canEdit
        canDelete
        canView
        onEdit={(row) => redirect(`${linkPrefix}/editar/${row.id}`)}
        DeleteDialog={BlockDelete}
        ViewDialog={BlockViewDialog}
      />
    </Box>
  );
};

export default BlockIndex;

BlockIndex.propTypes = {
  form: PropTypes.bool.isRequired,
};
