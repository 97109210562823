/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import useService from 'utils/services';
import LoadView from 'components/LoadView/LoadView';
import DashboardPanel from './DashboardPanel';

import './Dashboard.scss';

const DashboardBody = ({ isLoading, categories, stats }) => {
  const formatStats = (data) => {
    const [rmDone, rmTarget] = data.RM;
    const [regionDone, regionTarget] = data.OTHER;
    const rmStats = { done: rmDone, target: rmTarget };
    const regionStats = { done: regionDone, target: regionTarget };
    return { rmStats, regionStats };
  };

  return (
    <Grid container spacing={2}>
      {Object.entries(stats).map(([categoryId, data]) => {
        const cat = categories[categoryId];
        const { rmStats, regionStats } = formatStats(data);

        return (
          <Grid key={categoryId} item className="dash-section" xs={12} md={6}>
            <Typography className="category-name">
              Estadísticas {cat.name}
            </Typography>
            <DashboardPanel
              isLoading={isLoading}
              stats={rmStats}
              section="Reg. Metropolitana"
            />
            <DashboardPanel
              isLoading={isLoading}
              stats={regionStats}
              section="Regiones"
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState({});
  const [categories, setCategories] = useState({});

  const { dashboard, formtree } = useService();

  const initialSetup = async () => {
    const statPromise = dashboard.fetchStats().then((data) => {
      setStats(data);
    });
    const categoriesPromise = formtree.fetchCategoriesActive().then((data) => {
      const cats = {};
      // Create a dict for fast access
      data.forEach((cat) => {
        cats[cat.id] = cat;
      });
      setCategories(cats);
    });
    await Promise.all([categoriesPromise, statPromise]);
    setIsLoading(false);
  };

  useEffect(initialSetup, []);

  return (
    <div className="Dashboard">
      <Grid container justifyContent="center" className="dash-container">
        <Grid item xs={12} md={10}>
          <LoadView isLoading={isLoading}>
            <DashboardBody
              isLoading={isLoading}
              categories={categories}
              stats={stats}
            />
          </LoadView>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
