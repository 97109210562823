/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable no-alert */

import requests from 'utils/requests';

function createData(id, name, category, for_form, questionList) {
  const q = questionList.map((value) => value);
  return {
    id,
    name,
    category,
    for_form,
    questions: q,
  };
}
const separateQuestions = (allQuestions, selectedIds) => {
  const selected = [];
  selectedIds.forEach((id) => {
    const found = allQuestions.find((element) => element.id === id);
    selected.push(found);
  });
  const notSelected = allQuestions.filter(
    (question) => !selectedIds.includes(question.id)
  );
  return { selectedQuestions: selected, notSelectedQuestions: notSelected };
};
const fetchBlocks = async (form) => {
  let rows;
  try {
    let responseBlocks;
    if (form) {
      responseBlocks = await requests.get('/block_category_for_form/null/True');
    } else {
      responseBlocks = await requests.get(
        '/block_category_for_form/null/False'
      );
    }

    const responseQuestions = await requests.get('/questions/');
    const dataBlocks = await responseBlocks.json();
    const dataQuestions = await responseQuestions.json();
    const allQuestionsDictionary = Object.fromEntries(
      dataQuestions.map((question) => [question.id, question])
    );
    rows = Object.values(dataBlocks).map((block) =>
      createData(
        block.id,
        block.name,
        block.category,
        block.for_form,
        block.questions.question_ids.map((qId) => allQuestionsDictionary[qId])
      )
    );
  } catch (e) {
    window.alert('Hubo un error al cargar los bloques');
  }
  return rows;
};
const fetchAllRaw = async () => {
  return (await requests.get('/blocks/')).json();
};
const fetchAllIndexed = async () => {
  return (await requests.get('/blocks_indexed/')).json();
};
const fetchSingleBlock = async (id) => {
  let info;
  try {
    const newValues = await requests.get(`/block/${id}/`);
    const data = await newValues.json();
    const questionsAll = await requests.get(
      `/questions_category/${data.category}/`
    );
    const questionsList = await questionsAll.json();
    const lists = separateQuestions(
      Object.values(questionsList),
      data.questions.question_ids
    );
    info = {
      id: data.id,
      name: data.name,
      category: data.category,
      for_form: data.for_form,
      questions_Selected: lists.selectedQuestions,
      questions_Not_Selected: lists.notSelectedQuestions,
    };
  } catch (e) {
    console.error(e);
    window.alert('Hubo un error al cargar el bloque');
  }
  return info;
};
const selectQuestionsBlock = async (category) => {
  let info;
  try {
    const questionsAll = await requests.get(`/questions_category/${category}/`);
    const questionsList = await questionsAll.json();
    info = {
      questions_Selected: [],
      questions_Not_Selected: Object.values(questionsList),
    };
  } catch (e) {
    console.error(e);
    window.alert('Hubo un error al cargar el bloque');
  }
  return info;
};
const fetchAssociatedForms = async (id) => {
  const result = await requests.get(`/block_associated_forms/${id}/`);
  const json = await result.json();
  return json.forms;
};
const deleteBlock = async (id) => {
  const response = await requests.del(`/block_delete/${id}/`);
  return response.ok;
};
const postBlock = async (values, form, selectedQuestions) => {
  const info = {
    name: values.name,
    category: parseInt(values.category, 10),
    for_form: form,
    status: true,
    questions: {
      question_ids: selectedQuestions.map((curr) => curr.id),
    },
  };
  const response = await requests.post('/blocks/', info);
  return response.ok;
};
const putBlock = async (id, values, category, form, selectedQuestions) => {
  const info = {
    name: values.name,
    category,
    for_form: form,
    status: true,
    questions: {
      question_ids: selectedQuestions.map((curr) => curr.id),
    },
  };
  const response = await requests.put(`/block/${id}/`, info);
  return response.ok;
};

export default {
  fetchAllIndexed,
  fetchAllRaw,
  fetchBlocks,
  fetchSingleBlock,
  selectQuestionsBlock,
  fetchAssociatedForms,
  deleteBlock,
  postBlock,
  putBlock,
};
