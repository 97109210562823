import requests from 'utils/requests';

const typeMap = {
  0: 'Si - No - N/A- N/O',
  1: 'Si - No - N/A',
  // Used to be 'opciones fijas'
  2: 'Lista de Opciones',
  3: 'Lista de Opciones',
  4: 'Texto Libre',
  5: 'Foto Obligatoria',
  6: 'Foto Opcional',
};

const valuesLength = (question) => {
  if (question.category >= 4) return 'No Aplica';
  return question.options.possible_values.length;
};

const fetchQuestions = async () => {
  const data = await requests.get('/questions_active/');
  const json = await data.json();
  const responseFormTree = await requests.get('/form_tree/');
  const dataFormTree = await responseFormTree.json();
  const dict = Object.fromEntries(
    Object.entries(dataFormTree.tree).map(([key, value]) => [key, value.name])
  );

  const out = json.map((question) => {
    const { id, value, category_id: categoryId, category } = question;
    return {
      id,
      value,
      category: dict[categoryId],
      type: typeMap[category],
      option_qty: valuesLength(question),
    };
  });

  // TODO: sorting data is a concern of presentation, move this
  out.sort((a, b) => b.id - a.id);
  return out;
};

const fetchQuestion = async (id) => {
  const result = await requests.get(`/question/${id}/`);
  return result.json();
};

const postQuestion = async (question) => {
  const result = await requests.post('/questions/', question);
  return result.ok;
};

const putQuestion = async (id, question) => {
  const result = await requests.put(`/question/${id}/`, question);
  return result.ok;
};

const fetchAssociatedBlocks = async (id) => {
  const result = await requests.get(`/question_associated_blocks/${id}/`);
  const json = await result.json();
  return json.blocks;
};

const deleteQuestion = async (id) => {
  return requests.del(`/question_delete/${id}/`);
};

export default {
  fetchQuestion,
  fetchQuestions,
  postQuestion,
  putQuestion,
  deleteQuestion,
  fetchAssociatedBlocks,
};
