import React from 'react';
import PropTypes from 'prop-types';
import { useSessionContext } from 'utils/session/sessionContext';
import BaseRoute from './BaseRoute';

const AdminRoute = ({ children, ...rest }) => {
  const { isLoggedIn, currentUser } = useSessionContext();
  return (
    <BaseRoute
      userCheckCallback={() => isLoggedIn && currentUser.isAdmin}
      {...rest}
    >
      {children}
    </BaseRoute>
  );
};

AdminRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AdminRoute;
