import React from 'react';
import { Box, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ResponsiveTableGrid } from 'components';
import useRouterUtils from 'utils/router/routerUtilsHook';
import useService from 'utils/services';
import DeletePlaceDialog from 'components/TableGrid/DeletePlaceDialog';

export default function ProvincesIndex() {
  const { region } = useService();
  const { redirect } = useRouterUtils();

  const fetchData = async () => {
    return region.fetchRegions();
  };

  const headers = [
    {
      field: 'name',
      headerName: 'Nombre',
      flex: 1,
    },
    {
      field: 'region_ordinal',
      headerName: 'Número de región',
      flex: 1,
    },
  ];

  return (
    <>
      <div className="RegionsIndex">
        <h2 className="title"> Regiones </h2>
        <Box align="center">
          <Button
            component={Link}
            to="/regiones/nueva"
            className="new-entity-btn"
            variant="contained"
          >
            Nueva región
          </Button>
        </Box>
        <ResponsiveTableGrid
          fetchAllFunc={fetchData}
          headers={headers}
          tableName="regions"
          canEdit
          canDelete
          deleteEntityName={(row) => ` la region ${row.name}`}
          onEdit={(row) => redirect(`/regiones/editar/${row.id}`)}
          onDelete={async () => fetchData()}
          DeleteDialog={DeletePlaceDialog}
        />
      </div>
    </>
  );
}
