import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Tooltip } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';

import './TableGrid.scss';

import useTableState from 'utils/table-state/tableStateHook';
import useNotification from 'utils/notifications/notificationHook';
import DefaultDeleteDialog from './DeleteDialog';
import CustomFooter from './CustomFooter';

const TableGrid = ({
  headers,
  height,
  fetchAllFunc,
  fetchPaginatedFunc,
  canView,
  canEdit,
  canDelete,
  onView,
  onEdit,
  onDelete,
  onDemand,
  data,
  useDeletePopUp,
  deleteNotification,
  deleteEntityName,
  ViewComponent,
  EditComponent,
  DeleteComponent,
  CustomActionComponent,
  DeleteDialog,
  ViewDialog,
  serverPaginated,
  tableName,
  actionsWidth,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [dialogRow, setDialogRow] = useState({});
  const { setInfoMessage } = useNotification();

  const {
    page,
    setPage,
    rows,
    setRows,
    // sortModel,
    // setSortModel,
    filterModel,
    setFilterModel,
    pageSize,
    setPageSize,
    shouldReload,
    setShouldReload,
  } = useTableState(tableName);

  const usesActions = canView || canEdit || canDelete;

  const loadData = async () => {
    setIsLoading(true);
    let newRows;
    if (!onDemand) {
      if (serverPaginated) {
        newRows = await fetchPaginatedFunc();
      } else {
        newRows = await fetchAllFunc();
      }
    } else {
      newRows = data;
    }
    setRows(newRows);
    setShouldReload(false);
    setIsLoading(false);
  };

  // react to setShouldReload(true)
  useEffect(() => {
    if (shouldReload || data) loadData();
  }, [shouldReload, data]);

  const renderActionsColumn = (params) => {
    const { row } = params;

    const ViewButton = () => {
      if (ViewComponent) return <ViewComponent row={row} />;

      const onClick = () => {
        if (ViewDialog) {
          setDialogRow(row);
          setViewDialogOpen(true);
          return;
        }
        onView(row);
      };

      return (
        <Button className="button-view" onClick={onClick}>
          Ver
        </Button>
      );
    };

    const EditButton = () => {
      if (EditComponent) return <EditComponent row={row} />;
      return (
        <Button className="button-edit" onClick={() => onEdit(row)}>
          Editar
        </Button>
      );
    };

    const DeleteButton = () => {
      if (DeleteComponent) return <DeleteComponent row={row} />;

      const onClick = async () => {
        if (useDeletePopUp) {
          setDialogRow(row);
          setDeleteDialogOpen(true);
          return;
        }
        await onDelete(row);
        await loadData();
      };

      return (
        <Button className="button-delete" onClick={onClick}>
          Eliminar
        </Button>
      );
    };

    return (
      <Grid
        container
        className="actions-container"
        justifyContent="center"
        spacing={1}
      >
        {CustomActionComponent && (
          <Grid item>
            <CustomActionComponent row={row} />
          </Grid>
        )}

        {canView && (
          <Grid item>
            <ViewButton />
          </Grid>
        )}
        {canEdit && (
          <Grid item>
            <EditButton />
          </Grid>
        )}
        {canDelete && (
          <Grid item>
            <DeleteButton />
          </Grid>
        )}
      </Grid>
    );
  };

  const isMobile = window.screen.width <= 768;

  const applyDefaultHeaders = (inputHeaders) => {
    const heads = inputHeaders.map((header) => {
      const newHeader = { ...header };
      // Set default column width when none is provided
      if (!header.width && !header.flex) newHeader.width = 250;
      if (!('tooltip' in header)) newHeader.tooltip = true;

      // Allow Scroll on mobile devices
      if (isMobile) {
        delete newHeader.flex;
        newHeader.width = 250;
      }

      if (newHeader.tooltip) {
        newHeader.renderCell = ({ formattedValue }) => {
          return (
            <Tooltip title={formattedValue}>
              <div className="MuiDataGrid-cell">{formattedValue}</div>
            </Tooltip>
          );
        };
      }
      return newHeader;
    });

    if (!usesActions) return heads;

    const lastCol = heads.length && heads[heads.length - 1];

    if (lastCol) {
      delete lastCol.width;
      lastCol.flex = 1;
    }

    heads.push({
      field: '',
      headerName: 'Acciones',
      disableClickEventBubbling: true,
      renderCell: renderActionsColumn,
      width:
        actionsWidth ||
        Math.max(120, 40 + 67 * (canView + canEdit + canDelete)),
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      align: 'center',
    });

    return heads;
  };

  const afterDelete = async (row) => {
    if (deleteNotification) {
      const msg = deleteNotification(row);
      setInfoMessage(msg);
    }
    loadData();
  };

  const SelectedDeleteDialog = DeleteDialog || DefaultDeleteDialog;

  return (
    <div className="TableGrid" style={{ height }}>
      {canDelete && useDeletePopUp && (
        <SelectedDeleteDialog
          open={deleteDialogOpen}
          setOpen={setDeleteDialogOpen}
          row={dialogRow}
          onDelete={onDelete}
          afterDelete={afterDelete}
          entityName={deleteEntityName}
          tableName={tableName}
        />
      )}

      {ViewDialog && (
        <ViewDialog
          open={viewDialogOpen}
          setOpen={setViewDialogOpen}
          row={dialogRow}
        />
      )}

      <DataGrid
        page={page}
        onPageChange={setPage}
        rows={rows}
        columns={applyDefaultHeaders(headers)}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        loading={isLoading}
        hideFooterSelectedRowCount
        filterModel={filterModel}
        onFilterModelChange={setFilterModel}
        // sortModel={sortModel}
        // onSortModelChange={setSortModel}
        components={{
          Footer: CustomFooter,
        }}
        componentsProps={{
          Footer: {
            onRefresh: loadData,
          },
        }}
      />
    </div>
  );
};

TableGrid.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    )
  ).isRequired,
  height: PropTypes.number,
  onDemand: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  // A function that returns a list of objects that will be used as rows
  fetchAllFunc: PropTypes.func,
  // A function that receives a page number and returns rows (list of objects)
  fetchPaginatedFunc: PropTypes.func,
  canView: PropTypes.bool,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
  onView: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  ViewComponent: PropTypes.elementType,
  EditComponent: PropTypes.elementType,
  DeleteComponent: PropTypes.elementType,
  CustomActionComponent: PropTypes.elementType,
  ViewDialog: PropTypes.elementType,
  DeleteDialog: PropTypes.elementType,
  deleteNotification: PropTypes.func,
  useDeletePopUp: PropTypes.bool,
  deleteEntityName: PropTypes.func,
  serverPaginated: PropTypes.bool,
  tableName: PropTypes.string.isRequired,
  actionsWidth: PropTypes.number,
};

TableGrid.defaultProps = {
  height: 650,
  onDemand: false,
  data: null,
  fetchAllFunc: null,
  fetchPaginatedFunc: null,
  canView: false,
  canEdit: false,
  canDelete: false,
  onView: null,
  onEdit: null,
  onDelete: null,
  ViewComponent: null,
  EditComponent: null,
  CustomActionComponent: null,
  DeleteComponent: null,
  DeleteDialog: null,
  ViewDialog: null,
  deleteNotification: null,
  useDeletePopUp: true,
  deleteEntityName: null,
  serverPaginated: false,
  actionsWidth: null,
};

export default TableGrid;
