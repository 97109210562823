import React from 'react';
import PropTypes from 'prop-types';
import { useSessionContext } from 'utils/session/sessionContext';
import BaseRoute from './BaseRoute';

const LoggedRoute = ({ children, ...rest }) => {
  const { isLoggedIn } = useSessionContext();
  return (
    <BaseRoute userCheckCallback={() => isLoggedIn} {...rest}>
      {children}
    </BaseRoute>
  );
};

LoggedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoggedRoute;
