import { useEffect } from 'react';
import { useSessionContext } from 'utils/session/sessionContext';

const useNotificationState = () => {
  const { notificationState, setNotificationState } = useSessionContext();

  const getDefaultState = () => {
    return {
      severity: '',
      open: false,
      timeout: 6000,
      message: '',
    };
  };

  useEffect(() => {
    if (!Object.keys(notificationState).length)
      setNotificationState(getDefaultState());
  }, []);

  const setProperty = (propName, value) => {
    const genState = (currState) => {
      const newState = { ...currState };
      newState[propName] = value;
      return newState;
    };
    setNotificationState(genState);
  };

  const setterFor = (name) => (value) => setProperty(name, value);

  const setOpen = setterFor('open');
  const setTimeout = setterFor('timeout');
  const setMessage = setterFor('message');
  const setSeverity = setterFor('severity');

  return {
    ...notificationState,
    setMessage,
    setOpen,
    setTimeout,
    setSeverity,
  };
};

export default useNotificationState;
