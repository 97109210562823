import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import NavBar from './NavBar';
import Footer from './Footer';

import './Layout.scss';

const Layout = ({ children, useNavbar }) => (
  <>
    {useNavbar && <NavBar />}
    <Grid container id="Layout" direction="column">
      <Grid container item xs id="main-frame">
        <Grid item xs>
          {children}
        </Grid>
      </Grid>
      <Grid item xs="auto">
        <Footer />
      </Grid>
    </Grid>
  </>
);

Layout.propTypes = {
  useNavbar: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  useNavbar: true,
};

export default Layout;
