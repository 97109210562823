import React from 'react';
import DataListForm from 'components/DataListForm';
import useService from 'utils/services';

export default function NonOkOptions() {
  const { nonOkOption } = useService();

  const submitFunction = async (values, args) => {
    const newValues = values.options.map((optionObj) => optionObj.optionValue);
    await nonOkOption.postNonOkOptions(newValues);
    args.setValuesFunction(newValues);
  };

  const fetchDataFunction = async (args) => {
    const data = await nonOkOption.fetchNonOkOptions();
    args.setValuesFunction(data);
  };

  return (
    <div className="NonOkOptionsDiv">
      <h2 className="title"> Razones Auditoría No Ok </h2>
      <DataListForm
        entity="razones_no_ok"
        submitFunction={submitFunction}
        fetchDataFunction={fetchDataFunction}
      />
    </div>
  );
}
