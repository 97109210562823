import { useSessionContext } from 'utils/session/sessionContext';

const useTableState = (tableName) => {
  const { tableState, setTableState } = useSessionContext();

  const getDefaultState = () => {
    return {
      page: 0,
      rows: [],
      sortModel: [],
      filterModel: { items: [] },
      pageSize: 25,
      shouldReload: true,
    };
  };

  if (!(tableName in tableState)) {
    tableState[tableName] = getDefaultState();
  }

  const currState = tableState[tableName];

  const setProperty = (propName, value) => {
    const newState = { ...tableState };
    newState[tableName][propName] = value;
    setTableState(newState);
  };

  const setterFor = (name) => (value) => setProperty(name, value);

  const setPage = setterFor('page');
  const setRows = setterFor('rows');
  const setPageSize = setterFor('pageSize');
  const setSortModel = setterFor('sortModel');
  const setFilterModel = setterFor('filterModel');
  const setShouldReload = setterFor('shouldReload');

  return {
    ...currState,
    setPage,
    setPageSize,
    setRows,
    setSortModel,
    setFilterModel,
    setShouldReload,
  };
};

export default useTableState;
