import React from 'react';
import IconButton from '@material-ui/core/IconButton';

import { useGridSlotComponentProps } from '@material-ui/data-grid';
import CachedIcon from '@material-ui/icons/Cached';

import './CustomFooter.scss';

const CustomFooter = () => {
  const { apiRef } = useGridSlotComponentProps();

  const { Pagination } = apiRef.current.components;
  const { onRefresh } = apiRef.current.componentsProps.Footer;

  return (
    <div className="CustomFooter">
      <IconButton onClick={onRefresh} aria-label="first page">
        <CachedIcon />
      </IconButton>

      <div className="spacer" />

      <Pagination className="Pagination" />
    </div>
  );
};

export default CustomFooter;
