/* eslint-disable no-console */

const API = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000/';

class AuthError extends Error {
  constructor(...params) {
    super(...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AuthError);
    }
    this.name = 'AuthError';
  }
}

const resolvePath = (path) => {
  const url = new URL(path, API);
  return url.href;
};

const mergeWithDefaults = (options) => {
  const defaults = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (!options.withoutToken) {
    const tokenStr = `Bearer ${JSON.parse(localStorage['session-data']).token}`;
    defaults.headers.Authorization = tokenStr;
  }

  return { ...defaults, ...options };
};

const makeRequest = async (path, options) => {
  const newOptions = mergeWithDefaults(options);
  const response = await fetch(resolvePath(path), newOptions);
  // Throw an exception so session can be closed
  if (!options.withoutToken && response.status === 401) {
    throw new AuthError('Invalid token');
  }
  return response;
};

const get = async (path, options = {}) => {
  return makeRequest(path, options);
};

const post = async (path, data, options = {}) => {
  return makeRequest(path, {
    method: 'POST',
    body: JSON.stringify(data),
    ...options,
  });
};

const put = async (path, data, options = {}) => {
  return makeRequest(path, {
    method: 'PUT',
    body: JSON.stringify(data),
    ...options,
  });
};

const patch = async (path, data, options = {}) => {
  return makeRequest(path, {
    method: 'PATCH',
    body: JSON.stringify(data),
    ...options,
  });
};

const del = async (path, data = {}, options = {}) => {
  if (data) {
    return makeRequest(path, {
      method: 'DELETE',
      body: JSON.stringify(data),
      ...options,
    });
  }
  return makeRequest(path, {
    method: 'DELETE',
    ...options,
  });
};

export default {
  AuthError,
  get,
  post,
  put,
  patch,
  del,
};
