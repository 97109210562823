import requests from 'utils/requests';

const fetchProvinces = async () => {
  const result = await requests.get('/provinces/');
  const data = await result.json();
  return data;
};

const fetchProvince = async (id) => {
  const result = await requests.get(`/province/${id}/`);
  const data = await result.json();
  return data;
};

const postProvince = async (values) => {
  await requests.post('/provinces/', values);
};

const patchProvince = async (values, id) => {
  // TODO:
  // Verify if backend is using PATCH or PUT
  await requests.patch(`/province/${id}/`, values);
};

const deleteProvince = async (id) => {
  const result = await requests.del(`/province/${id}/`);
  return result.json();
};

export default {
  fetchProvinces,
  fetchProvince,
  postProvince,
  patchProvince,
  deleteProvince,
};
