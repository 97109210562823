import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import useService from 'utils/services';

const BlockViewDialog = ({ open, setOpen, row }) => {
  const [values, setValues] = useState({});
  const [isLoading, setLoading] = useState(true);
  const { block } = useService();

  const { id } = row;

  const fetchData = async () => {
    setLoading(true);
    const data = await block.fetchSingleBlock(id);
    setValues(data);
    setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!open) return;
    fetchData();
  }, [open]);

  return (
    <div className="BlockViewDialog">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isLoading ? (
          <Box m={2}>
            <CircularProgress m={2} />
          </Box>
        ) : (
          <div>
            <IconButton
              className="close"
              aria-label="close pop-up"
              size="small"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
            <DialogTitle id="alert-dialog-title" align="center">
              {`Preguntas Bloque ${values.name}`}
            </DialogTitle>
            <DialogContent>
              <Box margin={1}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Número</TableCell>
                      <TableCell>Pregunta</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.questions_Selected.map((questionRow, i) => (
                      <TableRow key={questionRow.value}>
                        <TableCell component="th" scope="row" align="center">
                          {i + 1}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {questionRow.value}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </DialogContent>
          </div>
        )}
      </Dialog>
    </div>
  );
};

BlockViewDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  row: PropTypes.object.isRequired,
};
export default BlockViewDialog;
