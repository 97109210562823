import React, { useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import {
  Form,
  fieldProps,
  LoadingButton,
  ResponsiveTableGrid,
} from 'components';
import './AuditQuery.scss';
import { useFormik } from 'formik';
import { getToday, getMonthAgo } from 'utils';
import useService from 'utils/services';
import useRouterUtils from 'utils/router/routerUtilsHook';
import useTableState from 'utils/table-state';
import DropdownCategories from './DropdownCategories';

const AuditQuery = () => {
  const thisName = 'filtered_audits';
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { rows } = useTableState(thisName);
  const [data, setData] = useState(rows || []);
  const { audit } = useService();
  const { redirect } = useRouterUtils();

  const headers = [
    {
      field: 'date',
      headerName: 'Fecha de Inicio',
      width: 200,
    },
    {
      field: 'category',
      headerName: 'Categoría',
      flex: 1,
    },
    {
      field: 'audit_type',
      headerName: 'Tipo de Auditoria',
      flex: 1,
    },
    {
      field: 'service_type',
      headerName: 'Tipo de Servicio',
      flex: 1,
    },
    {
      field: 'technician_name',
      headerName: 'Nombre Técnico(s)',
      flex: 1,
    },
    {
      field: 'technician_rut',
      headerName: 'RUT',
      flex: 1,
    },
    {
      field: 'technician_enterprise',
      headerName: 'Empresa',
      flex: 1,
    },
  ];

  const onSubmit = async (values) => {
    setLoadingSubmit(true);
    const cid = values.category_id !== '_' ? values.category_id : '';
    const fixedValues = { ...values, category_id: cid };
    const fetchedAudits = await audit.fetchFilteredAudits(fixedValues);
    setData(fetchedAudits);
    setLoadingSubmit(false);
  };

  const formik = useFormik({
    initialValues: {
      init_date: getMonthAgo(),
      final_date: getToday(),
      category_id: '_',
      first_name: '',
      last_name: '',
      user_rut: '',
      technician_first_name: '',
      technician_last_name: '',
      technician_rut: '',
      enterprise: '',
    },
    enableReinitialize: true,
    onSubmit,
  });

  return (
    <Grid
      container
      direction="column"
      className="AuditQuery"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={7}>
        <Form title="Filtrar auditorías" onSubmit={formik.handleSubmit}>
          {/* <LoadView isLoading={isLoading}> */}
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs>
              Datos de técnico
              <TextField
                label="Nombre"
                id="technician_first_name"
                name="technician_first_name"
                fullWidth
                InputLabelProps={{ shrink: true }}
                {...fieldProps(formik, 'technician_first_name')}
              />
              <TextField
                label="Apellido"
                id="technician_last_name"
                name="technician_last_name"
                fullWidth
                InputLabelProps={{ shrink: true }}
                {...fieldProps(formik, 'technician_last_name')}
              />
              <TextField
                label="RUT"
                id="technician_rut"
                name="technician_rut"
                fullWidth
                InputLabelProps={{ shrink: true }}
                {...fieldProps(formik, 'technician_rut')}
              />
              <TextField
                label="Empresa"
                id="enterprise"
                name="enterprise"
                fullWidth
                InputLabelProps={{ shrink: true }}
                {...fieldProps(formik, 'enterprise')}
              />
              <TextField
                className="datePicker"
                label="Inicio"
                id="init_date"
                name="init_date"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                {...fieldProps(formik, 'init_date')}
              />
            </Grid>
            <Grid item xs>
              Datos de auditor
              <TextField
                label="Nombre"
                id="first_name"
                name="first_name"
                fullWidth
                InputLabelProps={{ shrink: true }}
                {...fieldProps(formik, 'first_name')}
              />
              <TextField
                label="Apellido"
                id="last_name"
                name="last_name"
                fullWidth
                InputLabelProps={{ shrink: true }}
                {...fieldProps(formik, 'last_name')}
              />
              <TextField
                label="RUT"
                id="user_rut"
                name="user_rut"
                fullWidth
                InputLabelProps={{ shrink: true }}
                {...fieldProps(formik, 'user_rut')}
              />
              <DropdownCategories formik={formik} />
              <TextField
                className="datePicker"
                label="Fin"
                id="final_date"
                name="final_date"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                {...fieldProps(formik, 'final_date')}
              />
            </Grid>
          </Grid>
          <Grid item align="center">
            <LoadingButton loading={loadingSubmit} text="Filtrar" />
          </Grid>
          {/* </LoadView> */}
        </Form>
      </Grid>
      <ResponsiveTableGrid
        fetchAllFunc={() => onSubmit(formik.values)}
        headers={headers}
        tableName={thisName}
        data={data}
        onDemand
        canView
        onView={(row) => redirect(`/auditorias/${row.id}?filt=true`)}
      />
    </Grid>
  );
};

export default AuditQuery;
