import React from 'react';
import { Link } from 'react-router-dom';

const NavBarDropDown = () => (
  <div className="dropdown">
    <div className="dropbtn">Administración</div>
    <div className="dropdown-content">
      <Link to="/bloques_formularios">Bloques Formularios</Link>
      <Link to="/bloques_reportes">Bloques Reportes</Link>
      <Link to="/camionetas">Camionetas</Link>
      <Link to="/categorias">Categorías</Link>
      <Link to="/comunas">Comunas</Link>
      <Link to="/empresas">Empresas</Link>
      <Link to="/formularios">Formularios</Link>
      <Link to="/preguntas">Preguntas</Link>
      <Link to="/provincias">Provincias</Link>
      <Link to="/razones_no_ok">Razones no OK</Link>
      <Link to="/regiones">Regiones</Link>
      <Link to="/equipos">Teams</Link>
      <Link to="/tecnicos">Técnicos</Link>
      <Link to="/tareas">Tipos de Tareas</Link>
      <Link to="/usuarios">Usuarios</Link>
    </div>
  </div>
);

export default NavBarDropDown;
