import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TextField, Box, Grid, MenuItem } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { rutValid } from 'utils';
import { Form, fieldProps } from 'components/Form';
import LoadingButton from 'components/LoadingButton';
import useTableState from 'utils/table-state';
import { BackButton } from 'components';
import useRouterUtils from 'utils/router/routerUtilsHook';
import useService from 'utils/services';

import './TecNew.scss';

const validationSchema = yup.object({
  rut: yup
    .string()
    .required('Campo obligatorio')
    .matches(
      /^[1-9][0-9]*[0-9]*.*-./,
      'RUT inválido. Recuerda escribir sin puntos y con guión'
    )
    .min(9, 'El RUT es muy corto. Recuerda escribir sin puntos y con guión')
    .max(
      10,
      'El RUT es demasiado largo. Recuerda escribir sin puntos y con guión'
    )
    .test('valid', 'RUT Inválido', (value) => rutValid(value)),
  first_name: yup.string().required('Este campo es obligatorio'),
  last_name: yup.string().required('Este campo es obligatorio'),
  phone: yup.string().matches(/^[0-9]{11}$/, 'Ingrese 11 números'),
  email: yup.string().matches(/^.+@.+\..+$/, 'El e-mail no es válido'),
  team_group_id: yup.string().required('Este campo es obligatorio'),
  category_id: yup.string().required('Este campo es obligatorio'),
  enterprise_id: yup.string().required('Este campo es obligatorio'),
});

const cleanValues = (values) => {
  const newValues = { ...values };
  delete newValues.category_name;
  delete newValues.last_audit_date;
  delete newValues.id;
  delete newValues.team_group_code;
  delete newValues.team_group_name;
  delete newValues.enterprise_name;

  newValues.rut.replace(/\./g, '');
  return newValues;
};

const TecNewForm = ({ isEdit, title }) => {
  const [enterprises, setEnterprises] = useState(null);
  const [categories, setCategories] = useState(null);
  const [teams, setTeams] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { id } = useParams();
  const { setShouldReload } = useTableState('techs');
  const { redirect } = useRouterUtils();
  const { tech, enterprise, formtree, team, category } = useService();

  const onSubmit = async (values) => {
    setLoadingSubmit(true);
    const newValues = cleanValues(values);
    if (isEdit) await tech.putTechnician(newValues, id);
    else await tech.postTechnician(newValues);
    setLoadingSubmit(false);
    setShouldReload(true);
    redirect('/tecnicos');
  };

  const formik = useFormik({
    initialValues: {
      rut: '',
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      enterprise_id: '',
      category_id: '',
      team_group_id: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  const fetchData = async () => {
    const enterprisesData = await enterprise.fetchEnterprises();
    setEnterprises(enterprisesData);
    let categoriesData;
    if (isEdit) {
      categoriesData = await category.fetchAll();
    } else {
      categoriesData = await formtree.fetchCategoriesActive();
    }
    setCategories(categoriesData);
    const teamsData = await team.fetchTeams();
    setTeams(teamsData);

    if (id) {
      const data = await tech.fetchTechnician(id);
      formik.setValues(data);
    }
  };

  useEffect(fetchData, []);

  const handleEnterpriseChange = (e) => {
    formik.setFieldValue('enterprise_id', e.target.value);
  };

  const handleCategoryChange = (e) => {
    formik.setFieldValue('category_id', e.target.value);
  };

  const handleTeamChange = (e) => {
    formik.setFieldValue('team_group_id', e.target.value);
  };
  const GoBack = () => <BackButton to="/tecnicos" />;

  return (
    <div>
      <div className="tecnewform">
        <Grid container justifyContent="center">
          <Grid item xs={4}>
            <Form
              onSubmit={formik.handleSubmit}
              title={title}
              HeaderComponent={GoBack}
            >
              <TextField
                fullWidth
                label="Rut"
                margin="normal"
                id="rut"
                name="rut"
                className="rut-field"
                {...fieldProps(formik, 'rut')}
              />

              <TextField
                fullWidth
                label="Nombres"
                margin="normal"
                name="first_name"
                id="first_name"
                className="name-field"
                {...fieldProps(formik, 'first_name')}
              />

              <TextField
                fullWidth
                label="Apellidos"
                margin="normal"
                name="last_name"
                id="last_name"
                className="last-names-field"
                {...fieldProps(formik, 'last_name')}
              />

              <TextField
                select
                margin="normal"
                id="enterprise_id"
                name="enterprise_id"
                label="Empresa"
                value={formik.values.enterprise_id}
                error={fieldProps(formik, 'enterprise_id').error}
                helperText={fieldProps(formik, 'enterprise_id').helperText}
                fullWidth
                onChange={(e) => handleEnterpriseChange(e)}
              >
                {enterprises &&
                  enterprises
                    .sort((a, b) => {
                      if (a.name > b.name) return 1;
                      if (a.name < b.name) return -1;
                      return 0;
                    })
                    .map(({ id: entId, name }) => (
                      <MenuItem key={entId} value={entId}>
                        {name}
                      </MenuItem>
                    ))}
              </TextField>

              <TextField
                id="category_id"
                name="category_id"
                label="Categoría"
                value={formik.values.category_id}
                margin="normal"
                fullWidth
                select
                error={fieldProps(formik, 'category_id').error}
                helperText={fieldProps(formik, 'category_id').helperText}
                onChange={(e) => handleCategoryChange(e)}
              >
                {categories &&
                  categories
                    .sort((a, b) => {
                      if (a.name > b.name) return 1;
                      if (a.name < b.name) return -1;
                      return 0;
                    })
                    .map(({ id: entId, name }) => (
                      <MenuItem key={`item-${entId}`} value={entId}>
                        {name}
                      </MenuItem>
                    ))}
              </TextField>

              <TextField
                select
                id="team_group_id"
                name="team_group_id"
                label="Team"
                value={formik.values.team_group_id}
                error={fieldProps(formik, 'team_group_id').error}
                helperText={fieldProps(formik, 'team_group_id').helperText}
                fullWidth
                onChange={(e) => handleTeamChange(e)}
              >
                {teams &&
                  teams
                    .sort((a, b) => {
                      if (a.name > b.name) return 1;
                      if (a.name < b.name) return -1;
                      return 0;
                    })
                    .map(({ id: entId, name }) => (
                      <MenuItem key={`item-team-${entId}`} value={entId}>
                        {name}
                      </MenuItem>
                    ))}
              </TextField>

              <TextField
                fullWidth
                label="Teléfono"
                margin="normal"
                name="phone"
                id="phone"
                className="phone-field"
                {...fieldProps(formik, 'phone')}
              />

              <TextField
                fullWidth
                label="Email"
                margin="normal"
                name="email"
                id="email"
                className="email-field"
                {...fieldProps(formik, 'email')}
              />
              <Box align="center">
                <LoadingButton loading={loadingSubmit} isEdit={isEdit} />
              </Box>
            </Form>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

TecNewForm.propTypes = {
  isEdit: PropTypes.bool,
  title: PropTypes.string,
};

TecNewForm.defaultProps = {
  isEdit: false,
  title: 'Nueva entidad',
};

export default TecNewForm;
