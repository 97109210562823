import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Toolbar,
  Typography,
} from '@material-ui/core';

import { Close } from '@material-ui/icons';
import './AuditPhotos.scss';
import { SecurePhoto } from 'components';

const AuditPhotos = ({ imageData }) => {
  const [selectedTile, setSelectedTile] = useState(null);

  const handleClickOpen = (image) => {
    setSelectedTile(image);
  };

  const handleClose = () => {
    setSelectedTile(null);
  };

  const getColNumber = (imageLength) => {
    if (imageLength > 4) return 4;
    return imageLength;
  };

  return (
    <div className="AuditPhotos">
      <ImageList className="ImageList" cols={getColNumber(imageData.length)}>
        {imageData.map((image) => (
          <ImageListItem
            key={image.url}
            className="list-tile"
            onClick={() => handleClickOpen(image)}
          >
            <SecurePhoto src={image.url} alt={image.text} />
            <ImageListItemBar title={image.text} />
          </ImageListItem>
        ))}
      </ImageList>

      <Dialog open={selectedTile !== null} onClose={handleClose} maxWidth="lg">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography variant="h6">{selectedTile?.text}</Typography>
        </Toolbar>

        {selectedTile && (
          <SecurePhoto src={selectedTile.url} alt={selectedTile.title} />
        )}
      </Dialog>
    </div>
  );
};

AuditPhotos.propTypes = {
  imageData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
};

export default AuditPhotos;
