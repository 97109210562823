import React from 'react';
import { Box, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ResponsiveTableGrid } from 'components';
import useService from 'utils/services';
import useRouterUtils from 'utils/router/routerUtilsHook';

export default function TeamsIndex() {
  const { team } = useService();
  const { redirect } = useRouterUtils();

  const headers = [
    {
      field: 'name',
      headerName: 'Nombre',
      flex: 1,
    },
    {
      field: 'code',
      headerName: 'Código',
      flex: 1,
    },
  ];

  return (
    <div className="TeamsIndex">
      <h2 className="title">Teams</h2>
      <Box align="center">
        <Button
          component={Link}
          to="/equipos/nuevo"
          className="new-entity-btn"
          variant="contained"
        >
          Nuevo team
        </Button>
      </Box>
      <ResponsiveTableGrid
        fetchAllFunc={team.fetchTeams}
        headers={headers}
        tableName="teams"
        canEdit
        canDelete
        deleteNotification={() => 'Team Eliminado'}
        deleteEntityName={(row) => ` el team ${row.name}`}
        onEdit={(row) => redirect(`/equipos/editar/${row.id}`)}
        onDelete={(row) => team.deleteTeam(row.id)}
      />
    </div>
  );
}
