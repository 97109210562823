/* eslint-disable no-console */
/* eslint-disable no-alert */
import requests from 'utils/requests';
import { rmKeys } from 'utils';

const newCategory = async (values, tree) => {
  const names = ['Tipo de Auditoría'];
  let structure = tree.children.map((child) => {
    return { name: child.name, form_id: 0 };
  });
  if (tree.levels > 1) {
    names.push('Tipo de Servicio');
    structure = tree.children.map((child) => {
      const grandkids = child.children.map((gchild) => {
        return { name: gchild.name, form_id: 0 };
      });
      return {
        name: child.name,
        service_types: Object.fromEntries(
          grandkids.map((gchild, i) => [i, gchild])
        ),
      };
    });
  }
  const data = {
    name: values.name,
    is_active: true,
    technicians: parseInt(values.technicians, 10),
    filter_names: names,
    levels: tree.levels,
    goal_rm: values.goal_rm,
    goal_other: values.goal_other,
    audit_types: Object.fromEntries(structure.map((child, i) => [i, child])),
  };
  const response = await requests.post('/categories/', data);
  return response.ok;
};

const editCategory = async (values, tree) => {
  const names = ['Tipo de Auditoría'];
  let structure = tree.children.map((child) => {
    return { name: child.name, form_id: child.form_id };
  });
  if (tree.levels > 1) {
    names.push('Tipo de Servicio');
    structure = tree.children.map((child) => {
      const grandkids = child.children.map((gchild) => {
        return { name: gchild.name, form_id: gchild.form_id };
      });
      return {
        name: child.name,
        service_types: Object.fromEntries(
          grandkids.map((gchild, i) => [i, gchild])
        ),
      };
    });
  }
  const data = {
    name: values.name,
    is_active: true,
    technicians: parseInt(values.technicians, 10),
    filter_names: names,
    levels: tree.levels,
    goal_rm: values.goal_rm,
    goal_other: values.goal_other,
    audit_types: Object.fromEntries(structure.map((child, i) => [i, child])),
  };
  const response = await requests.put(`/category/${values.id}/`, data);
  return response.ok;
};

const fetchAll = async () => {
  const data = await requests.get('/categories/');
  const categories = await data.json();
  return categories;
};

const fetchSingleCategory = async (id) => {
  let category;
  let response;
  let tree;
  try {
    const responseFormTree = await requests.get('/form_tree/');
    const dataFormTree = await responseFormTree.json();
    category = dataFormTree.tree[id];
    const info = {
      id,
      name: category.name,
      technicians: category.technicians,
      goal_rm: category.goal_rm,
      goal_other: category.goal_other,
    };
    tree = {
      id: '0,0',
      name: category.name,
      children: Object.values(category.audit_types).map((child, i) => {
        return {
          id: `1,${i}`,
          name: child.name,
          form_id: child.form_id,
          children: [],
        };
      }),
      levels: category.levels,
    };
    if (category.levels > 1) {
      tree.children = Object.values(category.audit_types).map((child, i) => {
        const grandkids = Object.values(child.service_types).map(
          (gchild, index) => {
            return {
              id: `2,${i},${index}`,
              name: gchild.name,
              form_id: gchild.form_id,
              children: [],
            };
          }
        );
        return { id: `1,${i}`, name: child.name, children: grandkids };
      });
    }
    response = [info, tree];
  } catch (e) {
    window.alert('Hubo un error al cargar la información de la categoría');
  }
  return response;
};

const fetchTokens = async () => {
  const entries = await (await requests.get('/clients/')).json();
  const clean = Object.values(entries).map((row) => rmKeys(row, ['category']));
  return clean;
};

const deleteCategory = async (id) => {
  const response = await requests.del(`/category/inactive/${id}/`);
  return response.ok;
};

const updateToken = async (id, value) => {
  const status = await requests.patch(`/client/${id}/`, { token: value });
  return status;
};

export default {
  newCategory,
  editCategory,
  fetchAll,
  fetchTokens,
  fetchSingleCategory,
  deleteCategory,
  updateToken,
};
