import requests from 'utils/requests';

const fetchTeams = async () => {
  const result = await requests.get('/teams/');
  const data = await result.json();
  return data;
};

const fetchTeam = async (id) => {
  const result = await requests.get(`/team/${id}/`);
  const data = await result.json();
  return data;
};

const postTeam = async (values) => {
  await requests.post(`/teams/`, values);
};

const patchTeam = async (values, id) => {
  await requests.patch(`/team/${id}/`, values);
};

const deleteTeam = async (id) => {
  await requests.del(`/team/${id}/`);
};

export default {
  fetchTeams,
  fetchTeam,
  postTeam,
  patchTeam,
  deleteTeam,
};
