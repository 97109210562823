import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core/';
import useService from 'utils/services';
import './UserShow.scss';

const UserShow = () => {
  const [values, setValues] = useState({
    first_name: 'test',
  });
  const { id } = useParams();
  const { user } = useService();

  const fetchData = async () => {
    const data = await user.fetchOne({ id });
    setValues(data);
  };
  useEffect(fetchData, []);

  return (
    <Grid container className="UserShow">
      <Grid item xs={12} lg={12} className="box">
        <h1 className="title">{`${values.first_name} ${values.last_name}`}</h1>
        Esto se debería desplegar en producccion
        <ul>
          <li>
            <strong>RUT:</strong> {values.rut}
          </li>
          <li>
            <strong>Username:</strong> {values.username}
          </li>
          <li>
            <strong>E-mail:</strong> {values.email}
          </li>
        </ul>
      </Grid>
    </Grid>
  );
};

export default UserShow;
