import React from 'react';
import { Grid, Box } from '@material-ui/core';
import './Footer.scss';

const Footer = () => (
  <Box className="Footer">
    <Grid container direction="column" justifyContent="center">
      <Grid item xs>
        <p>
          <strong>OSAM Ingeniería</strong>
        </p>
        <p>Todos los Derechos Reservados. 2021.</p>
      </Grid>
    </Grid>
  </Box>
);

export default Footer;
