import React from 'react';
import osamLogo from 'assets/osamIcon.svg';

const NavBarLogo = () => (
  <div className="navbar-logo">
    <img src={osamLogo} alt="logo" />
  </div>
);

export default NavBarLogo;
