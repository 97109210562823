import React from 'react';
import AuditEditForm from 'components/AuditEditForm';

const AuditEdit = () => {
  return (
    <div>
      <AuditEditForm />
    </div>
  );
};

export default AuditEdit;
