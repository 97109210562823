import React from 'react';
import { Link } from 'react-router-dom';
import { useSessionContext } from 'utils/session/sessionContext';
import NavBarLogo from './NavBarLogo';
import NavBarBtn from './NavBarBtn';
import NavBarDropDown from './NavBarDropDown';
import CloseSessionButton from './CloseSessionButton';
import './NavBar.scss';

const NavBar = () => {
  const { currentUser, logout } = useSessionContext();
  return (
    <div className="navbar">
      <NavBarBtn />
      <div className="navbar-links">
        <Link className="logo-link" to="/">
          <NavBarLogo />
        </Link>
        <Link to="/">Inicio</Link>
        {currentUser.isAdmin && (
          <>
            <NavBarDropDown />
            <Link to="/estadisticas">Estadísticas</Link>
          </>
        )}
        {currentUser.isAdmin && <Link to="/reportes">Reportes</Link>}
        {currentUser.isClient && <Link to="/cliente/">Reporte</Link>}
        {currentUser.isAdmin ? (
          <Link to="/auditorias">Auditorías</Link>
        ) : (
          <Link to="/auditorias/recientes">Auditorías</Link>
        )}
        <CloseSessionButton logout={logout} />
      </div>
    </div>
  );
};

export default NavBar;
