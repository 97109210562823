/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-alert */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useNotification from 'utils/notifications/notificationHook';
import LoadView from 'components/LoadView';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Typography,
  Select,
  TextField,
} from '@material-ui/core/';
import { Form, Selector, useForm, BackButton } from 'components';
import LoadingButton from 'components/LoadingButton';
import './FormSheet.scss';
import useService from 'utils/services';
import PopUp from 'components/PopUp';
import useRouterUtils from 'utils/router/routerUtilsHook';
import useTableState from 'utils/table-state';

const FormSheet = ({ isEdit, title }) => {
  const initialValues = {
    id: 0,
    name: '',
    category: '',
    audit_type: '',
    service_type: '',
    active: false,
    block_ids: [],
  };
  const [tree, setTree] = useState({});
  const [depth, setDepth] = useState(1);
  const [categories, setCategories] = useState([]);
  const [auditTypes, setAuditTypes] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [hasService, setHasService] = useState(false);
  const [hasAuditType, setHasAuditType] = useState(false);
  const [allBlocks, setBlocks] = useState([]);
  const [selectedCategory, setCategory] = useState(false);
  const [isReady, setReady] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [errorCategory, setErrorCategory] = useState(false);
  const [errorAuditType, setErrorAuditType] = useState(false);
  const [errorServiceType, setErrorServiceType] = useState(false);
  const [nameSelected, setNameSelected] = useState(false);
  const [overwrittenName, setOverwrittenName] = useState('');
  const [selectedBlocks, setSelectedBlocks] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [currentlyActive, setCurrentlyActive] = useState(isEdit);
  const { values, setValues } = useForm(initialValues);
  const { id } = useParams();
  const { redirect } = useRouterUtils();
  const { setSuccessMessage, setErrorMessage } = useNotification();
  const { formtree, form } = useService();
  const { setShouldReload } = useTableState('forms');

  const validate = () => {
    const temp = {};
    temp.name = values.name ? '' : 'Error';
    temp.category = values.category ? '' : 'Error';
    temp.audit_type = values.audit_type ? '' : 'Error';
    if (hasService) {
      temp.service_type = values.service_type ? '' : 'Error';
    }
    return Object.values(temp).every((x) => x === '');
  };
  const overwriteForm = async () => {
    if (values.active) {
      const branch = tree[values.category];
      let idForm;
      if (branch.levels > 1) {
        idForm =
          branch.audit_types[values.audit_type].service_types[
            values.service_type
          ].form_id;
      } else {
        idForm = branch.audit_types[values.audit_type].form_id;
      }
      const overwrite = await form.isFormOverwritten(idForm);
      return overwrite;
    }
    return ['', false];
  };
  const handleSubmit = async (event) => {
    setLoadingSubmit(true);
    event.preventDefault();
    if (selectedBlocks.length === 0) {
      setErrorMessage('El formulario debe contener al menos un bloque');
      setLoadingSubmit(false);
      return null;
    }
    if (validate()) {
      const answer = await overwriteForm();
      if (answer[1]) {
        setLoadingSubmit(false);
        setOverwrittenName(answer[0]);
        setOpen(true);
      } else {
        const response = await form.submitForm(
          id,
          isEdit,
          values,
          selectedBlocks
        );
        if (response) {
          const message = isEdit
            ? 'Formulario Modificado'
            : 'Formulario Creado';
          setSuccessMessage(message);
        } else {
          const message = isEdit
            ? 'Hubo un error al modificar el formulario'
            : 'Hubo un error al crear el formulario';
          setErrorMessage(message);
        }
        setLoadingSubmit(false);
        setShouldReload(true);
        redirect('/formularios');
      }
    }
    if (!values.name) {
      setErrorName(true);
    }
    if (!values.category) {
      setErrorCategory(true);
    }
    if (!values.audit_type) {
      setErrorAuditType(true);
    }
    if (hasService && !values.service_type) {
      setErrorServiceType(true);
    }
    setLoadingSubmit(false);
    return null;
  };
  const onClose = () => {
    setOpen(false);
  };
  const getFormAuditType = (categorySelected, fTree = tree) => {
    const auditMenuItems = Object.entries(
      fTree[categorySelected].audit_types
    ).map((option) => {
      return [option[0], option[1].name];
    });

    const MenuItems = auditMenuItems.map((choice) => (
      <MenuItem key={choice[0]} value={choice[0]}>
        {' '}
        {choice[1]}
      </MenuItem>
    ));
    setAuditTypes(MenuItems);
  };
  const getFormServiceType = (
    auditTypeSelected,
    categorySelected = values.category,
    fTree = tree
  ) => {
    const serviceMenuItems = Object.entries(
      fTree[categorySelected].audit_types[auditTypeSelected].service_types
    ).map((option) => {
      return [option[0], option[1].name];
    });
    const MenuItems = serviceMenuItems.map((choice) => (
      <MenuItem key={choice[0]} value={choice[0]}>
        {' '}
        {choice[1]}
      </MenuItem>
    ));
    setServiceTypes(MenuItems);
  };
  const fetchData = async () => {
    let categoryMenuItems;
    let options;
    let formTree;
    try {
      if (isEdit) {
        formTree = await formtree.fetchTree();
      } else {
        formTree = await formtree.fetchTreeActive();
      }
      const data = await form.fetchFormInfo(id, true);
      setValues(data[0]);
      setBlocks(data[1]);
      setSelectedBlocks(data[2]);
      setLoading(false);
      setTree(formTree);
      categoryMenuItems = Object.entries(formTree).map((option) => {
        return [option[0], option[1].name];
      });
      setCurrentlyActive(data[0].active);
      options = categoryMenuItems.map((choice) => (
        <MenuItem key={choice[0]} value={choice[0]}>
          {' '}
          {choice[1]}
        </MenuItem>
      ));
      setCategories(options);
      if (isEdit) {
        if (formTree[data[0].category].levels > 0) {
          setHasAuditType(true);
          getFormAuditType(data[0].category, formTree);
          if (formTree[data[0].category].levels > 1) {
            setHasService(true);
            getFormServiceType(data[0].audit_type, data[0].category, formTree);
          }
        }
      }
    } catch (e) {
      setError(true);
      console.error(e);
    }
    return null;
  };

  const onSubmit = async () => {
    const response = await form.submitForm(id, isEdit, values, selectedBlocks);
    if (response) {
      const message = isEdit ? 'Formulario Modificado' : 'Formulario Creado';
      setSuccessMessage(message);
    } else {
      const message = isEdit
        ? 'Hubo un error al modificar el formulario'
        : 'Hubo un error al crear el formulario';
      setErrorMessage(message);
    }
    setShouldReload(true);
    redirect('/formularios');
  };

  useEffect(fetchData, []);
  const buttons = (
    <div className="buttons">
      <Button onClick={onClose} color="primary">
        Cancelar
      </Button>
      <Button className="block-delete-btn" onClick={onSubmit} color="primary">
        <LoadView isLoading={isLoading} size="1.2rem">
          Confirmar
        </LoadView>
      </Button>
    </div>
  );
  const handleActiveChange = (event) => {
    const { checked } = event.target;
    const mockEvent = {
      target: {
        name: 'active',
        value: checked,
      },
    };
    const { name, value } = mockEvent.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleCategory = async (event) => {
    setErrorCategory(false);
    values.category = event.target.value;
    setCategory(true);
    setReady(false);
    const blocks = await form.selectBlocksForm(values, event.target.value);
    setValues(blocks[0]);
    setBlocks(blocks[1]);
    setReady(true);
    setDepth(tree[event.target.value].levels);
    if (hasAuditType) {
      setHasAuditType(false);
      if (hasService) {
        setHasService(false);
      }
    }
    if (tree[event.target.value].levels > 0) {
      setHasAuditType(true);
      getFormAuditType(event.target.value);
    }
  };
  const handleAuditType = (event) => {
    setErrorAuditType(false);
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (hasService) {
      setHasService(false);
    }
    if (depth > 1) {
      setHasService(true);
      getFormServiceType(event.target.value);
    }
  };
  const handleNameChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
    setNameSelected(true);
    setErrorName(false);
  };
  const handleServiceChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
    setErrorServiceType(false);
  };
  const GoBack = () => <BackButton to="/formularios" />;
  return (
    <Container className="FormNew">
      <Grid container justifyContent="center">
        <Grid item m={12}>
          <Form
            isEdit={isEdit}
            onSubmit={handleSubmit}
            title={title}
            HeaderComponent={GoBack}
          >
            {isLoading && <CircularProgress />}
            {!isLoading && !error && (
              <>
                <TextField
                  className="formField"
                  label="Nombre"
                  name="name"
                  value={values.name}
                  onChange={handleNameChange}
                  error={(nameSelected && values.name === '') || errorName}
                  helperText={
                    ((nameSelected && values.name === '') || errorName) &&
                    'Este campo es requerido'
                  }
                  fullWidth
                  margin="normal"
                />
                <br />
                <br />
                <FormControl>
                  <InputLabel className="labelField" id="category">
                    Categoría
                  </InputLabel>
                  <Select
                    name="category"
                    className="formField"
                    value={values.category}
                    error={errorCategory}
                    onChange={handleCategory}
                    disabled={isEdit}
                  >
                    {categories}
                  </Select>
                </FormControl>
                <br />
                <br />
                {hasAuditType && (
                  <>
                    <FormControl>
                      <InputLabel className="labelField" id="audit_type">
                        Tipo de Auditoría
                      </InputLabel>
                      <Select
                        name="audit_type"
                        className="formField"
                        value={values.audit_type}
                        error={errorAuditType}
                        onChange={handleAuditType}
                        disabled={isEdit}
                      >
                        {auditTypes}
                      </Select>
                    </FormControl>
                    <br />
                    <br />
                  </>
                )}
                {hasService && (
                  <>
                    <FormControl>
                      <InputLabel className="labelField" id="service_type">
                        Tipo de Servicio
                      </InputLabel>
                      <Select
                        name="service_type"
                        className="formField"
                        value={values.service_type}
                        error={errorServiceType}
                        onChange={handleServiceChange}
                        disabled={isEdit}
                      >
                        {serviceTypes}
                      </Select>
                    </FormControl>
                    <br />
                    <br />
                  </>
                )}
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.active}
                        onChange={handleActiveChange}
                        name="active"
                        color="primary"
                        disabled={isEdit && currentlyActive}
                      />
                    }
                    label="Visible en aplicación móvil"
                    labelPlacement="start"
                  />
                </FormControl>
                <br />
                <br />
                {selectedCategory && !isReady && (
                  <>
                    <CircularProgress />
                    <br />
                  </>
                )}
                {(isReady || isEdit) && (
                  <>
                    <Selector
                      questions={allBlocks}
                      selectedQuestions={selectedBlocks}
                      isQuestion={false}
                      functionRight={setSelectedBlocks}
                      functionLeft={setBlocks}
                    />
                    <br />
                    <br />
                    <LoadingButton loading={loadingSubmit} isEdit={isEdit} />
                  </>
                )}
              </>
            )}
            <PopUp
              open={open}
              onClose={onClose}
              title="Sobreescribiendo Formulario Activo"
              buttons={buttons}
            >
              <Typography align="center">
                {`Se está sobreescribiendo el formulario activo ${overwrittenName}, ¿Está seguro que desea continuar?`}
              </Typography>
            </PopUp>
          </Form>
        </Grid>
      </Grid>
    </Container>
  );
};

FormSheet.propTypes = {
  isEdit: PropTypes.bool,
  title: PropTypes.string,
};

FormSheet.defaultProps = {
  isEdit: false,
  title: 'Nuevo Formulario',
};
export default FormSheet;
