import React from 'react';
import PropTypes from 'prop-types';
import AnswersAccordeon from 'components/AnswersAccordeon';

const FormQuestions = ({ dataSections, able }) => {
  return (
    <div>
      <div className="AuditAnswers">
        <AnswersAccordeon dataSections={dataSections} able={able} />
      </div>
    </div>
  );
};

FormQuestions.propTypes = {
  dataSections: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array]))
  ).isRequired,
  able: PropTypes.bool.isRequired,
};

export default FormQuestions;
