/* eslint-disable no-console */
/* eslint-disable no-alert */
/* eslint-disable import/prefer-default-export */
import requests from 'utils/requests';

const fetchForms = async () => {
  const responseForms = await requests.get('/forms/');
  const responseFormTree = await requests.get('/form_tree/');
  let formsList;
  try {
    const dataForms = await responseForms.json();
    const dataFormTree = await responseFormTree.json();
    const formTree = dataFormTree.tree;
    formsList = dataForms.map((form) => {
      const info = {
        id: form.id,
        name: form.name,
        category: formTree[form.category].name,
        audit_type: formTree[form.category].audit_types[form.audit_type].name,
        service_type: 'No aplica',
        active: form.for_mobile ? 'Sí' : 'No',
      };
      if (formTree[form.category].levels === 2) {
        info.service_type =
          formTree[form.category].audit_types[form.audit_type].service_types[
            form.service_type
          ].name;
      }
      return info;
    });
  } catch (e) {
    window.alert('Hubo un error al cargar los formularios');
  }
  return formsList;
};

const fetchFormInfo = async (id, isForm) => {
  let info;
  let dataBlocks;
  try {
    dataBlocks = [];
    if (id) {
      const dataForm = await (await requests.get(`/form/${id}/`)).json();
      const responseBlocks = await requests.get(
        `/block_category_for_form/${dataForm.category}/True/`
      );
      dataBlocks = await responseBlocks.json();
      if (isForm) {
        const basicInfo = {
          id: dataForm.id,
          name: dataForm.name,
          category: dataForm.category,
          audit_type: dataForm.audit_type,
          service_type: dataForm.service_type,
          active: dataForm.for_mobile,
          block_ids: dataForm.blocks.block_ids,
        };
        const blocksAll = Object.values(dataBlocks).map((block) => ({
          id: block.id,
          value: block.name,
        }));
        const filteredBlocksLeft = blocksAll.filter(
          (block) => !dataForm.blocks.block_ids.includes(block.id)
        );
        const filteredBlocksRight = blocksAll.filter((block) =>
          dataForm.blocks.block_ids.includes(block.id)
        );
        filteredBlocksRight.sort(
          (a, b) =>
            dataForm.blocks.block_ids.indexOf(a.id) -
            dataForm.blocks.block_ids.indexOf(b.id)
        );
        info = [basicInfo, filteredBlocksLeft, filteredBlocksRight];
      } else {
        const responseQuestions = await requests.get(
          `/questions_category/${dataForm.category}/`
        );
        const responseFormTree = await requests.get('/form_tree/');
        const dataQuestions = await responseQuestions.json();
        const dataFormTree = await responseFormTree.json();
        const formTree = dataFormTree.tree;
        const allQuestionsDictionary = Object.fromEntries(
          Object.values(dataQuestions).map((question) => [
            question.id,
            question,
          ])
        );
        const allBlocksDictionary = Object.fromEntries(
          Object.values(dataBlocks).map((blockReceived) => [
            blockReceived.id,
            blockReceived,
          ])
        );
        const currentBlocks = dataForm.blocks.block_ids;
        const mainInfo = {
          name: dataForm.name,
          category: formTree[dataForm.category].name,
          audit_type:
            formTree[dataForm.category].audit_types[dataForm.audit_type].name,
          service_type: 'No Aplica',
          active: dataForm.for_mobile ? 'Si' : 'No',
        };
        if (formTree[dataForm.category].levels === 2) {
          mainInfo.service_type =
            formTree[dataForm.category].audit_types[
              dataForm.audit_type
            ].service_types[dataForm.service_type].name;
        }
        const data = currentBlocks.map((blockId) => {
          return {
            name: allBlocksDictionary[blockId].name,
            questions: allBlocksDictionary[blockId].questions.question_ids.map(
              (questionId) => {
                return {
                  id: questionId,
                  category: allQuestionsDictionary[questionId].category,
                  question: allQuestionsDictionary[questionId].value,
                  possible_values:
                    allQuestionsDictionary[questionId].options.possible_values,
                  answer: '',
                };
              }
            ),
          };
        });
        info = [mainInfo, data];
      }
    } else {
      const mainInfo = {
        id: 0,
        name: '',
        category: '',
        audit_type: '',
        service_type: '',
        active: false,
        block_ids: [],
      };
      const blocks = dataBlocks.map((block) => ({
        id: block.id,
        value: block.name,
      }));
      info = [mainInfo, blocks, []];
    }
  } catch (e) {
    window.alert('Hubo un error al mostrar el formulario');
  }
  return info;
};
const selectBlocksForm = async (values, category) => {
  let info;
  try {
    const blocksAll = await requests.get(
      `/block_category_for_form/${category}/True/`
    );
    const blocksList = await blocksAll.json();
    const mainInfo = {
      id: 0,
      name: values.name,
      category: values.category,
      audit_type: values.audit_type,
      service_type: values.service_type,
      active: values.active,
      block_ids: [],
    };
    const blocks = Object.values(blocksList).map((block) => ({
      id: block.id,
      value: block.name,
    }));
    info = [mainInfo, blocks, []];
  } catch (e) {
    console.error(e);
    window.alert('Hubo un error al cargar el bloque');
  }
  return info;
};
/* TODO: Move to reports services */
const selectBlocksReport = async (category, raw = false) => {
  const blocksList = await (
    await requests.get(`/block_category_for_form/${category}/False/`)
  ).json();
  if (raw) return Object.values(blocksList);
  const blocks = Object.values(blocksList).map((block) => ({
    id: block.id,
    value: block.name,
  }));
  return blocks;
};

const submitForm = async (id, isEdit, values, selectedBlocks) => {
  const endpoint = isEdit ? `/form/${id}/` : '/forms/';
  let response;
  const data = {
    name: values.name,
    blocks: {
      block_ids: selectedBlocks.map((b) => b.id),
    },
    for_mobile: values.active,
    category: values.category,
    audit_type: values.audit_type,
    service_type: values.service_type ? values.service_type : '0',
  };
  if (isEdit) {
    response = await requests.put(endpoint, data).catch((err) => {
      window.alert('Hubo un error al editar el formulario');
      console.error(err);
    });
  } else {
    response = await requests.post(endpoint, data).catch((err) => {
      window.alert('Hubo un error al crear el formulario');
      console.error(err);
    });
  }
  return response.ok;
};

const deleteForm = async (id) => {
  const response = await requests.del(`form/${id}/`);
  return response.ok;
};

const isFormOverwritten = async (id) => {
  const formResponse = await requests.get(`form/${id}/`);
  const form = await formResponse.json();
  if (form.for_mobile) {
    return [form.name, true];
  }
  return [form.name, false];
};

export default {
  fetchForms,
  fetchFormInfo,
  selectBlocksForm,
  selectBlocksReport,
  isFormOverwritten,
  submitForm,
  deleteForm,
};
