/* eslint-disable consistent-return */
/* eslint-disable no-console */
/* eslint-disable no-alert */
import requests from 'utils/requests';

const fetchTree = async () => {
  let formTree;
  try {
    const responseFormTree = await requests.get('/form_tree/');
    const dataFormTree = await responseFormTree.json();
    formTree = dataFormTree.tree;
  } catch (e) {
    window.alert('Hubo un error al cargar las opciones de categoría.');
  }
  return formTree;
};

const fetchTreeActive = async () => {
  let formTree;
  try {
    const responseFormTree = await requests.get('/form_tree/active/');
    const dataFormTree = await responseFormTree.json();
    formTree = dataFormTree.tree;
  } catch (e) {
    window.alert('Hubo un error al cargar las opciones de categoría.');
  }
  return formTree;
};

const fetchCategoriesInfo = async () => {
  let formTree;
  let info;
  try {
    const responseFormTree = await requests.get('form_tree/active/');
    const dataFormTree = await responseFormTree.json();
    formTree = dataFormTree.tree;
    info = Object.entries(formTree).map(([id, tree]) => {
      return {
        id,
        name: tree.name,
        number_tech: tree.technicians,
        levels: tree.levels,
        goal_rm: tree.goal_rm,
        goal_other: tree.goal_other,
      };
    });
  } catch (e) {
    window.alert('Hubo un error al cargar las opciones de categoría.');
  }
  return info;
};

const fetchCategoriesActive = async () => {
  let formTree;
  let info;
  try {
    const responseFormTree = await requests.get('form_tree/active/');
    const dataFormTree = await responseFormTree.json();
    formTree = dataFormTree.tree;
    info = Object.entries(formTree).map(([id, tree]) => {
      return {
        id,
        name: tree.name,
      };
    });
  } catch (e) {
    window.alert('Hubo un error al cargar las opciones de categoría.');
  }
  return info;
};
export default {
  fetchTree,
  fetchTreeActive,
  fetchCategoriesInfo,
  fetchCategoriesActive,
};
