import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TextField, Box, Grid } from '@material-ui/core/';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useRouterUtils from 'utils/router/routerUtilsHook';
import { BackButton } from 'components';
import LoadingButton from 'components/LoadingButton';
import useTableState from 'utils/table-state';
import useService from 'utils/services';
import { Form, fieldProps } from 'components/Form';

const validationSchema = yup.object({
  name: yup.string().required('Este campo es obligatorio'),
  region_name: yup.string().required('Este campo es obligatorio'),
});

const ProvincesForm = ({ isEdit, title }) => {
  const { redirect } = useRouterUtils();
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState({ fullname: '' });
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { id } = useParams();
  const { province, region } = useService();
  const { setShouldReload } = useTableState('provinces');

  const onSubmit = async (values) => {
    setLoadingSubmit(true);
    // eslint-disable-next-line camelcase
    const { region_name, ...realValues } = values;
    if (isEdit) await province.patchProvince(realValues, id);
    else await province.postProvince(realValues);
    setLoadingSubmit(false);
    setShouldReload(true);
    redirect('/provincias');
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      region_name: '',
      region_id: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  const romanToNum = (roman) => {
    if (roman === '') return 0;
    if (roman.startsWith('X')) return 10 + romanToNum(roman.substr(1));
    if (roman.startsWith('IX')) return 9 + romanToNum(roman.substr(2));
    if (roman.startsWith('V')) return 5 + romanToNum(roman.substr(1));
    if (roman.startsWith('IV')) return 4 + romanToNum(roman.substr(2));
    if (roman.startsWith('I')) return 1 + romanToNum(roman.substr(1));
    if (roman.startsWith('RM')) return 5.5;
    return 0;
  };

  const getOrdinal = (name) => {
    const firstPart = name.split()[0];
    return firstPart.substring(1, firstPart.length - 1);
  };

  const fetchData = async () => {
    const regionsData = await region.fetchRegions();
    const regionsObj = regionsData.map((regionObj) => {
      return {
        ...regionObj,
        fullname: `(${regionObj.region_ordinal}) ${regionObj.name}`,
      };
    });
    setRegions(regionsObj);

    if (isEdit && id) {
      const data = await province.fetchProvince(id);
      await formik.setValues({
        name: data.name,
        region_id: data.region_id,
        region_name: regionsObj.filter(
          (regionObj) => regionObj.id === data.region_id
        )[0].name,
      });
      await formik.setFieldValue('name', data.name);
      await formik.setFieldValue('region_id', data.region_id);
      await formik.setFieldValue(
        'region_name',
        regionsObj.filter((regionObj) => regionObj.id === data.region_id)[0]
          .name
      );
    }
  };

  useEffect(fetchData, []);

  useEffect(() => {
    setSelectedRegion(
      regions.find((obj) => obj.name === formik.values.region_name)
    );
  }, [formik.values.region_name]);

  const handleClick = (regionObj) => {
    formik.setFieldValue('region_name', regionObj.name);
    formik.setFieldValue('region_id', regionObj.id);
    setSelectedRegion(regionObj);
  };

  const GoBack = () => <BackButton to="/provincias" />;

  return (
    <div>
      <div className="provinceForm">
        <Grid container justifyContent="center">
          <Grid item xs={4}>
            <Form
              onSubmit={formik.handleSubmit}
              title={title}
              HeaderComponent={GoBack}
            >
              <TextField
                fullWidth
                label="Nombre"
                margin="normal"
                id="name"
                name="name"
                className="name"
                {...fieldProps(formik, 'name')}
              />
              {regions && (
                <Autocomplete
                  id="grouped"
                  value={selectedRegion}
                  options={regions.sort(
                    (a, b) =>
                      romanToNum(getOrdinal(a.fullname)) -
                      romanToNum(getOrdinal(b.fullname))
                  )}
                  getOptionLabel={(regionObj) => regionObj.fullname}
                  onChange={(e, regionObj) => handleClick(regionObj)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Región"
                      variant="outlined"
                      {...fieldProps(formik, 'region_name')}
                    />
                  )}
                />
              )}

              <Box align="center">
                <LoadingButton loading={loadingSubmit} isEdit={isEdit} />
              </Box>
            </Form>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

ProvincesForm.propTypes = {
  isEdit: PropTypes.bool,
  title: PropTypes.string,
};

ProvincesForm.defaultProps = {
  isEdit: false,
  title: 'Nueva provincia',
};

export default ProvincesForm;
