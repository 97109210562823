/* eslint-disable no-console */
import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';

const convertBlobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      const data = reader.result;
      const b64 = data.substr(data.indexOf(',') + 1);
      resolve(b64);
    };
    reader.readAsDataURL(blob);
  });

const getS3Image = async (bucket, key) => {
  const config = {
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    },
    region: process.env.REACT_APP_AWS_REGION,
  };

  const params = {
    Bucket: bucket,
    Key: key,
  };

  const client = new S3Client(config);
  const command = new GetObjectCommand(params);
  const { Body } = await client.send(command);

  const resp = new Response(Body, {});

  return resp.blob().then(convertBlobToBase64);
};

export const getS3ImageSafe = async (bucket, key, region) => {
  try {
    return await getS3Image(bucket, key, region);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const getS3ImageFromUrl = (imageUrl) => {
  const url = new URL(imageUrl);
  const [bucket] = url.hostname.split('.');
  const filename = decodeURIComponent(
    `${url.pathname}`.replace(/\+/g, '%20')
  ).slice(1);

  return getS3ImageSafe(bucket, filename);
};

export const getS3ImageFromUrlSafe = async (bucket, key, region) => {
  try {
    return await getS3ImageFromUrl(bucket, key, region);
  } catch (error) {
    console.error(error);
    return null;
  }
};
