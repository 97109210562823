/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import PropTypes, { arrayOf } from 'prop-types';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import './Selector.scss';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import useNotification from 'utils/notifications/notificationHook';
import SearchBar from '../SearchBar';
import DisplayQuestions from '../DisplayQuestions';

const not = (a, b) => a.filter((value) => b.indexOf(value) === -1);
const intersection = (a, b) => a.filter((value) => b.indexOf(value) !== -1);
const union = (a, b) => [...a, ...not(b, a)];

const Selector = ({
  questions,
  selectedQuestions,
  isQuestion,
  functionRight,
  functionLeft,
}) => {
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState(questions);
  const [filteredLeft, setFilteredLeft] = useState(left);
  const [right, setRight] = useState(selectedQuestions);
  const [filteredRight, setFilteredRight] = useState(right);
  const leftChecked = intersection(checked, filteredLeft);
  const rightChecked = intersection(checked, right);
  const [inputLeft, setInputLeft] = useState('');
  const [inputRight, setInputRight] = useState('');
  const [dragEnabled, setDragEnabled] = useState(true);
  const { setWarningMessage } = useNotification();

  const requestSearchLeft = (searchedVal) => {
    const filteredItems = left.filter((row) =>
      row.value.toLowerCase().includes(searchedVal.toLowerCase())
    );
    setInputLeft(searchedVal);
    setFilteredLeft(filteredItems);
  };
  const requestSearchRight = (searchedVal) => {
    setDragEnabled(searchedVal.length === 0);

    const filteredItems = right.filter((row) =>
      row.value.toLowerCase().includes(searchedVal.toLowerCase())
    );
    setInputRight(searchedVal);
    setFilteredRight(filteredItems);
  };
  const cancelSearchLeft = () => {
    requestSearchLeft('');
  };
  const cancelSearchRight = () => {
    requestSearchRight('');
  };
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const numberOfChecked = (items) => intersection(checked, items).length;
  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };
  const handleCheckedRight = () => {
    setFilteredRight(filteredRight.concat(leftChecked));
    setFilteredLeft(not(filteredLeft, leftChecked));
    setChecked(not(checked, leftChecked));
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    functionRight(right.concat(leftChecked));
    functionLeft(not(left, leftChecked));
  };
  const handleCheckedLeft = () => {
    setFilteredLeft(filteredLeft.concat(rightChecked));
    setFilteredRight(not(filteredRight, rightChecked));
    setChecked(not(checked, rightChecked));
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    functionLeft(left.concat(rightChecked));
    functionRight(not(right, rightChecked));
  };
  const SortableItem = sortableElement(({ value }) => {
    return <ListItemText id={value.id} primary={value.value.toString()} />;
  });
  const SortableContainer = sortableContainer(({ children }) => {
    return (
      <List className="list" dense component="div" role="list">
        {children}
      </List>
    );
  });
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (!dragEnabled) {
      setWarningMessage('No se puede reordenar cuando hay una búsqueda');
      setFilteredRight(filteredRight);
      return;
    }

    const newRight = filteredRight.slice();
    newRight.splice(
      newIndex < 0 ? right.length + newIndex : newIndex,
      0,
      newRight.splice(oldIndex, 1)[0]
    );
    setFilteredRight(newRight);
    functionRight(newRight);
  };
  const customList = (title, items, isLeft) => (
    <Card className="SelectorList">
      <SearchBar
        keyword={isLeft ? inputLeft : inputRight}
        placeholder={isQuestion ? 'Busca la pregunta' : 'Busca el bloque'}
        setKeyword={isLeft ? requestSearchLeft : requestSearchRight}
        cancelSearch={isLeft ? cancelSearchLeft : cancelSearchRight}
      />
      <CardHeader
        className="cardHeader"
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
            color="primary"
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/\
        ${items.length} items selecionados`}
      />
      <Divider />
      {!isLeft && (
        <SortableContainer onSortEnd={onSortEnd}>
          {items.map((value, index) => {
            return (
              <ListItem key={value.id} role="listitem" button>
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    color="primary"
                    onClick={handleToggle(value)}
                  />
                </ListItemIcon>
                <SortableItem
                  key={`item-${value.id}`}
                  index={index}
                  value={value}
                />
                {!isQuestion && <DisplayQuestions id={value.id} />}
              </ListItem>
            );
          })}
        </SortableContainer>
      )}
      {isLeft && (
        <List className="list" dense component="div" role="list">
          {items.map((value) => {
            const labelId = `transfer-list-all-item-${value}-label`;
            return (
              <ListItem key={value.id} role="listitem" button>
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                    color="primary"
                    onClick={handleToggle(value)}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.value.toString()} />
                <DisplayQuestions
                  id={value.id}
                  isQuestion={isQuestion}
                  forTable={false}
                />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      )}
    </Card>
  );
  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      className="Selector"
    >
      <Grid item>
        {customList(
          isQuestion ? 'Preguntas a Elegir' : 'Bloques a elegir',
          filteredLeft,
          true
        )}
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className="button"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className="button"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        {customList(
          isQuestion ? 'Preguntas Seleccionadas' : 'Bloques Seleccionados',
          filteredRight,
          false
        )}
      </Grid>
    </Grid>
  );
};

Selector.propTypes = {
  questions: arrayOf(PropTypes.object).isRequired,
  selectedQuestions: arrayOf(PropTypes.object).isRequired,
  isQuestion: PropTypes.bool,
  functionRight: PropTypes.func.isRequired,
  functionLeft: PropTypes.func.isRequired,
};

Selector.defaultProps = {
  isQuestion: true,
};
export default Selector;
