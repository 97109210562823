import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputLabel, MenuItem, Select } from '@material-ui/core';
import { fieldProps, LoadView } from 'components';
import { rmKeys } from 'utils';
import useService from 'utils/services';

const DropdownCategories = ({ formik }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const { formtree } = useService();

  async function loadData() {
    const tree = await formtree.fetchTreeActive();
    const clean = Object.entries(tree).map(([id, { name }]) => {
      return { id, name };
    });
    setCategories(clean);
    setIsLoading(false);
  }
  useEffect(loadData, []);

  return (
    <div className="dropdown">
      <InputLabel className="labelField" id="category" shrink>
        Categoría
      </InputLabel>
      <Select
        id="category_id"
        name="category_id"
        fullWidth
        value={formik.values.category_id}
        {...rmKeys(fieldProps(formik, 'category_id'), ['helperText'])}
      >
        <MenuItem value="_">Todas</MenuItem>
        <LoadView isLoading={isLoading}>
          {categories.map(({ id, name }) => {
            return (
              <MenuItem key={id} value={`${id}`}>
                {name}
              </MenuItem>
            );
          })}
        </LoadView>
      </Select>
    </div>
  );
};

DropdownCategories.propTypes = {
  formik: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.func,
    ])
  ).isRequired,
};

export default DropdownCategories;
