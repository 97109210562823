import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSessionContext } from 'utils/session/sessionContext';
import { Box, Grid, TextField } from '@material-ui/core';
import fileDownload from 'js-file-download';
import {
  FormQuestions,
  Entry,
  Form,
  LoadingButton,
  LoadView,
  fieldProps,
  BackButton,
} from 'components';
import useService from 'utils/services';
import { makeJoin, getToday, buildCategoryDict } from 'utils';
import './ReportShow.scss';

const validationSchema = yup.object({
  client_entry_timestamp: yup.date(),
  client_exit_timestamp: yup
    .date()
    .min(
      yup.ref('client_entry_timestamp'),
      'La fecha de fin no puede ser anterior a la fecha de inicio'
    ),
});

const ReportShow = () => {
  const [blocks, setBlocks] = useState([]);
  const [categoryDict, setCategoryDict] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [template, setTemplate] = useState({});
  const [downloading, setDownloading] = useState(false);
  const [noActiveReport, setNoActiveReport] = useState(false);
  const [loadingBlocks, setLBlocks] = useState(true);
  const { id } = useParams();
  const { block, report, question } = useService();
  const { currentUser } = useSessionContext();
  const [stateId, setStateId] = useState(id);

  const fetchData = async () => {
    let newId = id;
    setStateId(newId);
    if (!id) {
      const apiReports = await report.fetchForAPI();
      // "This Category Report"
      const activeReport = Object.values(apiReports).filter(
        (rep) => rep.category === `${currentUser.clientCategory}`
      )[0];
      setNoActiveReport(!activeReport);
      if (!activeReport) {
        setLoading(false);
        return;
      }
      newId = activeReport.id;
      setStateId(newId);
    }
    const data = await report.fetchOne(newId);
    const categoryNames = await buildCategoryDict();
    setCategoryDict(categoryNames);
    setTemplate(data);
    setLoading(false);
  };
  // TODO: (Maybe) move to services
  const fetchBlocks = async () => {
    setLBlocks(true);
    const dataBlocks = await block.fetchAllIndexed();
    const dataQuestions = await question.fetchQuestions();
    const blockObjs = makeJoin(
      Object.values(dataBlocks),
      template?.blocks?.block_ids
    );
    const clean = blockObjs.map((bloq) => {
      const bloqQuestions = dataQuestions.filter((q) =>
        bloq.questions.question_ids.includes(q.id)
      );
      return {
        name: bloq.name,
        questions: bloqQuestions.map((q) => ({
          id: q.id,
          category: bloq.category,
          question: q.value,
          answer: null,
        })),
      };
    });
    setBlocks(clean);
    setLBlocks(false);
  };
  useEffect(fetchData, []);
  useEffect(fetchBlocks, [template]);

  const onSubmit = async (values) => {
    setDownloading(true);
    const clean = {};
    clean.client_entry_timestamp = `${values.client_entry_timestamp}T00:00:00.000000`;
    clean.client_exit_timestamp = `${values.client_exit_timestamp}T23:59:59.000000`;
    const csv = await (await report.generate(stateId, clean)).blob();
    fileDownload(csv, `${template.name}.csv`);
    setDownloading(false);
  };

  const formik = useFormik({
    initialValues: {
      client_entry_timestamp: getToday(),
      client_exit_timestamp: getToday(),
    },
    onSubmit,
    validationSchema,
  });

  const GoBack = () => <BackButton to="/reportes" />;

  return (
    <Grid className="ReportShow" justifyContent="center" container>
      <Grid item xs={12} lg={4}>
        <Form
          onSubmit={formik.handleSubmit}
          title="Descargar Reporte"
          HeaderComponent={GoBack}
        >
          <LoadView isLoading={isLoading}>
            {noActiveReport ? (
              'Actualmente no hay reportes activos para esta categoría'
            ) : (
              <>
                <ul>
                  <Entry name="Modelo" value={template.name} />
                  <Entry
                    name="Todas las preguntas"
                    value={template.question_based ? 'Sí' : 'No'}
                  />
                  <Entry
                    name="Categoría"
                    value={categoryDict[template.category]}
                  />
                  <Entry
                    name="Para Clientes"
                    value={template.for_api ? 'Sí' : 'No'}
                  />
                  <Entry
                    name="Sólo administrador"
                    value={template.only_admin ? 'Sí' : 'No'}
                  />
                  <Entry
                    name="Enumerado"
                    value={template.is_enumerated ? 'Sí' : 'No'}
                  />
                </ul>
                {/* TODO: (Nice to have) use Material-UI's KeyboardDatePicker */}
                <TextField
                  label="Inicio"
                  id="client_entry_timestamp"
                  name="client_entry_timestamp"
                  type="date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...fieldProps(formik, 'client_entry_timestamp')}
                />
                <TextField
                  label="Fin"
                  id="client_exit_timestamp"
                  name="client_exit_timestamp"
                  type="date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...fieldProps(formik, 'client_exit_timestamp')}
                />
                <Box align="center">
                  <LoadingButton loading={downloading} text="Descargar" />
                </Box>
              </>
            )}
          </LoadView>
        </Form>
      </Grid>
      {!formik.values.question_based && (
        <Grid
          container
          item
          justifyContent="center"
          alignItems="center"
          xs={12}
          lg={4}
        >
          <LoadView isLoading={loadingBlocks}>
            <FormQuestions dataSections={blocks} able={false} />
          </LoadView>
        </Grid>
      )}
    </Grid>
  );
};

ReportShow.propTypes = {
  clientCategory: PropTypes.number,
};

ReportShow.defaultProps = {
  clientCategory: -1,
};

export default ReportShow;
