/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { useParams } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TextField, Button, Box, Grid, Container } from '@material-ui/core/';
import useNotification from 'utils/notifications/notificationHook';
import { Form, Selector, fieldProps, BackButton } from 'components';
import LoadingButton from 'components/LoadingButton';
import './BlockFormEdit.scss';
import requests from 'utils/requests';
import useService from 'utils/services';
import useTableState from 'utils/table-state';
import useRouterUtils from 'utils/router/routerUtilsHook';

const validationSchema = yup.object({
  name: yup.string().required('Campo obligatorio'),
});

const BlockFormEdit = ({ form, title }) => {
  const { setSuccessMessage, setErrorMessage } = useNotification();
  const [isReady, setReady] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [tree, setTree] = useState({});
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [categories, setCategories] = useState([]);
  const [values, setValues] = useState({});
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const { id } = useParams();
  const { block, formtree } = useService();
  const { redirect } = useRouterUtils();
  const tableName = form ? 'blocks-form' : 'blocks-report';
  const { setShouldReload } = useTableState(tableName);
  const link = form ? '/bloques_formularios' : '/bloques_reportes';

  const GoBack = () => (
    <BackButton to={form ? '/bloques_formularios' : '/bloques_reportes'} />
  );

  const onSubmit = async (val) => {
    setLoadingSubmit(true);
    const response = await block.putBlock(
      id,
      val,
      values.category,
      form,
      right
    );

    if (response) {
      setSuccessMessage('Bloque Modificado');
    } else {
      setErrorMessage('Hubo un error al modificar el bloque');
    }
    setLoadingSubmit(false);
    setShouldReload(true);
    redirect(link);
  };

  const formik = useFormik({
    initialValues: {
      id: 0,
      name: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });
  const fetchData = async () => {
    let categoryOptions;
    let options;
    try {
      const formTree = await formtree.fetchTree();
      const data = await block.fetchSingleBlock(id);
      formik.setValues({ ...formik.initialValues, name: data.name });
      setValues(data);
      setLeft(data.questions_Not_Selected);
      setRight(data.questions_Selected);
      setTree(formTree);
      categoryOptions = Object.entries(formTree).map((option) => {
        return [option[0], option[1].name];
      });
      categoryOptions.unshift(['', '']);
      options = categoryOptions.map((choice) => (
        <option key={choice[0]} value={choice[0]}>
          {' '}
          {choice[1]}
        </option>
      ));
      setCategories(options);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(fetchData, []);

  return (
    <Container className="BlockFormEdit">
      <Grid container justifyContent="center">
        <Grid item m={12}>
          <Box align="center">
            <Form
              isEdit
              onSubmit={formik.handleSubmit}
              title={title}
              HeaderComponent={GoBack}
            >
              {isLoading && <CircularProgress />}
              {!isLoading && (
                <>
                  <TextField
                    className="formField"
                    label="Nombre"
                    name="name"
                    {...fieldProps(formik, 'name')}
                    fullWidth
                    margin="normal"
                  />
                  <br />
                  <br />
                  <FormControl>
                    <InputLabel className="labelField" id="category">
                      Categoría
                    </InputLabel>
                    <Select
                      native
                      name="category"
                      className="formField"
                      value={values.category}
                      disabled
                    >
                      {categories}
                    </Select>
                  </FormControl>
                  <br />
                  <br />
                  <Selector
                    questions={values.questions_Not_Selected}
                    selectedQuestions={values.questions_Selected}
                    functionRight={setRight}
                    functionLeft={setLeft}
                    isQuestion
                  />
                  <br />
                  <LoadingButton loading={loadingSubmit} isEdit />
                </>
              )}
            </Form>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

BlockFormEdit.propTypes = {
  form: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

BlockFormEdit.defaultProps = {
  title: 'Editar Bloque',
};
export default BlockFormEdit;
