import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@material-ui/core/';
import { Form, fieldProps, LoadView, Selector, BackButton } from 'components';
import LoadingButton from 'components/LoadingButton';
import useService from 'utils/services';
import { rmKeys } from 'utils';
import useRouterUtils from 'utils/router/routerUtilsHook';
import useTableState from 'utils/table-state';
import useNotification from 'utils/notifications/notificationHook';
import Checkbox from './Checkbox';
import './ReportTemplateForm.scss';

const validationSchema = yup.object({
  name: yup.string().required('Debes ingresar un nombre al modelo'),
  category: yup.string().required('Selecciona una categoría'),
  name_format: yup.string().required('Selecciona un formato para los nombres'),
});

const ReportTemplateForm = ({ isEdit, title }) => {
  const [isLoading, setLoading] = useState(true);
  const [loadBlocks, setLoadBlocks] = useState(false);
  const [categories, setCategories] = useState([]);
  const [show, setShow] = useState(true);
  const [sBlocks, setSBlocks] = useState([]);
  const [uBlocks, setUBlocks] = useState([]);
  const [values, setValues] = useState({
    question_based: false,
    category: '0',
  });
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { formtree, form, report, category } = useService();
  const { redirect } = useRouterUtils();
  const { setSuccessMessage } = useNotification();
  const { setShouldReload } = useTableState('reports');
  const { id } = useParams();

  const onSubmit = async (formikValues) => {
    setLoadingSubmit(true);
    const blockIds = sBlocks.map((bloq) => bloq.id);
    const newValues = {
      ...values,
      ...formikValues,
      blocks: { block_ids: blockIds },
    };
    if (isEdit) {
      await report.editTemplate(id, newValues);
      setSuccessMessage('Reporte Modificado');
    } else {
      await report.createTemplate(newValues);
      setSuccessMessage('Reporte Creado');
    }

    setShouldReload(true);
    redirect('/reportes');
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      question_based: false,
      for_api: false,
      only_admin: false,
      is_enumerated: false,
    },
    validationSchema,
    onSubmit,
  });

  const fetchData = async () => {
    let categoryObjs;
    if (isEdit) {
      categoryObjs = await category.fetchAll();
    } else {
      categoryObjs = await formtree.fetchCategoriesActive();
    }
    const MenuItems = categoryObjs.map((choice) => (
      <MenuItem key={choice.id} value={choice.id}>
        {' '}
        {choice.name}
      </MenuItem>
    ));
    if (isEdit) {
      const data = await report.fetchOne(id);
      const { blocks } = data;
      const blockObjs = await form.selectBlocksReport(data.category);
      const blockIds = blockObjs
        .map((bloq) => bloq.id)
        .filter((bid) => blocks.block_ids.includes(bid));
      const clean = {
        ...rmKeys(data, ['blocks']),
        blocks: { block_ids: blockIds },
      };
      const dbSBlocks = blockObjs.filter((bloq) => blockIds.includes(bloq.id));
      setUBlocks(blockObjs.filter((bloq) => !blockIds.includes(bloq.id)));
      setSBlocks(dbSBlocks);
      setLoadBlocks(!data.question_based);
      formik.setValues(clean);
    }
    setCategories(MenuItems);
    setLoading(false);
  };
  useEffect(fetchData, []);

  const checkboxProps = (name) =>
    rmKeys(fieldProps(formik, name), ['error', 'helperText']);

  useEffect(() => {
    setShow(false);
    setShow(!formik.values.question_based);
  }, [formik.values.question_based]);

  const handleCategory = async (evt) => {
    setLoadBlocks(false);
    formik.handleChange(evt);
    setValues({ ...values, ...formik.values, category: evt.target.value });
    setUBlocks(await form.selectBlocksReport(evt.target.value));
    setSBlocks([]);
    setLoadBlocks(true);
  };

  const GoBack = () => <BackButton to="/reportes" />;

  return (
    <Grid className="ReportTemplateForm" container justifyContent="center">
      <Form
        onSubmit={formik.handleSubmit}
        title={title}
        HeaderComponent={GoBack}
      >
        <LoadView isLoading={isLoading}>
          <TextField
            label="Nombre"
            id="name"
            name="name"
            margin="normal"
            fullWidth
            {...fieldProps(formik, 'name')}
          />
          <FormControl margin="normal" fullWidth>
            <InputLabel>Categoría</InputLabel>
            <Select
              id="category"
              name="category"
              onChange={handleCategory}
              disabled={isEdit}
              fullWidth
              {...rmKeys(fieldProps(formik, 'category'), [
                'helperText',
                'onChange',
              ])}
            >
              {categories}
            </Select>
          </FormControl>
          <input type="hidden" name="for_api" value={false} />
          <FormControl className="block" fullWidth margin="normal">
            <InputLabel>Formato</InputLabel>
            <Select
              id="name_format"
              name="name_format"
              fullWidth
              {...rmKeys(fieldProps(formik, 'name_format'), ['helperText'])}
            >
              <MenuItem value="all_caps">NOMBRES APELLIDOS</MenuItem>
              <MenuItem value="all_minus">nombres apellidos</MenuItem>
              <MenuItem value="camel_case">Nombres Apellidos</MenuItem>
              <MenuItem value="inverted_camel_case">Apellidos Nombres</MenuItem>
              <MenuItem value="camel_case_comma">Nombres, Apellidos</MenuItem>
              <MenuItem value="inverted_camel_case_comma">
                Apellidos, Nombres
              </MenuItem>
              <MenuItem value="inverted_all_caps">APELLIDOS NOMBRES</MenuItem>
              <MenuItem value="inverted_all_minus">apellidos nombres</MenuItem>
            </Select>
          </FormControl>
          <input type="hidden" name="for_api" value={false} />
          {/* These are customised M-UI Checkboxes */}
          <Checkbox
            label="Sólo visible para usuario administrador"
            idName="only_admin"
            checked={formik.values.only_admin}
            additionalProps={checkboxProps('only_admin')}
          />
          <Checkbox
            label="Preguntas enumeradas"
            idName="is_enumerated"
            checked={formik.values.is_enumerated}
            additionalProps={checkboxProps('is_enumerated')}
          />

          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>Elegir bloques</Grid>
            <Grid item>
              <Switch
                id="question_based"
                name="question_based"
                color="primary"
                checked={formik.values.question_based}
                {...rmKeys(fieldProps(formik, 'question_based'), [
                  'error',
                  'helperText',
                ])}
              />
            </Grid>
            <Grid item>Todas las preguntas</Grid>
          </Grid>
          {show && loadBlocks && (
            <Selector
              id="selectedBlocks"
              name="selectedBlocks"
              questions={uBlocks}
              selectedQuestions={sBlocks}
              isQuestion={false}
              functionRight={setSBlocks}
              functionLeft={setUBlocks}
              {...fieldProps(formik, 'selectedBlocks')}
            />
          )}
          <Box align="center">
            <LoadingButton loading={loadingSubmit} isEdit={isEdit} />
          </Box>
        </LoadView>
      </Form>
    </Grid>
  );
};

ReportTemplateForm.propTypes = {
  isEdit: PropTypes.bool,
  title: PropTypes.string,
};

ReportTemplateForm.defaultProps = {
  isEdit: false,
  title: 'Nuevo Reporte',
};

export default ReportTemplateForm;
