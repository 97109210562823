import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useRouterUtils from 'utils/router/routerUtilsHook';
import './BackButton.scss';

const BackButton = ({ to }) => {
  const { redirect } = useRouterUtils();

  const onClick = () => {
    redirect(to);
  };

  return (
    <div className="BackButton">
      <IconButton onClick={onClick}>
        <ArrowBackIcon />
      </IconButton>
    </div>
  );
};

BackButton.propTypes = {
  to: PropTypes.string.isRequired,
};

export default BackButton;
