import React from 'react';
import PropTypes from 'prop-types';

import {
  Grid,
  Typography,
  Paper,
  Box,
  CircularProgress,
  Divider,
} from '@material-ui/core';

import './DashboardPanel.scss';
import LoadView from 'components/LoadView';

// eslint-disable-next-line react/prop-types
const AuditProgress = ({ value }) => {
  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      className="progress-container"
    >
      <CircularProgress
        size="12rem"
        thickness={4.5}
        className="progress"
        variant="determinate"
        value={value}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
      >
        <Typography
          variant="subtitle2"
          component="p"
          className="percentage"
        >{`${Math.round(value)}%`}</Typography>
        <Typography component="p" className="percentage-text">
          completadas
        </Typography>
      </Box>
    </Box>
  );
};

const DashboardPanel = ({ section, stats, isLoading }) => {
  const getRemaining = () => {
    return Math.max(stats.target - stats.done, 0);
  };

  const getPercentage = () => {
    return Math.min((stats.done / stats.target) * 100, 100);
  };

  return (
    <div className="DashboardPanel">
      <LoadView isLoading={isLoading}>
        <Paper elevation={2} className="dash-paper">
          <Typography className="dash-title" variant="h2">
            {section}
          </Typography>

          <Box mb={2}>
            <Divider variant="middle" />
          </Box>

          <Grid container spacing={3}>
            <Grid
              item
              container
              direction="column"
              spacing={1}
              xs={12}
              md={6}
              className="stat-container"
            >
              <Grid item xs>
                <Typography className="stat-title">
                  Meta de auditorías
                </Typography>
                <Typography className="stat-value">{stats.target}</Typography>
              </Grid>
              <Grid item xs>
                <Typography className="stat-title">Completadas</Typography>
                <Typography className="stat-value">{stats.done}</Typography>
              </Grid>
              <Grid item xs>
                <Typography className="stat-title">Restantes</Typography>
                <Typography className="stat-value">{getRemaining()}</Typography>
              </Grid>
            </Grid>
            <Grid item xs className="progress-column">
              <AuditProgress value={getPercentage()} />
            </Grid>
          </Grid>
        </Paper>
      </LoadView>
    </div>
  );
};

DashboardPanel.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  section: PropTypes.string.isRequired,
  stats: PropTypes.shape({
    target: PropTypes.number.isRequired,
    done: PropTypes.number.isRequired,
  }).isRequired,
};

export default DashboardPanel;
