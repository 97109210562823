import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Box,
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  RadioGroup,
  Radio,
  Select,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Form, LoadView, fieldProps, BackButton } from 'components';
import LoadingButton from 'components/LoadingButton';
import useService from 'utils/services';
import { rmKeys, rutValid } from 'utils';
import useRouterUtils from 'utils/router/routerUtilsHook';
import useTableState from 'utils/table-state';

const buildValidationSchema = (isEdit) => {
  let password = yup
    .string()
    .min(8, 'Al menos 8 caracteres')
    .matches(
      /(?=.*?[a-zA-Z])[a-zA-Z\d]+/,
      'Se necesitan caracteres no numéricos'
    );
  let passwordConfirmation = yup
    .string()
    .oneOf([yup.ref('password'), null], 'La contraseña no coincide');

  if (!isEdit) {
    password = password.required('Ingresa una contraseña');
    passwordConfirmation = passwordConfirmation.required(
      'Debes confirmar la contraseña'
    );
  }
  return yup.object({
    first_name: yup.string().required('Campo obligatorio'),
    username: yup.string('Ingresa tu usuario').required('Ingresa un usuario'),
    last_name: yup.string().required('Campo obligatorio'),
    email: yup
      .string()
      .required('Campo obligatorio')
      .matches(/.+@.+.[.]+./, 'El e-mail no es válido'),
    rut: yup
      .string()
      .required('Campo obligatorio')
      .matches(
        /^[1-9][0-9]*[0-9]*.*-./,
        'RUT inválido. Recuerda escribir sin puntos y con guión'
      )
      .min(9, 'El RUT es muy corto. Recuerda escribir sin puntos y con guión')
      .max(
        10,
        'El RUT es demasiado largo. Recuerda escribir sin puntos y con guión'
      )
      .test('valid', 'RUT Inválido', (value) => rutValid(value)),
    password,
    passwordConfirmation,
  });
};

const UserForm = ({ isEdit, title }) => {
  const [isLoading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { id } = useParams();
  const { formtree, user } = useService();

  const { redirect } = useRouterUtils();
  const { setShouldReload } = useTableState('users');

  const GoBack = () => {
    return <BackButton to="/usuarios" />;
  };

  const onSubmit = async (values) => {
    setLoadingSubmit(true);
    const newValues =
      values._type !== '3' ? { ...values, category_id: -1 } : values;
    await (isEdit ? user.update : user.create)(newValues);
    setLoadingSubmit(false);
    setShouldReload(true);
    redirect('/usuarios');
  };

  const formik = useFormik({
    initialValues: {
      rut: '',
      first_name: '',
      last_name: '',
      username: '',
      is_superuser: false,
      email: '',
      _type: '2',
      category_id: -1,
      password: '',
      passwordConfirmation: '',
    },
    enableReinitialize: true,
    validationSchema: buildValidationSchema(isEdit),
    onSubmit,
  });

  const fetchData = async () => {
    setLoading(true);
    const categoriesData = await formtree.fetchCategoriesActive();
    setCategories(categoriesData);
    if (id) {
      const data = await user.fetchOne({ id });
      const categoryId = `${data.category_id}`;
      const strType = `${data._type}`;
      formik.setValues({
        ...data,
        category_id: categoryId,
        _type: strType,
        password: '',
      });
    }
    setLoading(false);
  };

  useEffect(fetchData, []);

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={4}>
          <Form
            onSubmit={formik.handleSubmit}
            title={title}
            HeaderComponent={GoBack}
          >
            <LoadView isLoading={isLoading}>
              <TextField
                label="RUT"
                id="rut"
                name="rut"
                margin="normal"
                fullWidth
                {...fieldProps(formik, 'rut')}
              />
              <TextField
                label="Nombres"
                id="first_name"
                name="first_name"
                margin="normal"
                fullWidth
                {...fieldProps(formik, 'first_name')}
              />
              <TextField
                label="Apellidos"
                id="last_name"
                name="last_name"
                margin="normal"
                fullWidth
                {...fieldProps(formik, 'last_name')}
              />
              <TextField
                label="Nombre de usuario"
                id="username"
                name="username"
                margin="normal"
                fullWidth
                {...fieldProps(formik, 'username')}
              />
              <TextField
                label="E-mail"
                id="email"
                name="email"
                margin="normal"
                fullWidth
                {...fieldProps(formik, 'email')}
              />
              <FormControl component="fieldset" margin="normal">
                <FormLabel component="legend">Tipo</FormLabel>
                <RadioGroup
                  component="fieldset"
                  id="_type"
                  name="_type"
                  value={`${formik.values._type}`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio color="primary" />}
                    label="Administrador"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio color="primary" />}
                    label="Auditor"
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio color="primary" />}
                    label="Cliente"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel className="labelField" id="category_id">
                  Categoría
                </InputLabel>
                <Select
                  id="category_id"
                  name="category_id"
                  disabled={formik.values._type !== '3'}
                  fullWidth
                  {...rmKeys(fieldProps(formik, 'category_id'), ['helperText'])}
                >
                  {categories.map(({ id: cid, name }) => (
                    <MenuItem key={cid} value={cid}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Contraseña"
                id="password"
                type="password"
                name="password"
                margin="normal"
                fullWidth
                {...fieldProps(formik, 'password')}
              />
              <TextField
                label="Confirmar contraseña"
                id="passwordConfirmation"
                type="password"
                name="passwordConfirmation"
                margin="normal"
                fullWidth
                {...fieldProps(formik, 'passwordConfirmation')}
              />
              <Box align="center">
                <LoadingButton loading={loadingSubmit} isEdit={isEdit} />
              </Box>
            </LoadView>
          </Form>
        </Grid>
      </Grid>
    </>
  );
};

UserForm.propTypes = {
  isEdit: PropTypes.bool,
  title: PropTypes.string,
};

UserForm.defaultProps = {
  isEdit: false,
  title: 'Nueva entidad',
};
export default UserForm;
