import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import './AuditChooser.scss';

export default function AuditChooser() {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className="centerBox"
    >
      <Button className="bigButton" component={Link} to="/auditorias/recientes">
        Últimas auditorías
      </Button>
      <Button className="bigButton" component={Link} to="/auditorias/filtrar">
        Filtro avanzado
      </Button>
    </Grid>
  );
}
