import requests from 'utils/requests';

const fetchTechnicians = async () => {
  const result = await requests.get('/technicians/');
  const data = await result.json();

  const filtered = data.map((obj) => {
    const {
      id,
      rut,
      first_name: firstName,
      last_name: lastName,
      enterprise_name: enterpriseName,
    } = obj;
    return { id, rut, firstName, lastName, enterpriseName };
  });
  return filtered;
};

const fetchTechnician = async (id) => {
  const result = await requests.get(`/technician/${id}/`);
  const tech = await result.json();
  return tech;
};

const postTechnician = async (values) => {
  await requests.post('/technicians/', values);
};

const putTechnician = async (values, id) => {
  await requests.put(`/technician/${id}/`, values);
};

const deleteTechnician = async (id) => {
  await requests.del(`/technician/${id}/`);
};

export default {
  fetchTechnicians,
  fetchTechnician,
  postTechnician,
  putTechnician,
  deleteTechnician,
};
