import { React, useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { DeletePopUp, LoadView } from 'components';
import useNotification from 'utils/notifications/notificationHook';
import useService from 'utils/services';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper,
  Typography,
  Grid,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import './NotificationPanel.scss';

const NotificationPanel = () => {
  const [info, setInfo] = useState([]);
  const [empty, setEmpty] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const { setSuccessMessage, setErrorMessage } = useNotification();
  const { panel } = useService();

  const getNotifications = async () => {
    const data = await panel.fetchNotifications();
    if (data.length) {
      setEmpty(false);
    }
    setInfo(data);
    setLoading(false);
  };
  useEffect(getNotifications, []);

  const deleteNotification = (id) => {
    // Instant update for responsiveness illusion
    const newInfo = info.filter((notification) => notification.id !== id);
    setInfo(newInfo);
    panel.deleteNotification(id);
  };
  const deleteAll = async () => {
    const response = await panel.deleteNotificationAll();
    if (response) {
      setSuccessMessage('Notificaciones Eliminadas');
    } else {
      setErrorMessage('Hubo un error al eliminar las notificaciones');
    }
    getNotifications();
  };

  return (
    <div className="NotificationPanel">
      <Typography className="title">Panel Informativo</Typography>
      <Box className="FormIndex">
        <LoadView isLoading={isLoading}>
          <Paper>
            {!empty && (
              <Box display="flex" justifyContent="flex-end" mr={1}>
                <DeletePopUp
                  id={0}
                  buttonText=""
                  entity="todas las notificaciones"
                  deleteFunction={deleteAll}
                  icon={<DeleteIcon />}
                />
              </Box>
            )}
            <List component="nav">
              {empty ? (
                <ListItem key="0">
                  <ListItemText
                    primary="No hay notificaciones"
                    align="center"
                  />
                </ListItem>
              ) : (
                info.map((notification) => (
                  <Fragment key={notification.id}>
                    <Divider />
                    <ListItem>
                      <Grid container spacing={2}>
                        <Grid item xs>
                          <Box
                            display="flex"
                            alignItems="center"
                            className="textBox"
                          >
                            <ListItemText primary={notification.text} />
                          </Box>
                        </Grid>

                        <Grid item>
                          <Box
                            className="actionContainer"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            py={1}
                          >
                            <Button
                              className="buttonLink"
                              component={Link}
                              to={notification.link}
                              variant="contained"
                            >
                              {notification.buttonText}
                            </Button>
                            <IconButton
                              size="small"
                              color="inherit"
                              onClick={() =>
                                deleteNotification(notification.id)
                              }
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </ListItem>
                  </Fragment>
                ))
              )}
            </List>
          </Paper>
        </LoadView>
      </Box>
    </div>
  );
};

export default NotificationPanel;
