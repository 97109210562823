/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-alert */
import requests from 'utils/requests';

import {
  fixTimestamp,
  changeDate,
  changeTime,
  exportInitialDate,
  exportFinalTimestamp,
} from 'utils';

const toJSON = (response) => response.json();

const parseAudit = (audit) => {
  return {
    id: audit.id,
    date: fixTimestamp(audit.metadata.timestamp_data.initial_timestamp),
    category: audit.metadata.category_data.category_name,
    audit_type: audit.metadata.category_data.audit_type,
    service_type: audit.metadata.category_data.service_type
      ? audit.metadata.category_data.service_type
      : 'No Aplica',
    technician_name: Object.values(audit.metadata.technician_data.technicians)
      .map((tech) => {
        if (tech?.status > 0) {
          return `${tech.technician_name}`;
        }
      })
      .join('\n'),
    technician_rut: Object.values(audit.metadata.technician_data.technicians)
      .map((tech) => {
        if (tech?.status > 0) {
          return tech.technician_rut;
        }
      })
      .join('\n'),
    technician_enterprise: audit.metadata.technician_data.enterprise,
  };
};

const fetchLatestAudits = async () => {
  try {
    const url = '/answers_paginated/?page_size=2000';
    const unparsed = await requests.get(url).then(toJSON);
    return unparsed.data.map(parseAudit);
  } catch (e) {
    window.alert('Hubo un error al cargar las auditorías');
  }
};

const fetchFilteredAudits = async (values) => {
  const unparsed = await requests.post('/answers_filter/', values).then(toJSON);
  return unparsed.map(parseAudit);
};

const fetchAudit = async (id) => {
  let data;
  try {
    const responseAudit = requests.get(`/answer/${id}/`).then(toJSON);
    const questionsAll = requests.get(`/questions_indexed/`).then(toJSON);

    const audit = await responseAudit;
    const mainInfo = {
      id: audit.id,
      auditor: audit.metadata.auditor_data.auditor_name,
      category: audit.metadata.category_data.category_name,
      audit_type: audit.metadata.category_data.audit_type,
      service_type: audit.metadata.category_data.service_type
        ? audit.metadata.category_data.service_type
        : 'No Aplica',
      commune: audit.metadata.location_data.commune,
      technician: Object.values(audit.metadata.technician_data.technicians)
        .map((tech) => {
          if (tech.status) {
            return tech.technician_name;
          }
        })
        .join('\n'),
      technician_rut: Object.values(audit.metadata.technician_data.technicians)
        .map((tech) => {
          if (tech.status) {
            return tech.technician_rut;
          }
        })
        .join('\n'),
      technicians: Object.values(audit.metadata.technician_data.technicians),
      technician_enterprise: audit.metadata.technician_data.enterprise,
      startTime: fixTimestamp(audit.metadata.timestamp_data.initial_timestamp),
      finishTime: fixTimestamp(audit.exit_timestamp),
      task_type: audit.metadata.category_data.task_type,
      task_number: audit.metadata.category_data.task_number,
      address: `\
        ${audit.metadata.location_data.street_name}, \
        ${audit.metadata.location_data.street_number} \
        ${audit.metadata.location_data.dpt_or_office || ''}
        `,
      truck_model: audit.metadata.truck_data.truck_type,
      license_plate: audit.metadata.truck_data.truck_license,
      audit_ok: audit.metadata.extras.audit_done,
      comments_non_ok: audit.metadata.extras.comments_nok || 'No Aplica',
      values: audit.values,
      locationData: audit.metadata.location_data,
    };
    const questions = await questionsAll;
    const answersInfo = Object.values(audit.blocks).map((block) => {
      return {
        name: block.block_name,
        questions: block.questions.map((q) => {
          const question = questions[q];
          return {
            id: q,
            category: question.category,
            question: question.value,
            answer: audit.values[q],
            possible_values: question.options.possible_values,
          };
        }),
      };
    });

    const imageList = [];

    const techs = audit.metadata.technician_data.technicians;
    Object.values(techs).forEach((tech) => {
      const photo = tech.technician_photo;
      if (!photo) return;
      imageList.push({
        text: `Técnico ${tech.technician_name}`,
        url: photo,
      });
    });

    const truckData = audit.metadata.truck_data;
    if (truckData) {
      imageList.push({
        text: `Camioneta ${truckData.truck_type}, ${truckData.truck_license}`,
        url: truckData.truck_photo,
      });
    }
    // Read answers and add them to images!
    answersInfo.forEach((block) => {
      block.questions.forEach((question) => {
        if ([5, 6].includes(question.category)) {
          imageList.push({
            text: question.question,
            url: question.answer,
          });
        }
      });
    });

    // Using safe navigators in case of a malformed audit
    audit.metadata?.additional_photos?.photos?.forEach((url, index) => {
      imageList.push({
        text: `Imagen Adicional ${index + 1}`,
        url,
      });
    });

    data = [mainInfo, answersInfo, imageList];
  } catch (e) {
    window.alert('Hubo un error al cargar la auditoría');
  }
  return data;
};
const fetchAuditForm = async (id) => {
  let values;
  let possibleValues;
  try {
    const rTec = requests.get(`/technicians_indexed/`).then(toJSON);
    const rAud = requests.get(`/answer/${id}/`).then(toJSON);
    const rCom = requests.get(`/communes/`).then(toJSON);
    const rEnt = requests.get(`/enterprises/`).then(toJSON);
    const rTas = requests.get(`/task_types/`).then(toJSON);
    const rNon = requests.get(`/non_ok_options/`).then(toJSON);
    const rVeh = requests.get(`/vehicles/`).then(toJSON);

    const [audit, technicians] = await Promise.all([rAud, rTec]);

    values = {
      number_of_technicians:
        audit.metadata.technician_data.number_of_technicians,
      technician_1:
        audit.metadata.technician_data.technicians.technician_1.technician_id,
      technician_2:
        audit.metadata.technician_data.technicians.technician_2.technician_id,
      technician_enterprise: audit.metadata.technician_data.enterprise,
      commune: audit.commune_id,
      street_name: audit.metadata.location_data.street_name,
      street_number: audit.metadata.location_data.street_number,
      apt_or_office: audit.metadata.location_data.dpt_or_office,
      task_type: audit.metadata.category_data.task_type,
      task_number: audit.metadata.category_data.task_number,
      truck_model: audit.metadata.truck_data.truck_type,
      license_plate: audit.metadata.truck_data.truck_license,
      date: changeDate(audit.metadata.timestamp_data.initial_date),
      initial_time: changeTime(audit.metadata.timestamp_data.initial_time),
      final_time: changeTime(
        audit.metadata.timestamp_data.final_timestamp.split(' ')[1]
      ),
      audit_ok: audit.metadata.extras.audit_done,
      comments_non_ok: audit.metadata.extras.comments_nok,
    };
    const [communes, enterprises, taskTypes, nonOk, vehicles] =
      await Promise.all([rCom, rEnt, rTas, rNon, rVeh]);
    possibleValues = {
      technicians: Object.values(technicians),
      technician_enterprise: enterprises,
      communes,
      task_types: taskTypes[audit.category_id],
      non_ok_options: nonOk,
      vehicles,
    };
  } catch (e) {
    window.alert('Hubo un error al cargar la información de la auditoría');
  }
  return { values, possibleValues };
};

const editAudit = async (id, values) => {
  const commUrl = `/commune_location/${values.commune}/`;

  const audit = await requests.get(`/answer/${id}/`).then(toJSON);
  const technicians = await requests.get(`/technicians_indexed/`).then(toJSON);
  const com = await requests.get(commUrl).then(toJSON);

  const body = {
    category_id: audit.category_id,
    audit_type_id: audit.audit_type_id,
    service_type_id: audit.service_type_id,
    auditor_id: audit.auditor_id,
    commune_id: values.commune,
    exit_timestamp: audit.exit_timestamp,
    values: audit.values,
    blocks: audit.blocks,
    metadata: {
      timestamp_data: {
        final_timestamp: exportFinalTimestamp(values.date, values.final_time),
        initial_timestamp: audit.metadata.timestamp_data.initial_timestamp,
        initial_time: `${values.initial_time}:00`,
        initial_date: exportInitialDate(values.date),
      },
      auditor_data: {
        auditor_name: audit.metadata.auditor_data.auditor_name,
        auditor_rut: audit.metadata.auditor_data.auditor_rut,
        auditor_email: audit.metadata.auditor_data.auditor_email,
        auditor_phone: audit.metadata.auditor_data.auditor_phone,
      },
      technician_data: {
        number_of_technicians:
          audit.metadata.technician_data.number_of_technicians,
        enterprise: values.technician_enterprise,
        team_id: audit.metadata.technician_data.team_id,
        id_group_team: audit.metadata.technician_data.id_group_team,
        technicians: {
          technician_1: {
            technician_id: values.technician_1,
            status:
              audit.metadata.technician_data.technicians.technician_1.status,
            technician_name: `\
                ${technicians[values.technician_1].first_name} \
                ${technicians[values.technician_1].last_name}`,
            technician_rut: technicians[values.technician_1].rut,
            technician_phone: technicians[values.technician_1].phone,
            technician_external_id:
              audit.metadata.technician_data.technicians.technician_1
                .technician_external_id,
            technician_category_id:
              technicians[values.technician_1].category_id.toString(),
            technician_enterprise_id:
              technicians[values.technician_1].enterprise_id.toString(),
            technician_email: technicians[values.technician_1].email,
            technician_team_id:
              technicians[values.technician_1].team_group_code.toString(),
            technician_id_group_team:
              technicians[values.technician_1].team_group_id.toString(),
            technician_first_name: technicians[values.technician_1].first_name,
            technician_last_name: technicians[values.technician_1].last_name,
            technician_photo:
              audit.metadata.technician_data.technicians.technician_1
                .technician_photo,
            technician_name_redes:
              audit.metadata.technician_data.technicians.technician_1
                .technician_name_redes,
          },
          technician_2: {
            technician_id: values.technician_2,
            status:
              audit.metadata.technician_data.technicians.technician_2.status,
            technician_name: values.technician_2
              ? `${technicians[values.technician_2].first_name} \
                ${technicians[values.technician_2].last_name}`
              : '',
            technician_rut: values.technician_2
              ? technicians[values.technician_2].rut
              : '',
            technician_phone: values.technician_2
              ? technicians[values.technician_2].phone
              : '',
            technician_external_id:
              audit.metadata.technician_data.technicians.technician_2
                .technician_external_id,
            technician_category_id: values.technician_2
              ? technicians[values.technician_2].category_id.toString()
              : '',
            technician_enterprise_id: values.technician_2
              ? technicians[values.technician_2].enterprise_id.toString()
              : '',
            technician_email: values.technician_2
              ? technicians[values.technician_2].email
              : '',
            technician_team_id: values.technician_2
              ? technicians[values.technician_2].team_group_code.toString()
              : '',
            technician_id_group_team: values.technician_2
              ? technicians[values.technician_2].team_group_id.toString()
              : '',
            technician_first_name: values.technician_2
              ? technicians[values.technician_2].first_name
              : '',
            technician_last_name: values.technician_2
              ? technicians[values.technician_2].last_name
              : '',
            technician_photo:
              audit.metadata.technician_data.technicians.technician_2
                .technician_photo,
            technician_name_redes:
              audit.metadata.technician_data.technicians.technician_2
                .technician_name_redes,
          },
        },
      },
      location_data: {
        initial_latitude: audit.metadata.location_data.initial_latitude,
        initial_longitude: audit.metadata.location_data.initial_longitude,
        final_latitude: audit.metadata.location_data.final_latitude,
        final_longitude: audit.metadata.location_data.final_longitude,
        dpt_or_office: values.apt_or_office,
        street_number: values.street_number,
        street_name: values.street_name,
        zone_att: com.region_zona_att.toString(),
        zone_crm: com.region_crm.toString(),
        commune: com.commune_name,
        province: com.province_name,
        region: com.region_name,
      },
      category_data: {
        task_type: values.task_type,
        category_name: audit.metadata.category_data.category_name,
        audit_type: audit.metadata.category_data.audit_type,
        service_type: audit.metadata.category_data.service_type,
        task_number: values.task_number,
      },
      truck_data: {
        truck_type: values.truck_model,
        truck_license: values.license_plate,
        truck_photo: audit.metadata.truck_data.truck_photo,
      },
      extras: {
        audit_done: values.audit_ok,
        comments_nok: values.comments_non_ok,
      },
      additional_photos: audit.metadata.additional_photos,
    },
  };
  const response = await requests.put(`/answer/${id}/`, body);
  return response.ok;
};

const deleteAudit = async (id) => {
  const response = await requests.del(`/answer/${id}/`);
  return response.ok;
};

const patchAuditAnswers = async (id, values) => {
  return requests.patch(`/answer/${id}/`, values);
};

export default {
  fetchLatestAudits,
  fetchFilteredAudits,
  fetchAudit,
  fetchAuditForm,
  editAudit,
  deleteAudit,
  patchAuditAnswers,
};
