/* eslint-disable no-alert */
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { ResponsiveTableGrid } from 'components';
import './FormIndex.scss';
import useService from 'utils/services';
import useRouterUtils from 'utils/router/routerUtilsHook';

const FormIndex = () => {
  const { form } = useService();
  const { redirect } = useRouterUtils();

  const headers = [
    {
      field: 'name',
      headerName: 'Nombre',
      width: 200,
    },
    {
      field: 'category',
      headerName: 'Categoría',
      flex: 1,
    },
    {
      field: 'audit_type',
      headerName: 'Tipo de Auditoría',
    },
    {
      field: 'service_type',
      headerName: 'Tipo de Servicio',
      flex: 1,
    },
    {
      field: 'active',
      headerName: 'Activo Aplicación Móvil',
      flex: 1,
    },
  ];

  return (
    <div className="FormIndex">
      <h2 className="title">Formularios</h2>
      <Button
        className="new-entity-btn"
        component={Link}
        to="/formularios/nuevo"
        variant="contained"
      >
        Nuevo Formulario
      </Button>
      <ResponsiveTableGrid
        fetchAllFunc={form.fetchForms}
        headers={headers}
        tableName="forms"
        canView
        canEdit
        canDelete
        deleteNotification={() => `Formulario Eliminado`}
        deleteEntityName={(row) => ` el formulario ${row.name}`}
        onView={(row) => redirect(`/formularios/${row.id}`)}
        onEdit={(row) => redirect(`/formularios/editar/${row.id}`)}
        onDelete={(row) => form.deleteForm(row.id)}
      />
    </div>
  );
};

export default FormIndex;
