import React, { useEffect, useState } from 'react';
import useNotification from 'utils/notifications/notificationHook';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  TextField,
  InputLabel,
  FormControl,
  Select,
  Grid,
  MenuItem,
  Box,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import './AuditEditForm.scss';
import LoadingButton from 'components/LoadingButton';
import { useParams } from 'react-router-dom';
import useService from 'utils/services';
import { rmKeys } from 'utils';
import { Form, fieldProps, BackButton } from 'components';
import useRouterUtils from 'utils/router/routerUtilsHook';
import useTableState from 'utils/table-state';

const validationSchema = yup.object({
  number_of_technicians: yup.number(),
  technician_1: yup.number(),
  technician_2: yup.number(),
  technician_enterprise: yup.string(),
  commune: yup.number(),
  street_name: yup.string(),
  street_number: yup.string(),
  apt_or_office: yup.string(),
  task_type: yup.string(),
  task_number: yup.string(),
  truck_model: yup.string(),
  license_plate: yup.string(),
  date: yup.string(),
  initial_time: yup.string(),
  final_time: yup.string(),
  audit_ok: yup.string(),
  comments_non_ok: yup.string(),
});

export default function EditAuditForm() {
  const [isLoading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { setSuccessMessage, setErrorMessage } = useNotification();
  const [pValues, setPossibleValues] = useState({});
  const { redirect } = useRouterUtils();
  const { setShouldReload } = useTableState('audits');
  const { id } = useParams();
  const { audit } = useService();

  const onSubmit = async (values) => {
    setLoadingSubmit(true);
    const response = await audit.editAudit(id, values);
    if (response) {
      setSuccessMessage('Auditoría Modificada');
    } else {
      setErrorMessage('Hubo un error al modificar la auditoría');
    }
    setLoadingSubmit(false);
    setShouldReload(true);
    redirect(`/auditorias/${id}`);
  };

  const formik = useFormik({
    initialValues: {
      number_of_technicians: 1,
      technician_1: 0,
      technician_2: 0,
      technician_enterprise: '',
      commune: 0,
      street_name: '',
      street_number: '',
      apt_or_office: '',
      task_type: '',
      task_number: '',
      truck_model: '',
      license_plate: '',
      date: '',
      initial_time: '',
      final_time: '',
      audit_ok: '',
      comments_non_ok: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  const fetchData = async () => {
    const { values, possibleValues } = await audit.fetchAuditForm(id);
    formik.setValues(values);
    setPossibleValues(possibleValues);
    setLoading(false);
  };

  const GoBack = () => <BackButton to={`/auditorias/${id}`} />;

  useEffect(fetchData, []);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className="AuditEditForm"
    >
      <Grid item xs={12} lg={4}>
        {isLoading && (
          <Box align="center">
            <CircularProgress />
          </Box>
        )}
        {!isLoading && (
          <Form
            onSubmit={formik.handleSubmit}
            title="Editar Auditoría"
            HeaderComponent={GoBack}
          >
            <FormControl fullWidth margin="normal">
              <InputLabel className="labelField" id="technician">
                Técnico
              </InputLabel>
              <Select
                id="technician_1"
                name="technician_1"
                className="formField"
                fullWidth
                {...rmKeys(fieldProps(formik, 'technician_1'), ['helperText'])}
              >
                {pValues.technicians
                  .sort((a, b) => {
                    if (a.last_name > b.last_name) return 1;
                    if (a.last_name < b.last_name) return -1;
                    return 0;
                  })
                  .map((tech) => (
                    <MenuItem key={tech.id} value={tech.id}>
                      {tech.last_name} {tech.first_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            {formik.values.number_of_technicians > 1 && (
              <>
                <FormControl fullWidth margin="normal">
                  <InputLabel className="labelField" id="technician">
                    Técnico 2
                  </InputLabel>
                  <Select
                    id="technician_2"
                    name="technician_2"
                    className="formField"
                    {...rmKeys(fieldProps(formik, 'technician_2'), [
                      'helperText',
                    ])}
                  >
                    {pValues.technicians
                      .sort((a, b) => {
                        if (a.last_name > b.last_name) return 1;
                        if (a.last_name < b.last_name) return -1;
                        return 0;
                      })
                      .map((tech) => (
                        <MenuItem key={tech.id} value={tech.id}>
                          {tech.last_name} {tech.first_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </>
            )}
            <FormControl fullWidth margin="normal">
              <InputLabel className="labelField" id="technician_enterprise">
                Empresa Técnico(s)
              </InputLabel>
              <Select
                id="technician_enterprise"
                name="technician_enterprise"
                className="formField"
                {...rmKeys(fieldProps(formik, 'technician_enterprise'), [
                  'helperText',
                ])}
              >
                {pValues.technician_enterprise.map((enterprise) => (
                  <MenuItem key={enterprise.id} value={enterprise.name}>
                    {enterprise.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel className="labelField" id="commune">
                Comuna
              </InputLabel>
              <Select
                className="formField"
                {...rmKeys(fieldProps(formik, 'commune'), ['helperText'])}
              >
                {pValues.communes.map((commune) => (
                  <MenuItem key={commune.id} value={commune.id}>
                    {commune.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Nombre Calle"
              id="street_name"
              name="street_name"
              fullWidth
              margin="normal"
              {...fieldProps(formik, 'street_name')}
            />
            <TextField
              label="Número"
              id="street_number"
              name="street_number"
              fullWidth
              margin="normal"
              {...fieldProps(formik, 'street_number')}
            />
            <TextField
              label="Departamento u Oficina"
              id="apt_or_office"
              name="apt_or_office"
              fullWidth
              margin="normal"
              {...fieldProps(formik, 'apt_or_office')}
            />
            <TextField
              label="Fecha de Realización"
              name="date"
              id="date"
              type="date"
              fullWidth
              margin="normal"
              {...fieldProps(formik, 'date')}
            />

            <TextField
              label="Hora de Inicio"
              name="initial_time"
              id="initial_time"
              type="time"
              margin="normal"
              fullWidth
              {...fieldProps(formik, 'initial_time')}
            />
            <TextField
              label="Hora de Término"
              name="final_time"
              id="final_time"
              type="time"
              margin="normal"
              fullWidth
              {...fieldProps(formik, 'final_time')}
            />

            <FormControl fullWidth margin="normal">
              <InputLabel className="labelField" id="task_type">
                Tipo de Tarea
              </InputLabel>
              <Select
                className="formField"
                {...rmKeys(fieldProps(formik, 'task_type'), ['helperText'])}
              >
                {pValues.task_types.map((task) => (
                  <MenuItem key={task} value={task}>
                    {task}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Número de Tarea"
              name="task_number"
              id="task_number"
              type="text"
              margin="normal"
              fullWidth
              {...fieldProps(formik, 'task_number')}
            />

            <FormControl fullWidth margin="normal">
              <InputLabel className="labelField" id="truck_model">
                Modelo Camioneta
              </InputLabel>
              <Select
                className="formField"
                {...rmKeys(fieldProps(formik, 'truck_model'), ['helperText'])}
              >
                {pValues.vehicles.map((vehicle) => (
                  <MenuItem key={vehicle} value={vehicle}>
                    {vehicle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Patente"
              id="license_plate"
              name="license_plate"
              fullWidth
              margin="normal"
              {...fieldProps(formik, 'license_plate')}
            />

            <FormControl fullWidth margin="normal">
              <InputLabel className="labelField" id="audit_ok">
                ¿Auditoría OK?
              </InputLabel>
              <Select
                className="formField"
                {...rmKeys(fieldProps(formik, 'audit_ok'), ['helperText'])}
              >
                <MenuItem key={0} value="OK">
                  OK
                </MenuItem>
                <MenuItem key={1} value="NOK">
                  NO OK
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel className="labelField" id="comments_non_ok">
                Comentarios Auditoría NO OK
              </InputLabel>
              <Select
                className="formField"
                disabled={formik.values.audit_ok === 'OK'}
                {...rmKeys(fieldProps(formik, 'comments_non_ok'), [
                  'helperText',
                ])}
              >
                {pValues.non_ok_options.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box align="center">
              <LoadingButton loading={loadingSubmit} isEdit />
            </Box>
          </Form>
        )}
      </Grid>
    </Grid>
  );
}
