import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TextField, Box, Grid } from '@material-ui/core/';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useRouterUtils from 'utils/router/routerUtilsHook';
import { BackButton } from 'components';
import useTableState from 'utils/table-state';
import useService from 'utils/services';
import { Form, fieldProps } from 'components/Form';
import LoadingButton from 'components/LoadingButton';

const validationSchema = yup.object({
  name: yup.string().required('Este campo es obligatorio'),
  region_ordinal: yup.string().required('Este campo es obligatorio'),
});

const RegionsForm = ({ isEdit, title }) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { redirect } = useRouterUtils();
  const { id } = useParams();
  const { region } = useService();
  const { setShouldReload } = useTableState('regions');

  const onSubmit = async (values) => {
    setLoadingSubmit(true);
    if (isEdit) await region.patchRegion(values, id);
    else await region.postRegion(values);
    setLoadingSubmit(false);
    setShouldReload(true);
    redirect('/regiones');
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      region_ordinal: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  const fetchData = async () => {
    if (isEdit && id) {
      const data = await region.fetchRegion(id);
      await formik.setValues({
        name: data.name,
        region_ordinal: data.region_ordinal,
      });
    }
  };

  useEffect(fetchData, []);

  const GoBack = () => <BackButton to="/regiones" />;

  return (
    <Grid container justifyContent="center" className="RegionsForm">
      <Grid item xs={12} lg={4}>
        <Form
          onSubmit={formik.handleSubmit}
          title={title}
          HeaderComponent={GoBack}
        >
          <TextField
            fullWidth
            label="Nombre"
            margin="normal"
            id="name"
            name="name"
            className="name"
            {...fieldProps(formik, 'name')}
          />

          <TextField
            fullWidth
            label="Número de región"
            margin="normal"
            id="region_ordinal"
            name="region_ordinal"
            className="region_ordinal"
            {...fieldProps(formik, 'region_ordinal')}
          />

          <Box align="center">
            <LoadingButton loading={loadingSubmit} isEdit={isEdit} />
          </Box>
        </Form>
      </Grid>
    </Grid>
  );
};

RegionsForm.propTypes = {
  isEdit: PropTypes.bool,
  title: PropTypes.string,
};

RegionsForm.defaultProps = {
  isEdit: false,
  title: 'Nueva provincia',
};

export default RegionsForm;
