import React, { useState } from 'react';
import { useSessionContext } from 'utils/session/sessionContext';
import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  Select,
  Radio,
  RadioGroup,
  MenuItem,
} from '@material-ui/core';
import './AnsweredQuestion.scss';

const AnsweredQuestion = ({
  id,
  number,
  category,
  question,
  answer,
  show,
  saveAnswer,
  possibleValues,
  edit,
}) => {
  const [display, setDisplay] = useState('notdisplayed');
  const { currentUser } = useSessionContext();
  const [save, setSave] = useState('save');
  const [editMode, setEditMode] = useState(false);
  const showButton = (e) => {
    if (!editMode) {
      e.preventDefault();
      setDisplay('displayed');
    }
  };
  const hideButton = (e) => {
    e.preventDefault();
    setDisplay('notdisplayed');
  };
  const handleClickEdit = (e) => {
    e.preventDefault();
    setEditMode(true);
    setDisplay('notdisplayed');
    setSave('save');
  };
  const handleClickSave = (e) => {
    e.preventDefault();
    setEditMode(false);
    setSave('notdisplayed');
    edit(true);
  };

  if (show) {
    return (
      <div>
        <div
          className="AnsweredQuestion"
          onMouseEnter={(e) => showButton(e)}
          onMouseLeave={(e) => hideButton(e)}
        >
          <div className="boxContent">{`${number}. ${question}`}</div>
          {currentUser.isAdmin && (
            <Button className={display} onClick={(e) => handleClickEdit(e)}>
              Editar
            </Button>
          )}
          <Answer
            id={id}
            category={category}
            answer={answer}
            editMode={editMode}
            unable={false}
            saveAnswer={saveAnswer}
            possibleValues={possibleValues}
          />
          {editMode && currentUser.isAdmin && (
            <Button className={save} onClick={(e) => handleClickSave(e)}>
              Guardar Respuesta
            </Button>
          )}
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="AnsweredQuestion">
        <div className="boxContent">{`${number}. ${question}`}</div>
        <Answer
          id={id}
          category={category}
          answer={answer}
          editMode={editMode}
          unable
          saveAnswer={saveAnswer}
          possibleValues={possibleValues}
        />
      </div>
    </div>
  );
};

const Answer = ({
  id,
  category,
  answer,
  editMode,
  unable,
  saveAnswer,
  possibleValues,
}) => {
  const [value, setValue] = useState(answer);
  const handleChange = (event) => {
    if (editMode) {
      event.preventDefault();
      setValue(event.target.value);
      saveAnswer(id, event.target.value);
    } else {
      setValue(answer);
    }
  };
  if (category === 0) {
    return (
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="respuestas"
          name="respuestas"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            value={unable ? 'disabled' : 'SI'}
            control={<Radio color="primary" />}
            label="Si"
          />
          <FormControlLabel
            value={unable ? 'disabled' : 'NO'}
            control={<Radio color="primary" />}
            label="No"
          />
          <FormControlLabel
            value={unable ? 'disabled' : 'No Aplica'}
            control={<Radio color="primary" />}
            label="No Aplica"
          />
          <FormControlLabel
            value={unable ? 'disabled' : ''}
            control={<Radio color="primary" />}
            label="No Observado"
          />
        </RadioGroup>
      </FormControl>
    );
  }
  if (category === 4) {
    return (
      <TextField
        className="textanswer"
        id="standard-multiline-flexible"
        multiline
        maxRows={6}
        value={unable ? '' : value}
        onChange={handleChange}
      />
    );
  }
  if (category === 2 || category === 3) {
    return (
      <FormControl className="optionsDisplay">
        <Select
          id="multi-options"
          fullWidth
          value={value}
          onChange={handleChange}
        >
          {possibleValues.map((option) => (
            <MenuItem key={option} value={option} disabled={unable}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
  if (category === 1) {
    return (
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="respuestas"
          name="respuestas"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            value={unable ? 'disabled' : 'SI'}
            control={<Radio color="primary" />}
            label="Si"
          />
          <FormControlLabel
            value={unable ? 'disabled' : 'NO'}
            control={<Radio color="primary" />}
            label="No"
          />
          <FormControlLabel
            value={unable ? 'disabled' : 'No Aplica'}
            control={<Radio color="primary" />}
            label="No Aplica"
          />
        </RadioGroup>
      </FormControl>
    );
  }
  if (unable) {
    return <div className="answerMultiple"> </div>;
  }
  return <div className="answerMultiple" />;
};

AnsweredQuestion.propTypes = {
  id: PropTypes.number.isRequired,
  number: PropTypes.number.isRequired,
  category: PropTypes.number.isRequired,
  question: PropTypes.string.isRequired,
  answer: PropTypes.string,
  show: PropTypes.bool.isRequired,
  saveAnswer: PropTypes.func,
  possibleValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  edit: PropTypes.func,
};
AnsweredQuestion.defaultProps = {
  saveAnswer: null,
  edit: null,
  answer: '',
};
Answer.propTypes = {
  id: PropTypes.number.isRequired,
  category: PropTypes.number.isRequired,
  editMode: PropTypes.bool.isRequired,
  answer: PropTypes.string,
  unable: PropTypes.bool.isRequired,
  saveAnswer: PropTypes.func,
  possibleValues: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Answer.defaultProps = {
  saveAnswer: null,
  answer: '',
};

export default AnsweredQuestion;
