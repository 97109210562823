import requests from 'utils/requests';

const fetchTaskTypes = async () => {
  const result = await requests.get('/task_types/');
  return result.json();
};

const postTaskTypes = async (taskTypes) => {
  await requests.post('/task_types/', taskTypes);
};

export default {
  fetchTaskTypes,
  postTaskTypes,
};
