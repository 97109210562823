import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getS3ImageFromUrlSafe } from 'utils/aws/s3Utils';

export default function SecurePhoto({ src, alt }) {
  const [imageData, setImageData] = useState('');

  useEffect(async () => {
    const b64data = await getS3ImageFromUrlSafe(src);
    setImageData(b64data);
  }, []);

  return <img src={`data:image/jpeg;base64,${imageData}`} alt={alt} />;
}

SecurePhoto.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};
