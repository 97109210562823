/* eslint-disable no-alert */
import requests from 'utils/requests';

const fetchAll = async (raw = false) => {
  const reports = await (await requests.get('/reports/')).json();
  if (raw) return Object.values(reports);
  const categories = await (await requests.get('/categories/')).json();
  const categoryNames = {};
  categories.forEach(({ id, name }) => {
    categoryNames[id] = name;
  });
  const cleanedReports = Object.values(reports).map((report) => {
    const { id, name, category, for_api: forApi } = report;
    const niceCategory = categoryNames[category];
    const niceForApi = forApi ? 'Sí' : 'No';
    return { id, name, niceCategory, niceForApi };
  });
  return cleanedReports;
};

const fetchByUserCategory = async () => {
  const rawReports = await requests.get('/reports_by_user_category/');
  const reports = await rawReports.json();
  return reports;
};

const fetchByCategory = async (id) => {
  const rawReports = await requests.get(`/reports_by_category/${id}/`);
  const reports = await rawReports.json();
  return reports;
};

const fetchForAPI = async () => {
  const rawReports = await requests.get('/report_for_api/');
  const reports = await rawReports.json();
  return reports;
};

const fetchOne = async (id) => {
  const rawReport = await requests.get(`/report/${id}/`);
  const report = await rawReport.json();
  return report;
};

const createTemplate = async (values) => {
  return requests.post('/reports/', values);
};

const editTemplate = async (id, values) => {
  return requests.put(`/report/${id}/`, values);
};

const generate = async (id, values) => {
  return requests.post(`/generate_report/${id}/`, values);
};

const generateClientReport = async (values) => {
  return requests.put(`/generate_report_client_side/`, values);
};

const update = async (id, values) => {
  return requests.put(`/report/${id}/`, values);
};

const updateForAPI = async (id) => {
  let response;
  if (`${id}` !== `${-1}`) {
    response = await requests.patch(`/report/${id}/`, {
      for_api: true,
    });
  } else {
    const currentForAPI = Object.values(await fetchForAPI())[0];
    response = await requests.patch(`/report/${currentForAPI.id}/`, {
      for_api: false,
    });
  }
  return response;
};

const destroy = async (id) => {
  return requests.del(`/report/${id}/`);
};

export default {
  createTemplate,
  destroy,
  editTemplate,
  fetchAll,
  fetchByUserCategory,
  fetchByCategory,
  fetchForAPI,
  fetchOne,
  generate,
  generateClientReport,
  update,
  updateForAPI,
};
