/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import Button from '@material-ui/core/Button';
import DeletePopUp from 'components/DeletePopUp';
import TablePaginationActions from './TablePaginationActions';
import './TableWithPagination.scss';

const TableWithPagination = ({
  headers,
  data,
  linkEntity,
  deleteFunction,
  entity,
  CustomDelete,
  showView,
  showEdit,
  showDelete,
  showDownload,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // TODO: Use a props showView, showEdit, showDelete!
  /* Entities that make use of X action button */
  const usesView = [
    '/auditorias/',
    '/formularios/',
    '/preguntas/',
    '/tecnicos/',
  ];
  const usesEdit = [
    '/comunas/',
    '/tecnicos/',
    '/usuarios/',
    '/preguntas/',
    '/empresas/',
    '/equipos/',
    '/provincias/',
    '/categorias/',
  ];
  const usesDelete = [
    '/comunas/',
    '/tecnicos/',
    '/usuarios/',
    '/preguntas/',
    '/empresas/',
    '/equipos/',
    '/provincias/',
    '/categorias/',
  ];

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <TableContainer className="TableWithPagination" component={Paper}>
        <Table className="table" size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header} className="tableHead" size="small">
                  {header}
                </TableCell>
              ))}
              <TableCell className="tableHead">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="body">
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((row) => (
              <TableRow key={row.id} hover>
                {Object.entries(row).map(([key, value]) => {
                  if (
                    [
                      'id',
                      'region_zona_att',
                      'region_crm',
                      'province_id',
                      'region_id',
                    ].includes(key)
                  ) {
                    return null;
                  }
                  return (
                    <TableCell key={key + value} className="body" width="10%">
                      {value}
                    </TableCell>
                  );
                })}
                <TableCell className="body" width="5%">
                  <div className="actionButtons">
                    {(showView || usesView.includes(linkEntity)) && (
                      <Button
                        className="buttonView"
                        component={Link}
                        to={`${linkEntity + row.id}`}
                      >
                        Ver
                      </Button>
                    )}
                    {(showEdit || usesEdit.includes(linkEntity)) && (
                      <Button
                        className="buttonEdit"
                        component={Link}
                        to={`${linkEntity}editar/${row.id}`}
                      >
                        Editar
                      </Button>
                    )}
                    {showDownload && (
                      <Button
                        className="buttonView"
                        component={Link}
                        to={`${linkEntity}${row.id}`}
                      >
                        Descargar
                      </Button>
                    )}
                    {CustomDelete ? (
                      <CustomDelete id={row.id} />
                    ) : (
                      (showDelete || usesDelete.includes(linkEntity)) && (
                        <DeletePopUp
                          id={parseInt(row.id, 10)}
                          className="buttonDelete"
                          buttonText="Eliminar"
                          variant="contained"
                          deleteFunction={deleteFunction}
                          redirectPath={linkEntity}
                          entity={entity}
                        />
                      )
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && <TableRow />}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 40, { label: 'All', value: -1 }]}
                colSpan={3}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};

TableWithPagination.propTypes = {
  headers: PropTypes.node.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  linkEntity: PropTypes.string.isRequired,
  deleteFunction: PropTypes.func,
  entity: PropTypes.string,
  CustomDelete: PropTypes.elementType,
  showView: PropTypes.bool,
  showEdit: PropTypes.bool,
  showDelete: PropTypes.bool,
  showDownload: PropTypes.bool,
};

TableWithPagination.defaultProps = {
  entity: 'esta entidad',
  deleteFunction: () => null,
  CustomDelete: null,
  showView: false,
  showEdit: false,
  showDelete: false,
  showDownload: false,
};

export default TableWithPagination;
