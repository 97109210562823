import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';

import QuestionForm from '../QuestionForm';

/* eslint-disable-next-line react/prop-types */
const CustomButton = ({ id }) => {
  return (
    <Box align="center">
      <Link to={`/preguntas/editar/${id}`}>
        <Button className="button-submit">Editar</Button>
      </Link>
    </Box>
  );
};

const QuestionShow = () => {
  return (
    <QuestionForm isShow title="Ver Pregunta" CustomButton={CustomButton} />
  );
};

export default QuestionShow;
