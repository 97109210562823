import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import useService from 'utils/services';
import useTableState from 'utils/table-state';

const DeletePlaceDialog = ({
  open,
  setOpen,
  row,
  entityName,
  tableName,
  onDelete,
}) => {
  const [deletable, setDeletable] = useState(true);
  const [dependencies, setDependencies] = useState(0);
  const { province, region } = useService();
  const { setShouldReload } = useTableState(tableName);

  const handleClose = () => {
    setOpen(false);
  };

  // Had to do the delete this way because otherwise returns undefined
  const confirmDelete = async () => {
    const deleteFunc =
      tableName === 'provinces' ? province.deleteProvince : region.deleteRegion;
    const result = await deleteFunc(row.id);
    if (result.deleted) {
      setOpen(false);
      setShouldReload(true);
      if (onDelete) onDelete();
    } else {
      setDeletable(false);
      setDependencies(
        tableName === 'provinces'
          ? result.communes.length
          : result.provinces.length
      );
    }
  };

  useEffect(() => {
    setDeletable(true);
    setDependencies(0);
  }, [row, entityName]);

  return (
    <Box align="center" className="DeletePlaceDialog">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {deletable && dependencies === 0 ? (
          <>
            <DialogTitle id="alert-dialog-title">
              ¿Está seguro que quiere eliminar {entityName(row)}?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Luego de confirmar, esta acción no se puede deshacer.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancelar
              </Button>
              <Button onClick={confirmDelete} color="primary" autoFocus>
                Confirmar
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle id="alert-dialog-title">
              No se puede eliminar {entityName(row)} por tener {dependencies}{' '}
              dependencias.
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Elimina las dependencias y vuelve a probar otra vez.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cerrar
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
};

DeletePlaceDialog.propTypes = {
  entityName: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  row: PropTypes.object.isRequired,
  tableName: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DeletePlaceDialog;
