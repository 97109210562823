import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { TextField, MenuItem, Box, Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Form, fieldProps } from 'components/Form';
import useService from 'utils/services';
import OptionList from 'components/OptionList';
import LoadingButton from 'components/LoadingButton';
import useTableState from 'utils/table-state';
import useRouterUtils from 'utils/router/routerUtilsHook';
import { BackButton } from 'components';

import { invertObject } from 'utils';

import './QuestionForm.scss';
import useNotification from 'utils/notifications/notificationHook';

const mapping = {
  'four-alt': 0,
  'three-alt': 1,
  list: 3,
  text: 4,
  'img-req': 5,
  'img-opt': 6,
};

const optionsAndValues = [
  { value: 'four-alt', option: 'Si - No - N/A- N/O' },
  { value: 'three-alt', option: 'Si - No - N/A' },
  { value: 'list', option: 'Lista de Opciones' },
  { value: 'text', option: 'Texto Libre' },
  { value: 'img-req', option: 'Imagen Obligatoria' },
  { value: 'img-opt', option: 'Imagen Opcional' },
].sort((a, b) => {
  if (a.option > b.option) return 1;
  if (a.option < b.option) return -1;
  return 0;
});

// Validation using yup
const validationSchema = yup.object({
  title: yup
    .string()
    .required('Campo obligatorio')
    .min(3, 'La pregunta es demasiado corta'),
  subFormValid: yup.boolean().oneOf([true]),
  questionType: yup.string().required('Debes Elegir una opción'),
  category: yup.number().required('Debes Elegir una opción'),
});

// TODO: Generalize and extract edit and show logic into their own components.
const QuestionForm = ({ title, submitText, isEdit, isShow, CustomButton }) => {
  const [subFormValues, setSubFormValues] = useState({});
  const [categories, setCategories] = useState([]);
  const [currQuestion, setCurrQuestion] = useState();
  const [canEditType, setCanEditType] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { id } = useParams();
  const { question, formtree, category } = useService();
  const { redirect } = useRouterUtils();
  const { setShouldReload } = useTableState('questions');
  const { setSuccessMessage, setErrorMessage } = useNotification();

  const onSubmit = async (values) => {
    setLoadingSubmit(true);
    const newQuestion = {
      category: mapping[values.questionType],
      options: {
        possible_values: [],
      },
      value: values.title,
      status: true,
      category_id: values.category,
    };

    let opts;
    switch (values.questionType) {
      case 'list':
        opts = subFormValues.options.map((el) => el.optionValue);
        break;

      // TODO: Backend should handle this cases without redundant possible values
      case 'three-alt':
        opts = ['SI', 'NO', 'N/A'];
        break;

      case 'four-alt':
        opts = ['SI', 'NO', 'N/A', 'N/O'];
        break;

      default:
        opts = [];
        break;
    }

    newQuestion.options.possible_values = opts;

    let requestOk;

    if (isEdit) {
      requestOk = await question.putQuestion(id, newQuestion);
    } else {
      requestOk = await question.postQuestion(newQuestion);
    }
    setLoadingSubmit(false);
    let verb;
    if (requestOk) {
      verb = isEdit ? 'modificada' : 'creada';
      setSuccessMessage(`Pregunta ${verb}`);
      setShouldReload(true);
      redirect('/preguntas');
    } else {
      verb = isEdit ? 'editar' : 'crear';
      setErrorMessage(`Hubo un error al ${verb} la pregunta`);
    }
  };

  const formik = useFormik({
    initialValues: {
      title: '',
      subFormValid: false,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  const initialSetup = async () => {
    if (!isEdit && !isShow) return;
    const fetchedQuestion = await question.fetchQuestion(id);
    const typeMap = invertObject(mapping);
    const questionType = typeMap[fetchedQuestion.category];
    fetchedQuestion.type = questionType;
    formik.setFieldValue('title', fetchedQuestion.value);
    formik.setFieldValue('category', fetchedQuestion.category_id.toString());
    formik.setFieldValue('questionType', questionType);
    setCurrQuestion(fetchedQuestion);
  };

  const setSubFormValid = (value) => {
    formik.setFieldValue('subFormValid', value);
  };

  const chooseSubForm = () => {
    switch (formik.values.questionType) {
      case 'list':
        return (
          <OptionList
            initialOptions={
              isEdit || isShow ? currQuestion?.options.possible_values : null
            }
            canReorder
            canDelete
            canAdd
            onValidChange={(valid) => setSubFormValid(valid)}
            onValueChange={(values) => setSubFormValues(values)}
            editEnabled={!isShow}
          />
        );

      default:
        return null;
    }
  };

  useEffect(initialSetup, []);

  useEffect(() => {
    const value = formik.values.category !== 'list';
    setSubFormValid(value);
  }, [formik.values.category]);

  // Update the canEditType state based on question type
  useEffect(() => {
    if (!currQuestion) return;
    const canEdit = ['three-alt', 'four-alt'].includes(currQuestion.type);
    setCanEditType(canEdit);
  }, [currQuestion]);

  const getAllowedOptions = () => {
    if (!canEditType) return optionsAndValues;
    return optionsAndValues.filter((opt) =>
      ['three-alt', 'four-alt'].includes(opt.value)
    );
  };

  const setupCategories = async () => {
    let fetchedCategories;
    if (isEdit) {
      fetchedCategories = await category.fetchAll();
    } else {
      fetchedCategories = await formtree.fetchCategoriesActive();
    }
    const options = fetchedCategories.map((cat) => {
      const { id: categoryId, name } = cat;
      return (
        <MenuItem key={categoryId} value={categoryId}>
          {name}
        </MenuItem>
      );
    });
    setCategories(options);
  };
  useEffect(setupCategories, []);

  const GoBack = () => {
    return <BackButton to="/preguntas" />;
  };

  return (
    <Grid container justifyContent="center" className="QuestionForm">
      <Grid item xs={12} lg={4}>
        <Form
          onSubmit={formik.handleSubmit}
          title={title}
          HeaderComponent={GoBack}
        >
          <TextField
            label="Ingresa la pregunta"
            id="title"
            name="title"
            fullWidth
            margin="normal"
            InputProps={{ readOnly: isShow }}
            {...fieldProps(formik, 'title')}
          />
          <TextField
            id="category"
            select
            disabled={isEdit}
            fullWidth
            margin="normal"
            label="Elige la categoría"
            InputProps={{ readOnly: isShow }}
            {...fieldProps(formik, 'category')}
          >
            {categories}
          </TextField>

          <TextField
            id="questionType"
            select
            disabled={isEdit && !canEditType}
            fullWidth
            margin="normal"
            label="Elige un tipo de pregunta"
            InputProps={{ readOnly: isShow }}
            {...fieldProps(formik, 'questionType')}
          >
            {getAllowedOptions().map(({ value, option }) => (
              <MenuItem key={value} value={value}>
                {option}
              </MenuItem>
            ))}
          </TextField>

          {chooseSubForm()}

          {!isShow && !CustomButton && (
            <Box align="center">
              <LoadingButton loading={loadingSubmit} text={submitText} />
            </Box>
          )}

          {CustomButton && <CustomButton id={id} />}
        </Form>
      </Grid>
    </Grid>
  );
};

QuestionForm.propTypes = {
  title: PropTypes.string,
  submitText: PropTypes.string,
  isEdit: PropTypes.bool,
  isShow: PropTypes.bool,
  CustomButton: PropTypes.elementType,
};

QuestionForm.defaultProps = {
  title: '',
  submitText: '',
  isEdit: false,
  isShow: false,
  CustomButton: null,
};

export default QuestionForm;
