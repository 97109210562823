import React from 'react';
import { Box, Grid } from '@material-ui/core';
import OsamLogo from 'components/OsamLogo';
import { useSessionContext } from 'utils/session/sessionContext';
import NotificationPanel from 'views/StartPanel/NotificationPanel';
import './StartPanel.scss';

const Welcome = () => {
  const { currentUser } = useSessionContext();

  return (
    <Grid xs item className="Welcome">
      <Box className="title-container">
        <Box className="title">
          Hola <strong>{currentUser.name},</strong>
        </Box>
        <Box className="subtitle">¡Qué gusto verte!</Box>
        <Box className="logo-container">
          <OsamLogo />
        </Box>
      </Box>
    </Grid>
  );
};

const StartPanel = () => {
  const { currentUser } = useSessionContext();

  return (
    <Grid
      container
      className="StartPanel"
      alignItems="center"
      justifyContent="center"
      direction="column"
    >
      {currentUser.isAdmin ? (
        <>
          <Grid xs={12} md={6} item className="dash-container">
            <NotificationPanel />
          </Grid>
        </>
      ) : (
        <Welcome />
      )}
    </Grid>
  );
};

export default StartPanel;
