import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@material-ui/core';

export default function FormRow({ row }) {
  return (
    <Grid className="FormRow" container spacing={1}>
      {Object.entries(row).map(([key, value]) => {
        return (
          <Fragment key={key}>
            <Grid item xs={2}>
              <Paper className="paper">{key}</Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper className="paperAnswer">{value}</Paper>
            </Grid>
          </Fragment>
        );
      })}
    </Grid>
  );
}

FormRow.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
};
