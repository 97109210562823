/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Box,
  Grid,
  Container,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CircularProgress from '@material-ui/core/CircularProgress';
import useNotification from 'utils/notifications/notificationHook';
import { Form, Selector, fieldProps, BackButton } from 'components';
import LoadingButton from 'components/LoadingButton';
import useService from 'utils/services';
import './BlockForm.scss';

import useTableState from 'utils/table-state';
import useRouterUtils from 'utils/router/routerUtilsHook';
import { rmKeys } from 'utils';

const validationSchema = yup.object({
  name: yup.string().required('Campo obligatorio'),
  category: yup.number().required('Elija una categoría'),
});

const BlockForm = ({ form, title }) => {
  const [isReady, setReady] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const { setSuccessMessage, setErrorMessage } = useNotification();
  const [selectedCategory, setCategory] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [categories, setCategories] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [values, setValues] = useState({
    questions_Not_Selected: [],
    questions_Selected: [],
  });
  const { block, formtree } = useService();
  const { redirect } = useRouterUtils();
  const tableName = form ? 'blocks-form' : 'blocks-report';
  const { setShouldReload } = useTableState(tableName);
  const link = form ? '/bloques_formularios' : '/bloques_reportes';

  const fetchData = async () => {
    let categoryMenuItems;
    let options;
    try {
      const formTree = await formtree.fetchTreeActive();
      categoryMenuItems = Object.entries(formTree).map((option) => {
        return [option[0], option[1].name];
      });
      options = categoryMenuItems.map((choice) => (
        <MenuItem key={choice[0]} value={choice[0]}>
          {' '}
          {choice[1]}
        </MenuItem>
      ));
      setCategories(options);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(fetchData, []);

  const onSubmit = async (val) => {
    setLoadingSubmit(true);
    const response = await block.postBlock(val, form, right);
    if (response) {
      setSuccessMessage('Bloque Creado');
    } else {
      setErrorMessage('Hubo un error al crear el bloque');
    }
    setLoadingSubmit(false);
    setShouldReload(true);
    redirect(link);
  };
  const formik = useFormik({
    initialValues: {
      id: 0,
      name: '',
      category: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });
  const handleCategory = async () => {
    if (formik.values.category) {
      setCategory(true);
      setReady(false);
      const questions = await block.selectQuestionsBlock(
        formik.values.category
      );
      setValues(questions);
      setReady(true);
    }
  };
  useEffect(handleCategory, [formik.values.category]);

  const GoBack = () => <BackButton to={link} />;

  useEffect(fetchData, []);

  return (
    <Container className="BlockForm">
      <Grid container justifyContent="center">
        <Grid item m={12}>
          <Box align="center">
            <Form
              onSubmit={formik.handleSubmit}
              title={title}
              HeaderComponent={GoBack}
            >
              {isLoading && <CircularProgress />}
              {!isLoading && (
                <>
                  <TextField
                    className="formField"
                    label="Nombre"
                    name="name"
                    {...fieldProps(formik, 'name')}
                    fullWidth
                    margin="normal"
                  />
                  <br />
                  <br />
                  <FormControl>
                    <InputLabel className="labelField" id="category">
                      Categoría
                    </InputLabel>
                    <Select
                      name="category"
                      className="formField"
                      {...rmKeys(fieldProps(formik, 'category'), [
                        'helperText',
                      ])}
                    >
                      {categories}
                    </Select>
                  </FormControl>
                  <br />
                  <br />
                  {selectedCategory && !isReady && (
                    <>
                      <CircularProgress />
                      <br />
                    </>
                  )}
                  {isReady && (
                    <>
                      <Selector
                        questions={values.questions_Not_Selected}
                        selectedQuestions={values.questions_Selected}
                        functionRight={setRight}
                        functionLeft={setLeft}
                      />
                      <br />
                    </>
                  )}
                  <LoadingButton loading={loadingSubmit} />
                </>
              )}
            </Form>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

BlockForm.propTypes = {
  form: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

BlockForm.defaultProps = {
  title: 'Nuevo Bloque',
};
export default BlockForm;
