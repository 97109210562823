import React from 'react';
import DataListForm from 'components/DataListForm';
import useService from 'utils/services';

export default function TaskTypes() {
  const { taskType, formtree } = useService();

  const submitFunction = async (values, args) => {
    const formattedValues = values.options.map(
      (optionObj) => optionObj.optionValue
    );
    const newValues = [...args.initialValues];
    newValues[args.serviceType][1] = formattedValues;
    args.setValuesFunction(newValues);

    const toPost = {};
    newValues.forEach(([id, list]) => {
      toPost[id] = list;
    });
    await taskType.postTaskTypes(toPost);
  };

  const fetchDataFunction = async (args) => {
    const result = await taskType.fetchTaskTypes();
    const categoriesResult = await formtree.fetchCategoriesActive();
    const data = Object.entries(result);
    const categoriesObj = {};
    categoriesResult.forEach((categoryObj) => {
      categoriesObj[categoryObj.id] = categoryObj.name;
      if (!data.map((pair) => pair[0]).includes(categoryObj.id)) {
        data.push([categoryObj.id, ['']]);
      }
    });
    args.setValuesFunction(data);
    args.setCategoriesFunction(categoriesObj);
  };
  return (
    <div className="TaskTypesDiv">
      <h2 className="title"> Tipos de tareas </h2>
      <DataListForm
        entity="tareas"
        submitFunction={submitFunction}
        fetchDataFunction={fetchDataFunction}
      />
    </div>
  );
}
