/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox as MuiCheckbox, FormControlLabel } from '@material-ui/core';

// TODO: Maybe move to components
const Checkbox = ({ additionalProps, checked, disabled, idName, label }) => {
  return (
    <FormControlLabel
      className="block"
      label={label}
      control={
        <MuiCheckbox
          className="chckbx"
          id={idName}
          name={idName}
          color="primary"
          checked={checked}
          disabled={disabled}
          {...additionalProps}
        />
      }
    />
  );
};

Checkbox.propTypes = {
  additionalProps: PropTypes.object.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  idName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
};

export default Checkbox;
