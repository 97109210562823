import React from 'react';
import PropTypes from 'prop-types';
import './Form.scss';

const Form = (props) => {
  const { children, method, onSubmit, title, boxShadow, HeaderComponent } =
    props;

  return (
    <form
      className={`Form ${boxShadow ? 'has-shadow' : ''}`}
      method={method}
      onSubmit={onSubmit}
    >
      {HeaderComponent && <HeaderComponent />}
      {title && <h1 className="form-title">{title}</h1>}
      {children}
    </form>
  );
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
  method: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  boxShadow: PropTypes.bool,
  HeaderComponent: PropTypes.elementType,
};

Form.defaultProps = {
  method: 'get',
  title: '',
  boxShadow: true,
  HeaderComponent: null,
};

export default Form;
