import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';
import useService from 'utils/services';
import useRouterUtils from 'utils/router/routerUtilsHook';
import { ResponsiveTableGrid } from 'components';

import './ReportIndex.scss';

const ReportIndex = () => {
  const { report } = useService();
  const { redirect } = useRouterUtils();

  const headers = [
    {
      field: 'name',
      headerName: 'Nombre',
      flex: 1,
    },
    {
      field: 'niceCategory',
      headerName: 'Categoría',
      flex: 1,
    },
    {
      field: 'niceForApi',
      headerName: 'Descargable por API',
      flex: 1,
    },
  ];

  // eslint-disable-next-line react/prop-types
  const DownloadButton = ({ row }) => {
    const onClick = () => {
      // eslint-disable-next-line react/prop-types
      redirect(`reportes/${row.id}`);
    };

    return (
      <Button className="download-button" onClick={onClick}>
        Descargar
      </Button>
    );
  };

  return (
    <div className="ReportIndex">
      <h2 className="title">Reportes</h2>
      <Box align="center">
        <Button
          className="new-entity-btn"
          component={Link}
          to="/reportes/nuevo"
          variant="contained"
        >
          Nuevo Reporte
        </Button>
      </Box>

      <ResponsiveTableGrid
        fetchAllFunc={report.fetchAll}
        headers={headers}
        tableName="reports"
        canEdit
        canDelete
        deleteNotification={() => 'Reporte Eliminado'}
        deleteEntityName={(row) => ` el reporte ${row.name}`}
        onEdit={(row) => redirect(`/reportes/editar/${row.id}`)}
        onDelete={(row) => report.destroy(row.id)}
        CustomActionComponent={DownloadButton}
        actionsWidth={300}
      />
    </div>
  );
};

export default ReportIndex;
