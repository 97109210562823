import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { UserIndex, UserShow, UserForm } from './views/Users';
import { useSessionContext } from './utils/session/sessionContext';
import Layout from './components/Layout';
import Audit from './views/Audit/AuditShow';
import AuditChooser from './views/Audit/AuditChooser';
import AuditEdit from './views/Audit/AuditEdit';
import AuditIndex from './views/Audit/AuditIndex';
import AuditQuery from './views/AuditQuery';
import { CategoryIndex, CategoryForm, CategoryShow } from './views/Categories';
import { FormIndex, FormShow, FormSheet } from './views/Forms';
import {
  QuestionIndex,
  QuestionNew,
  QuestionShow,
  QuestionEdit,
} from './views/Questions';
import { TecIndex, TecNewForm, TecShow } from './views/Tec';
import Vehicles from './views/Vehicles';
import TaskTypes from './views/TaskTypes';
import TeamsIndex from './views/Teams/TeamsIndex';
import TeamsForm from './views/Teams/TeamsForm';
import EnterprisesIndex from './views/Enterprises/EnterprisesIndex';
import EnterprisesForm from './views/Enterprises/EnterprisesForm';
import CommuneIndex from './views/Communes/CommunesIndex';
import CommuneForm from './views/Communes/CommuneForm';
import ProvincesIndex from './views/Provinces/ProvincesIndex';
import ProvincesForm from './views/Provinces/ProvincesForm';
import RegionsIndex from './views/Regions/RegionsIndex';
import RegionsForm from './views/Regions/RegionsForm';
import NonOkOptions from './views/NonOkOptions';
import StartPanel from './views/StartPanel';
import Login from './views/Login';
import { BlockIndex, BlockForm, BlockFormEdit } from './views/Blocks';
import { ReportIndex, ReportShow, ReportTemplateForm } from './views/Reports';
import { Admin, Client, Unlogged, Logged } from './components/Routes';
import Dashboard from './views/Dashboard';

const OsamRouter = () => {
  const { isLoggedIn, currentUser } = useSessionContext();
  return (
    <Router>
      <Switch>
        <Unlogged exact path="/iniciar-sesion">
          <Layout useNavbar={false}>
            <Login />
          </Layout>
        </Unlogged>

        <Admin exact path="/usuarios">
          <Layout>
            <UserIndex />
          </Layout>
        </Admin>

        <Admin exact path="/usuarios/nuevo">
          <Layout>
            <UserForm title="Nuevo Usuario" />
          </Layout>
        </Admin>

        <Admin exact path="/usuarios/:id">
          <Layout>
            <UserShow />
          </Layout>
        </Admin>

        <Admin exact path="/usuarios/editar/:id">
          <Layout>
            <UserForm isEdit title="Editar Usuario" />
          </Layout>
        </Admin>

        {/* Audits */}
        <Logged exact path="/auditorias">
          <Layout>
            <AuditChooser />
          </Layout>
        </Logged>

        <Logged exact path="/auditorias/filtrar">
          <Layout>
            <AuditQuery />
          </Layout>
        </Logged>

        <Logged exact path="/auditorias/recientes">
          <Layout>
            <AuditIndex />
          </Layout>
        </Logged>

        <Logged exact path="/auditorias/:id">
          <Layout>
            <Audit />
          </Layout>
        </Logged>

        <Admin exact path="/auditoria/editar/:id">
          <Layout>
            <AuditEdit />
          </Layout>
        </Admin>

        {/* Categories */}
        <Admin exact path="/categorias">
          <Layout>
            <CategoryIndex />
          </Layout>
        </Admin>

        <Admin exact path="/categorias/nueva">
          <Layout>
            <CategoryForm />
          </Layout>
        </Admin>

        <Admin exact path="/categorias/:id">
          <Layout>
            <CategoryShow />
          </Layout>
        </Admin>

        <Admin exact path="/categorias/editar/:id">
          <Layout>
            <CategoryForm isEdit title="Editar Categoría" />
          </Layout>
        </Admin>

        {/* Forms */}
        <Admin exact path="/formularios">
          <Layout>
            <FormIndex />
          </Layout>
        </Admin>

        <Admin exact path="/formularios/nuevo">
          <Layout>
            <FormSheet />
          </Layout>
        </Admin>

        <Admin exact path="/formularios/:id">
          <Layout>
            <FormShow />
          </Layout>
        </Admin>

        <Admin exact path="/formularios/editar/:id">
          <Layout>
            <FormSheet isEdit title="Editar Formulario" />
          </Layout>
        </Admin>

        {/* Questions */}
        <Admin exact path="/preguntas">
          <Layout>
            <QuestionIndex />
          </Layout>
        </Admin>

        <Admin exact path="/preguntas/nuevo">
          <Layout>
            <QuestionNew />
          </Layout>
        </Admin>

        <Admin exact path="/preguntas/editar/:id">
          <Layout>
            <QuestionEdit />
          </Layout>
        </Admin>

        <Admin exact path="/preguntas/:id">
          <Layout>
            <QuestionShow />
          </Layout>
        </Admin>

        {/* Blocks Forms */}
        <Admin exact path="/bloques_formularios">
          <Layout>
            <BlockIndex key={1} form />
          </Layout>
        </Admin>

        <Admin exact path="/bloques_formularios/nuevo">
          <Layout>
            <BlockForm key={1} title="Nuevo Bloque" form />
          </Layout>
        </Admin>

        <Admin exact path="/bloques_formularios/editar/:id">
          <Layout>
            <BlockFormEdit key={1} title="Editar Bloque" form />
          </Layout>
        </Admin>

        {/* Blocks Report */}
        <Admin exact path="/bloques_reportes">
          <Layout>
            <BlockIndex key={2} form={false} />
          </Layout>
        </Admin>

        <Admin exact path="/bloques_reportes/nuevo">
          <Layout>
            <BlockForm key={2} title="Nuevo Bloque" form={false} />
          </Layout>
        </Admin>

        <Admin exact path="/bloques_reportes/editar/:id">
          <Layout>
            <BlockFormEdit key={2} title="Editar Bloque" form={false} />
          </Layout>
        </Admin>

        {/* Reports */}
        <Admin exact path="/reportes">
          <Layout>
            <ReportIndex />
          </Layout>
        </Admin>

        <Admin exact path="/reportes/nuevo">
          <Layout>
            <ReportTemplateForm />
          </Layout>
        </Admin>

        <Admin exact path="/reportes/editar/:id">
          <Layout>
            <ReportTemplateForm isEdit title="Editar Reporte" />
          </Layout>
        </Admin>

        <Client exact path="/reportes/:id">
          <Layout>
            <ReportShow />
          </Layout>
        </Client>

        <Client exact path="/cliente">
          <Layout>
            <ReportShow />
          </Layout>
        </Client>

        {/* Technicians */}
        <Admin exact path="/tecnicos">
          <Layout>
            <TecIndex />
          </Layout>
        </Admin>

        <Admin exact path="/tecnicos/nuevo">
          <Layout>
            <TecNewForm title="Nuevo Técnico" />
          </Layout>
        </Admin>

        <Admin exact path="/tecnicos/:id">
          <Layout>
            <TecShow />
          </Layout>
        </Admin>

        <Admin exact path="/tecnicos/editar/:id">
          <Layout>
            <TecNewForm isEdit title="Editar Técnico" />
          </Layout>
        </Admin>

        {/* Vehicles */}
        <Admin exact path="/camionetas">
          <Layout>
            <Vehicles />
          </Layout>
        </Admin>

        {/* Task Types */}
        <Admin exact path="/tareas">
          <Layout>
            <TaskTypes />
          </Layout>
        </Admin>

        {/* Non Ok Options */}
        <Admin exact path="/razones_no_ok">
          <Layout>
            <NonOkOptions />
          </Layout>
        </Admin>
        {/* Teams */}
        <Admin exact path="/equipos">
          <Layout>
            <TeamsIndex />
          </Layout>
        </Admin>

        <Admin exact path="/equipos/nuevo">
          <Layout>
            <TeamsForm title="Nuevo Equipo" />
          </Layout>
        </Admin>

        <Admin exact path="/equipos/editar/:id">
          <Layout>
            <TeamsForm isEdit title="Editar Equipo" />
          </Layout>
        </Admin>

        {/* Enterprises */}
        <Admin exact path="/empresas">
          <Layout>
            <EnterprisesIndex />
          </Layout>
        </Admin>

        <Admin exact path="/empresas/nueva">
          <Layout>
            <EnterprisesForm title="Nueva empresa" />
          </Layout>
        </Admin>

        <Admin exact path="/empresas/editar/:id">
          <Layout>
            <EnterprisesForm isEdit title="Editar empresa" />
          </Layout>
        </Admin>

        {/* Communes */}
        <Admin exact path="/comunas">
          <Layout>
            <CommuneIndex />
          </Layout>
        </Admin>

        <Admin exact path="/comunas/nueva">
          <Layout>
            <CommuneForm title="Nueva Comuna" />
          </Layout>
        </Admin>

        <Admin exact path="/comunas/editar/:id">
          <Layout>
            <CommuneForm isEdit title="Editar Comuna" />
          </Layout>
        </Admin>

        {/* Provinces */}
        <Admin exact path="/provincias">
          <Layout>
            <ProvincesIndex />
          </Layout>
        </Admin>

        <Admin exact path="/provincias/nueva">
          <Layout>
            <ProvincesForm title="Nueva Provincia" />
          </Layout>
        </Admin>

        <Admin exact path="/provincias/editar/:id/">
          <Layout>
            <ProvincesForm isEdit title="Editar Provincia" />
          </Layout>
        </Admin>

        <Admin exact path="/provincias/editar/:id/">
          <Layout>
            <ProvincesForm isEdit title="Editar Provincia" />
          </Layout>
        </Admin>

        <Admin exact path="/estadisticas">
          <Layout>
            <Dashboard />
          </Layout>
        </Admin>

        {/* Regions */}
        <Admin exact path="/regiones">
          <Layout>
            <RegionsIndex />
          </Layout>
        </Admin>

        <Admin exact path="/regiones/nueva">
          <Layout>
            <RegionsForm title="Nueva Región" />
          </Layout>
        </Admin>

        <Admin exact path="/regiones/editar/:id/">
          <Layout>
            <RegionsForm isEdit title="Editar Región" />
          </Layout>
        </Admin>

        <Client exact path="/reportes/:id">
          <ReportShow clientCategory={currentUser?.clientCategory} />
        </Client>

        <Route exact path="/">
          {isLoggedIn ? (
            <Layout>
              <StartPanel />
            </Layout>
          ) : (
            <Redirect to="/iniciar-sesion" />
          )}
        </Route>

        {/* Go to Home in case of an unknown path */}
        <Redirect to="/" />
      </Switch>
    </Router>
  );
};

export default OsamRouter;
